.open-collapse-toggle {
  cursor: pointer;
  height: 20px;
  position: relative;
  width: 20px;
  // transform: rotate(90deg);
  &:before,
  &:after{
    content: '';
    height: 2px;
    top: 9px;
    position: absolute;
    left: 3px;
    width: 14px;
    transition: transform 300ms ease-out;
    border-radius: 1px;
  }
  &:after {
    transform-origin: center;
    //transform: rotate(90deg);
  }
  &.collapsed {
    &:before {
      //transform: scaleX(0);
      transform: rotate(180deg);
    }&:after {
       transform: rotate(90deg);
     }
  }
}
