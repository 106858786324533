.Accuracy {
  padding: 26px 20px;
  height: 100%;

  .Accuracy--charts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 50px;
    height: 100%;
    min-height: 610px;
    grid-auto-rows: 1fr;

    .Accuracy--chart-container {
      overflow: hidden;
    }

  }
}


// --- Everything below is in parts still used, but deprecated.

.accuracy {
  padding: 10px 31px;
}

.accuracy--list {
  padding: 26px 10px 15px 10px;
}

.accuracy--row {
  @include grid-row();
  @include grid-layout(2, ".accuracy--col");
  @include breakpoint(small down) {
    @include grid-layout(1, ".accuracy--col");
  }
  @include breakpoint(large up) {
    @include grid-layout(3, ".accuracy--col");
  }
}

.accuracy--col {
  @include grid-column(12, 18px);
  @include grid-column-end;

  &:hover {
    position: relative;
    z-index: 1000;
  }
}

.accuracy--item {
  margin: 0 0 22px 0;
}

.accuracy--title {
  font-weight: 400;
  margin: 0 0 13px 0;
  font-size: 15px;
}

.accuracy--title--link {
  color: $color-text;
  text-decoration: none;
}

.accuracy--item--graph {
  border: 1px solid $color-grey;
  border-radius: 4px;
  padding: 5px;

  img {
    width: 100%;
  }
}

.accuracy--item--graph--in {
  margin: 0 auto;
  //width: 302px;
  height: 181px;
  @include breakpoint(medium) {
    //width: 189px;
    height: 113px;
  }
  @include breakpoint(large) {
    //width: 244px;
    height: 146px;
  }

  .accuracy--item--graph--empty {
    display: flex;
    align-items: center;
    justify-content: center;

  }
}

.accuracy--history--title {
  font-weight: 600;
  font-size: 20px;
  float: left;
  padding: 8px 0 7px 0;
  @include breakpoint(medium down) {
    float: none;
  }
}

.accuracy--history--form {
  margin: 0 0 0 200px;
  @include breakpoint(medium down) {
    margin: 0;
  }

  .form--label--inline {
    color: $color-text;
    margin: 0 10px;
  }
}

.accuracy--graph {
  text-align: center;
  padding: 30px 0;
}

.accuracy--graphs--links {
  text-align: right;
  padding: 0 0 20px 0;
}

.accuracy--graphs--link {
  width: 42px;
  height: 42px;
  font-weight: 600;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  text-decoration: none;
  display: inline-block;
  border-radius: 2px;
  border: solid 1px #ddd;
  background: $color-light-grey;

  &:hover {
    color: $color-primary;
  }

  &.active {
    cursor: default;
    background: #b6d8ff;

    &:hover {
      color: $color-text;
    }
  }
}


.accuracy--diagram--wrap {
  display: flex;
  padding: 10px 0 0 0;
}

.accuracy--diagram--info {
  width: 200px;
  flex: 0 1 auto;

  .checkbox, .radio {
    margin: 10px 0;

    input {
      position: absolute;
      left: -20px;
      top: 0;

      &:checked {
        + span {
          color: $color-primary;
        }
      }
    }
  }

  .checkbox--list {
    padding: 10px 0 0 0;
  }
}

.accuracy--diagram--graph {
  flex: 1 1 0;
  padding: 20px 0 20px 30px;
  text-align: center;
}

.accuracy--item--graph {
  .pie-chart {
    display: flex;
    @include flex-align(left, middle);
  }

  .pie-chart_legend {
    flex: 1 1 0;
    order: 2;
    padding: 0 0 5px 25px;
    font-size: 11px;
    color: $color-text-light;
    @media (max-width: 800px) {
      padding: 0 0 5px 5px;
    }

    li {
      padding: 4px 0;
    }
  }

  .pie-chart_legend_dot {
    width: 10px;
    height: 10px;
    vertical-align: top;
    border-radius: 50%;
    display: inline-block;
    margin: 2px 5px 0 0;
  }

  .pie-chart_chart {
    flex: 0 1 auto;
    order: 1;
    width: 200px;
    height: 181px;
    @include breakpoint(medium) {
      height: 113px;
      width: 140px;
    }
    @include breakpoint(large) {
      height: 146px;
    }
    @media (max-width: 800px) {
      width: 113px;
    }
  }
}

.graphWait {
  display: flex;
  height: 100%;
  text-align: center;
  @include flex-align(center, middle);

  & > div {
    max-width: 300px;
  }

  h4, .h4 {
    font-size: 18px;
    font-weight: 400;
    padding: 0 0 15px 0;
  }

  p {
    color: $color-text-light;
    padding: 0 0 25px 0;
  }

  .graphWait-pic {
    width: 107px;
    height: 107px;
    margin: 0 auto 25px auto;
    background: url("../../img/graphWait.png") no-repeat;
    background-size: cover;
  }
}
