@import "../../../../scss/base/var.module";

.dropzoneContainer {
  height: 100px;
  width: 200px;
  min-width: 200px;
  background-color: $color-light-grey;
  border: 1px solid $color-grey;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  overflow: hidden;

  &.multiple {
    height: 200px;
  }

  &.highlight {
    background-color: $color-grey;
  }
}

.icon {
  opacity: 0.3;
  height: 30px;
  width: 30px;
}

.uploadLabel {
  font-size: 14px;
  margin-top: 10px;
}

.fileInput {
  display: none;
}

.dropzoneContainerFiles,
.dropzoneContainerDirectories {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 198px;
  height: 99px;

  &:hover {
    background-color: $color-grey;
  }
}

.multiple {
  .dropzoneContainerFiles {
    border-bottom: 1px solid $color-grey;
  }
}