@import '../../../../scss/base/var';
.OrchestrationContent {
  padding: 20px 15px;
  height: 100%;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;

  .OrchestrationInnerContent {
    flex-grow: 1;

    &.notScrollable {
      height: 100%; // Needed to make the inner (e.g. paging) component scroll instead
    }
  }
}
.schedulesList{}
.timedSchedules {
  height: 100%;
  flex-grow:1;
}
.JobSchedulesFilters {
  display: grid;
  grid-template-columns: 200px 400px;

  .JobSchedulesTrigger {
    margin-right: 10px;
  }
}
.JobSchedulesSubheader {
  position: sticky;
  top: 0;
  padding: 10px 0 10px 0;
  background-color: white;
  z-index: 100; // To overlap the react-flow graph

  .JobSchedulesSubheaderDescriptions {
    display: grid;
    grid-template-columns: 2fr 1fr 260px 40px 80px;
    gap: 10px;
    width: 100%;
  }
}

.JobSchedulesSchedules {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;


}
.JobSchedulesHeaderPaging {
  display: flex;
  align-items: center;
  width: 100%;

  .JobSchedulesButton {
    margin-left: auto;
    margin-right: 10px;
  }
}
