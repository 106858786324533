@import "../../../../../../../scss/base/var";

.augurBiography {
  padding: 26px 20px;
  height: 100%;

  .header {
    .headerTitle {
      font-size: 15px;
      font-weight: 400;
      display: block;
      margin-bottom: 5px;
    }
  }

  .empty,
  .error,
  .loading {
    height: 100%;
  }

  .content {
    margin-top: 10px;
    overflow-y: auto;
  }
}
