@import '../../../../../../../scss/base/var';

.parameterTuning {
  width: 100%;
  height: 100%;
}

.searchStrategySelect {
  width: 200px;
  max-width: 100%;
}
.headline {
  margin-bottom: 5px;
}

.container {
  margin-top: 20px;
  border-top: 1px solid $color-grey;
  padding-top: 20px;
  display: flex;
}

.element {
  margin-left: 20px;
  flex-grow: 1;
}

.parameterContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: auto;
  gap: 10px;
}

.gridItem {
  display: flex;
  flex-direction: column;
}

.checkbox {
  width: 26px;
}

.input {
  margin-bottom: 10px;
}

.description {
  margin: auto;
}

.placeholder {
  height: 20px;
  width: 1px;
}

.error {
  margin-top: 20px;
  color: $color-red;
  height: 20px;
  animation: shake 1s forwards;
  -webkit-animation: shake 1s forwards; /* For Safari */
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(0px);
  }
  75% {
    transform: translateX(2px);
  }
}

@-webkit-keyframes shake {
  /* For Safari */
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-10px);
  }
}
