@import '../../../../scss/base/var';

.container {
  margin: 40px;
}

.entry {
  margin-top: 20px;
}

.title {
  display: block;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 10px;
}

.advancedSectionHeader {
  display: flex;
  margin-top: 32px;
}

.collapseIcon {

}

.archiveButton {
  margin-left: auto;
  margin-right: 0;
}