.augur-attributes-table{
  margin-bottom: 10px;
  font-size: 14px;
  table-layout: fixed;

  tr {
    td {
      padding: 5px;
      border: none;

      &.td-icon-minus{
        width: 36px;
      }
    }
  }
}

.download-block {
  margin: -12px 20px 21px 20px;
  padding: 26px 20px 27px 20px;
  .checkbox--list--inline, .radio--list--inline {
    padding-top: 0;
  }
  .form--label {
    font-size: 12px;
  }
  select.form--field {
    width: 100%;
    margin: 0;
    max-width: none;
  }
}
.reset--link {
  float: right;
  color: $color-primary;
  text-decoration: none;
  font-size: 12px;
  margin-top: 5px;
  [class^="icon-"], [class*=" icon-"] {
    color: $color-text-light;
  }
}
