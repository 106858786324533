.MainContainer.Orchestration {
  .JobGroups--Header-paging {
    display: flex;
    align-items: center;
    width: 100%;

    .JobGroups--Button {
      margin-left: auto;
      margin-right: 10px;
    }
  }

  .JobGroups--Header {
    display: grid;
    grid-template-columns: 2fr 1fr 40px 160px;
    gap: 10px;
    width: 100%;

    position: sticky;
    top: 0;
    padding: 10px 0 10px 0;
    background-color: white;
    z-index: 100; // To overlap the react-flow graph
  }
}
