.Checkbox {
  position: relative;
  cursor: pointer;
  height: 20px;
  line-height: 20px;

  .Checkbox--mark-input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .Checkbox--mark-input:checked ~ .Checkbox--mark:after {
    display: block;
  }

  .Checkbox--mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 4px;

    &:hover {
      background-color: #ccc;
    }

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 6px;
      top: 0;
      width: 8px;
      height: 14px;
      border: solid darkblue;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .Checkbox--label {
    white-space: nowrap;
    padding-left: 30px;
  }
}
