@import '../../../../../scss/base/var';

.bucketContentHeadlineContainer {
  margin-bottom: 10px;

  .bucketPathContainer {
    display: flex;

    .bpBucket,
    .bpDir {
      padding: 5px 7px 5px 11px;
      border-radius: 4px;
      background-color: $color-white;
      cursor: pointer;

      &:not(&:last-child) {
        margin-right: -4px; // Exactly the border width
      }
    }

    .bpBucket {
      font-weight: 400;
      color: $color-primary-highlight;
      border: 1px solid $color-primary-highlight;

      &:hover {
        background-color: $color-primary-highlight-lighter90;
      }
    }

    .bpDir {
      border: 1px solid $color-grey;

      &:hover {
        background-color: $color-very-light-grey;
      }
    }
  }

  .bucketContentHeadline {
    font-weight: 400;
    display: block;
    cursor: default;
  }
}

.bucketContentList {
  .bucketContentElement {
    align-items: center;
  }

  .contentElementFile {
    cursor: default !important; // Override ct-row TODO this is from collab.scss it probably should not take anyway
    text-decoration: none;
  }

  .contentElementFileWithPreview {
    cursor: pointer !important; // TODO only necessary because of the above
  }
}

.renameModal {
  .modalHeadline {
    margin-bottom: 20px;
  }
}
