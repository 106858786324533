[type="text"],
[type="password"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="month"],
[type="week"],
[type="email"],
[type="number"],
[type="search"],
[type="tel"],
[type="time"],
[type="url"],
[type="color"],
textarea {
  background: $color-white;
  border-color: #bbb;
  border-radius: 4px;
  box-shadow: none;
  font-size: 15px;
  padding: 13px 18px;
  line-height: 1.3;
  height: 46px;
  margin: 0;
  &:focus {
    border-color: #bbb;
    box-shadow: none;
    background: $color-white;
  }
}


.form--row {
  @include grid-row(10);
  @include grid-row-nest(30px);
}
.form--left__large {
  @include grid-col(7);
}
.form--right__small {
  @include grid-col(3);
  text-align: right;
}

.form--left {
  @include grid-col(3);
  @include breakpoint(medium down) {
    @include grid-col(10);
    padding-bottom: 20px;
  }
}
.form--right {
  @include grid-column-offset(1);
  @include grid-col(6);
  @include breakpoint(medium down) {
    @include grid-column-offset(0);
    @include grid-col(10);
  }
}
.form--right--cut {
  float: left !important;
  @include grid-column-offset(1);
  @include grid-col(5);
  @include breakpoint(medium down) {
    @include grid-column-offset(0);
    @include grid-col(10);
    float: none !important;;
  }
}
.form--right--cut2 {
  float: left !important;
  @include grid-column-offset(1);
  @include grid-col(4);
  @include breakpoint(medium down) {
    @include grid-column-offset(0);
    @include grid-col(10);
    float: none !important;;
  }
}

.form--left__small {
  @include grid-col(3);
  padding-bottom: 30px;
  @include breakpoint(medium down) {
    @include grid-col(10);
  }
}
.form--right__large {
  @include grid-column-offset(1);
  @include grid-col(6);
  padding-bottom: 30px;
  @include breakpoint(medium down) {
    @include grid-column-offset(0);
    @include grid-col(10);
  }
}
.form--row22 {
  @include grid-row(22);
  float: none !important;
  @include grid-row-nest(30px);
  .form--col6 {
    @include breakpoint(medium down) {
      padding-bottom: 0;
    }
    @include breakpoint(small down) {
      padding-bottom: 0;
    }
  }
}
.form--col4 {
  @include grid-col(4);
  @include breakpoint(medium down) {
    @include grid-col(11);
  }
  @include breakpoint(small down) {
    @include grid-col(22);
  }
}
.form--col6 {
  @include grid-col(6);
  @include breakpoint(medium down) {
    @include grid-col(11);
    padding-bottom: 20px;
  }
  @include breakpoint(small down) {
    @include grid-col(22);
    padding-bottom: 20px;
  }
}
.form--col5 {
  @include grid-col(11);
  @include breakpoint(medium down) {
    @include grid-col(11);
    padding-bottom: 20px;
  }
  @include breakpoint(small down) {
    @include grid-col(11);
    padding-bottom: 20px;
  }
}

.form--row9 {
  @include grid-row(9);
  @include grid-row-nest(30px);
  .form--col__6 {
    @include grid-col(5);
    @include breakpoint(medium down) {
      @include grid-col(9);
    }
  }
  .form--col__3 {
    @include grid-col(4);
    @include breakpoint(medium down) {
      @include grid-col(9);
    }
  }
}



.form--field-block--wrap {
  height: 40px;
  margin-bottom: 1rem;
  display: flex;
  .button {
    margin: 1px 0 3px 4px;
    text-transform: uppercase;
  }
}
.form--field--wrap {
  flex: 1 1 0;
  margin: 0;
}
.form--field {
  width: 100%;
  border-radius: 3px;
  &.success {
    border-color: $color-green;
  }
  &.error {
    border-color: $color-red;
  }
  &.pending {
    border-color: $color-blue;
  }
}
.form--field--button--wrap {
  flex: 0 1 auto;
  &.w76 {
    width: 76px;
  }
}

.form--fields--line {
  @include grid-row(12);
  .form--field {
    width: auto;
    display: inline-block;
    margin: 0 3px 0 0;
    max-width: 120px;
    @include breakpoint(medium down) {
      margin-bottom: 5px;
    }
    &.Select {
      margin: 0;
      max-width: 150px;
      @include breakpoint(small down) {
        width: 100%;
        max-width: none;
      }
    }
  }
  .form--field__lg {
    min-width: 225px;
    max-width: 225px;
  }
  .form--field__md {
    max-width: 90px;
    text-align: center;
  }
  .form--field__sm {
    max-width: 55px;
    text-align: center;
  }
  .form--buttons {
    display: inline-block;
    vertical-align: top;
    .button {
      margin: 1px 0 1px 2px;
    }
  }
  .form--field {
    margin: 0 16px 5px 0;
  }
  .select--wrap {
    max-width: 250px;
    display: inline-block;
    vertical-align: top;
    margin: 0 16px 5px 0;
  }
  .select--wrap__md {
    min-width: 250px;
  }
  .select--wrap__lg {
    min-width: 280px;
    max-width: 280px;
  }
  + .error-line {
    float: none;
    margin: 8px 0 0 0;
    max-width: none;
    text-align: left;
    position: relative;
  }
  .settings-block & {
    + .error-line {
      float: none;
      margin: 3px 0 -8px 0;
      max-width: none;
      text-align: left;
      position: relative;
      min-height: 18px;
    }
  }
}
.line--text {
  color: $color-text-light;
  display: inline-block;
  margin: 0 16px 0 0;
}
.form--buttons--line {
  text-align: right;
  padding: 26px 20px 0 20px;
  margin: 35px -60px 0 -60px;
  border-top: 1px solid $color-grey;
  @include breakpoint(large down) {
    margin: 35px -20px 0 -20px;
  }
  @include breakpoint(medium down) {
    margin: 35px -20px 0 -20px;
  }
}
.form--buttons {
  &.passive {
    background: $color-light-grey;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255,.5);
    }
  }
  .button {
    margin: 1px 0 1px 13px;
    min-width: 145px;
    text-align: center;
    box-shadow: none;
  }
}

.form--select {
  font-size: 16px;
  line-height: 1.4;
  padding: 6px 0 0 0;
}

.error-line {
  font-size: 14px;
  text-align: right;
  color: $color-red;
  float: right;
  margin-top: -69px;
  max-width: 375px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 0 0 5px;
  position: relative;
  z-index: 1;
  @include breakpoint(small down) {
    margin: 0;
    max-width: none;
    overflow: visible;
    float: none;
    padding: 6px 0 0 0;
    text-align: left;
  }
}

.errors-bottom .error-line {
  margin-top: 5px;
}

.success-line {
  font-size: 18px;
  text-align: right;
  color: $color-green;
  margin: -34px 13px 0 0;
  float: right;
}
.pending-line {
  font-size: 18px;
  text-align: right;
  color: $color-blue;
  margin: -34px 13px 0 0;
  float: right;
}
.form--label--line {
  padding: 0 0 7px 0;
}
.form--info {
  max-width: 270px;
  min-height: 153px;
  @include breakpoint(medium down) {
    max-width: none;
    min-height: 0;
  }
}
.form--label--inline {
  line-height: 37px;
  vertical-align: top;
  display: inline-block;
  margin: 0 10px;
  color: $color-text-light;
  @include breakpoint(medium down) {
    display: block;
    margin: 0;
  }
}

.checkbox, .radio {
  display: inline-block;
  cursor: pointer;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  line-height: 22px;
  margin: 0 10px 10px 0;
  font-size: 15px;
  span {
    position: relative;
    padding: 0 0 0 30px;
    display: inline-block;
    color: $color-text-light;

    &.empty {
      height: 16px;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 22px;
      height: 22px;
      border: 1px solid #b6d8ff;
    }
    &:after {
      content: "";
      position: absolute;
      left: 6px;
      top: 6px;
      width: 10px;
      height: 10px;
      background: $color-primary;
      display: none;
    }
  }
  input {
    position: absolute;
    left: -20px;
    top: 0;
    &:checked {
      + span {
        color: $color-text;
        &:after {
          display: block;
        }
      }
    }
  }
}
.radio {
  span {
    &:before {
      background-color: #fff;
      border-radius: 50%;
    }
    &:after {
      border-radius: 50%;
    }
  }
  &.disabled {
    span {
      &:before {
        border: 1px solid $color-grey;
      }
    }
  }
}
.checkbox--list, .radio--list {

}
.checkbox--list--inline, .radio--list--inline {
  padding: 8px 0 12px 0;
  @include breakpoint(medium down) {
    padding: 0 0 12px 0;
  }
  .checkbox, .radio {
    display: inline-block;
    margin-right: 50px;
  }
}

.form--label--fieldset {
  padding: 0 15px 10px 0;
  display: inline-block;
  vertical-align: top;
}
.form--label--date-sets {
  @include grid-col(12);
  .form--buttons {
    @include breakpoint(medium down) {
      display: block;
      padding: 0 0 20px 0;
    }
  }
}
.form--label--date-set {
  @include grid-col(12);
  display: none;
  font-weight: 600;
  font-size: 20px;
  padding: 8px 0 15px 0;
}
.date-set {
  .form--label--date-sets {
    display: none;
  }
  .form--label--date-set {
    @include grid-col(4);
    @include breakpoint(medium down) {
      @include grid-col(12);
    }
    display: block;
  }
  .checkbox--list--inline, .radio--list--inline {
    @include grid-col(8);
    @include breakpoint(medium down) {
      @include grid-col(12);
    }
  }
}
.form--results {
  display: none;
}


.form--success {
  text-align: center;
  font-size: 15px;
  position: relative;
  font-weight: 400;
  z-index: 1;
  padding: 100px 0;
  color: $color-text-light;
  p {
    padding: 0 0 40px 0;
  }
  h3 {
    margin: 0;
    color: $color-text;
    padding: 0 0 40px 0;
  }
  @include breakpoint(medium down) {
    padding: 30px 0;
  }
}
.form--success--in {
  max-width: 400px;
  margin: 0 auto;
}
.form--success--ico {
  width: 91px;
  height: 91px;
  opacity: 0.8;
  background-color: $color-green;
  display: inline-block;
  border-radius: 50%;
  margin: 0 0 50px 0;
  .icon-ok {
    color: $color-white;
    line-height: 89px;
    font-size: 50px;
  }
}

.form--group {
  padding: 0 0 22px 0;

  &.form--group-flex {
    display: flex;

    .form--col-flex {
      flex-grow: 1;
      &:not(:first-child){
        margin-left: 10px;
      }
      &:not(:last-child){
        margin-right: 10px;
      }
    }
  }
}
.form--label {
  color: $color-text-light;
  font-size: 14px;
  display: block;
  padding: 0 0 6px 0;
}



.fullscreen-wrap {
  color: $color-text-light;
  display: flex;
  @include flex-align(center, middle);
}

.login-box {
  padding: 10px;
  text-align: center;
  [type="text"],
  [type="password"],
  [type="date"],
  [type="datetime"],
  [type="datetime-local"],
  [type="month"],
  [type="week"],
  [type="email"],
  [type="number"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="url"],
  [type="color"],
  textarea {
    margin: 0 0 26px 0;
  }

  $error-border: 1px solid $color-red;

  .row.error {
    label {
      color: $color-red;
    }

    input, textarea {
      border: $error-border;
    }

    .input-group-button button {
      border-top: $error-border;
      border-right: $error-border;
      border-bottom: $error-border;
    }
  }

  [type="password"] {
    font-size: 19px;
    @include placeholder {
      font-size: 15px;
    }
  }

  label {
    color: $color-text-light;
    text-align: left;
    font-size: 13px;
    padding: 0 0 7px 0;
  }
  strong {
    font-weight: 500;
  }

  h3 {
    color: $color-text-light;
    text-align: center;
    padding: 0 0 15px 0;
    font-weight: 300;
  }

  a {
    color: $color-white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  small.error {
    margin-top: -20px;
    margin-bottom: 26px;
    color: $color-red;
  }
  .button--submit {
    margin: 32px auto 28px;
    min-width: 240px;
  }
  .forgot-link {
    text-align: left;
    color: #5d92db;
    font-size: 13px;
    padding: 0 0 7px 0;
    margin: -4px 0 0 0;
    @include grid-row(12);
    @include grid-row-nest(30px);
    a {
      color: #5d92db;
    }
  }
  .input-group {
    margin: 0;
  }
}
.forgot-l {
  @include grid-col(6);
}
.forgot-r {
  @include grid-col(6);
  text-align: right;
}

.logo-big {
  position: relative;
  color: #5d92db;
  font-size: 13px;
  display: inline-block;
  margin: 0 auto 50px;
}
.logo-more {
  position: absolute;
  right: 0;
  bottom: 0;
}
.select-lang {
  text-align: right;
  padding: 30px 0;
  @include breakpoint(small down) {
    text-align: center;
  }
  select {
    background-color: transparent;
    border-color: transparent;
    max-width: 120px;
    display: inline-block;
    outline: none !important;
    cursor: pointer;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

.login-error {
  text-align: center;
  padding: 0 10px 10px 10px;
  color: $color-red;
}

.reset-info {
  text-align: center;
  padding: 0 10px 10px 10px;
  color: $color-primary;
}

.form-buttons--row {
  @include grid-row(12);
  @include grid-column-row(10px);
  position: relative;
  float: none !important;
  &:after {
    content: "";
    clear: both;
    display: table;
  }
}
.form-buttons--left {
  padding: 5px 0;
  @include grid-col(5);
  @include breakpoint(small down) {
    text-align: center;
  }
}
.form-buttons--right {
  padding: 5px 0;
  @include grid-col(7);
  text-align: right;
  @include breakpoint(medium down) {
    .button {
      margin: 0 5px;
    }
  }
  @include breakpoint(small down) {
    text-align: center;
  }
  .button {
    margin: 0 0 1px 10px;
    min-width: 130px;
  }
  .button-md {
    min-width: 145px;
  }
}

.white-group {
  border-radius: 3px;
  .form--field {
    border-right-color: transparent;
    border-radius: 3px 0 0 3px;
  }
  .button {
    height: 46px;
    text-align: center;
    padding: 0 0 2px 0;
    cursor: pointer;
    width: 50px;
    margin: 0 0 0 -1px;
    background: $color-white !important;
    box-shadow: none;
    border-color: #bbb;
    border-left-color: transparent;
    border-radius: 0 3px 3px 0;
    &:hover {
      opacity: 1;
      .icon-search {
        color: $color-white;
      }
      [class^="icon-"], .button [class*=" icon-"] {
        opacity: 1;
      }
    }
    [class^="icon-"], .button [class*=" icon-"] {
      margin: 3px 0 0 0;
      opacity: .8;
    }
  }
  .icon-model-viewer {
    color: $color-primary;
  }
}

.drag-area {
  background: $color-light-grey;
  border: 1px dashed $color-primary;
  border-radius: 4px;
  height: 326px;
  display: flex;
  text-align: center;
  @include flex-align(center, middle);
  .link {
    color: $color-primary;
  }
  [class^="icon-"], .button [class*=" icon-"] {
    font-size: 50px;
    color: $color-text-light;
  }

  &.active {
    border-style: solid;
    background: $color-grey;
  }
}
.dragging {
  max-width: 350px;
  .drag-area {
    height: 200px;
  }
}
.uploaded-item {
  padding: 0 20px 7px;
  position: relative;
  .icon-cross {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 21px;
    color: $color-red;
    text-decoration: none;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
  .icon-blank-file {
    position: absolute;
    left: 0;
    top: 2px;
    color: $color-primary;
  }
}


.input-group {
  > div {
    flex: 1;
  }
  .input-group-button {
    flex: 0 auto;
  }
}


.textarea {
  border: 1px solid $color-grey;
  border-radius: 4px;
  padding: 10px;
  height: 90px;

  &.not-resize {
    resize: none;
  }
}

.form--icon-button{
  width: 46px;
  height: 46px;
  border-radius: 4px;
  border: 1px solid #bbb;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-text;
  cursor: pointer;

  &:not(:last-child){
    margin-right: 5px;
  }

  &.enabled{
    &:hover{
      background-color: $color-grey;
      color: $color-white;
    }
  }

  &.disabled{
    color: $color-grey;
  }
}
