@import "../../../../scss/base/var.module";

.image {
  border-radius: 50%;
  background: $color-white;
  padding: 0;
  text-align: center;
  overflow: hidden;
}

.initials {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  color: white;
  font-weight: 500;
}

.icon {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 22px;
  color: #40669f;
}

