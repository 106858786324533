@import '../../../../../scss/base/var';

.RadioButtonsInput {
  .RadioButtonsInput--header {
    display: flex;

    .RadioButtonsInput--label {
      color: $color-text-light;
      font-size: 14px;
      display: block;
      padding: 0 0 6px 0;
      white-space: nowrap;
      cursor: default;
      margin-bottom: 5px;
    }

    .RadioButtonsInput--error {
      margin-left: auto;
      font-size: 14px;
      text-align: right;
      color: $color-red;
      float: right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 0 0 5px;
      cursor: default;
    }
  }

  .RadioButtonsInput--buttons {
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 0;

    .RadioButtonsInput--button {
      .RadioButtonsInput--radio {
        display: inline-block;
        cursor: pointer;
        vertical-align: top;
        overflow: hidden;
        position: relative;
        line-height: 22px;
        margin: 0 10px 10px 0;
        font-size: 15px;

        span {
          position: relative;
          padding: 0 0 0 30px;
          display: inline-block;
          color: $color-text;

          &.empty {
            height: 16px;
          }
          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 22px;
            height: 22px;
            border: 1px solid $color-primary-lighter60;
          }
          &:after {
            content: "";
            position: absolute;
            left: 6px;
            top: 6px;
            width: 10px;
            height: 10px;
            background: $color-primary;
            display: none;
          }

          &:before {
            background-color: #fff;
            border-radius: 50%;
          }
          &:after {
            border-radius: 50%;
          }
        }

        &.disabled {
          cursor: default;
          span {
            color: $color-text-light;
            &:before {
              background-color: $color-light-grey;
              border: 1px solid $color-grey;
            }
          }
        }
        input {
          position: absolute;
          left: -20px;
          top: 0;
          &:checked {
            + span {
              &:after {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
