@import '../../../../../scss/base/var';

.Header {
  display: flex;
  cursor: pointer;
}

.Icon {
  margin-right: 5px;
}

.Body {
  margin-bottom: 10px;
}


.TextInputArea {
  display: flex;
  flex-direction: column;

  .TextInputAreaHeader {
    display: flex;

    .TextInputAreaLabel {
      color: $color-text-light;
      font-size: 14px;
      display: block;
      padding: 0 5px 6px 0;
      white-space: nowrap;
      cursor: default;

    }

    .TextInputAreaError {
      margin-left: auto;
      font-size: 14px;
      text-align: right;
      color: $color-red;
      float: right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 0 0 5px;
      cursor: default;
    }
  }

  .TextInputAreaInput {
    width: 100%;
    border-radius: 3px;

    &.success {
      border-color: $color-green;
    }

    &.error {
      border-color: $color-red;
    }
  }
}
