// -- Color definitions - a bit duplicated. TODO cleanup double specifications
$col-chart-link: #455A64;
$col-light-grey: #eef0f2;
$col-border-grey-lighter: #dce2eb;
$col-status-grey: #78909C;

.Orchestration--content {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  position: relative;

  .queue-header {
    padding: 0 0 10px 0;

    .queue-header-list {
      display: flex;

      .queue-header-button {
        display: flex;
        align-items: center;
        padding: 11px 20px;

        .queue-header-icon {
          margin-right: 6px;
        }
      }

      .refresh-item {
        display: inline-block;
        margin-left: auto;
      }
    }
  }

  .queue-content {
    height: 100%;
    margin-top: 10px;
  }
}

.pre-queue-container {
  height: 100%;
  padding-top: 15px;

  .pre-queue-header {
    display: grid;
    grid-template-columns: 2fr 1fr 170px 40px 80px;
    gap: 10px;
    width: 100%;
  }
}

.realtime-container {
  .realtime-headline {
    margin: 0 5px 10px 5px;
    font-size: 15px;
    font-weight: 400;
    display: block;
  }
}
