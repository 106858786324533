@import '../../../../../../../../scss/base/var';

.PipelineTuningSelection {
  .PipelineTuningSelection--pipeline-container {
    border: 1px solid $color-grey;
    border-radius: 0 4px 4px 4px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .PipelineTuningSelection--node-editor {
    margin-top: 10px;
    padding: 10px;
    border-top: 1px solid $color-grey;
    display: flex;
    flex-direction: column;

    .PipelineTuningSelection--display-name {
      font-weight: 400;
      font-size: 15px;
    }

    .PipelineTuningSelection--description {
      margin-top: 5px;
    }

    .PipelineTuningSelection--active {
      margin-top: 10px;
    }

    .PipelineTuningSelection--static {
      // empty
    }

    .PipelineTuningSelection--tuning {
      // empty
    }

    .PipelineTuningSelection--static,
    .PipelineTuningSelection--tuning {
      margin-top: 10px;
      display: flex;
      flex-direction: column;

      .PipelineTuningSelection--params-headline {
        font-weight: 400;
        color: $color-text-light;
        margin-bottom: 5px;
      }

      .PipelineTuningSelection--params-container {
        border: 1px solid $color-grey;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .PipelineTuningSelection--params-param {
          display: flex;
          flex-direction: column;
          width: 300px;
          margin: 10px;
          justify-content: stretch;

          .PipelineTuningSelection--params-param-headline {
            font-weight: 400;
          }

          .PipelineTuningSelection--params-param-description {
            margin-top: 5px;
            flex-grow: 1; // To align the text boxes evenly
          }

          .PipelineTuningSelection--params-param-input-container {
            margin-top: 5px;
          }
        }
      }
    }
  }
}
