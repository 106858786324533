@import '../../../../../scss/base/var';

.inputRow {
  display: flex;
  align-items: flex-end;
  padding-top: 20px;
  width: 100%;

  .inputParent {
    width: 100%;
  }
}

.bottomRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;

  .buttonLine {
    display: flex;

    // Ensure spacing between the buttons
    > * {
      &:not(:last-child){
        margin-right: 5px;
      }
    }
  }
}

.Error {
  color: $color-red;
}
