.barpart {
  transition: all ease-in-out 0.05s;
  &:hover {
    filter: brightness(1.15);
  }
}

.legendHover {
  filter: brightness(1.15);
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.overflowX {
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;
}

.legend {
  margin-top: 5px;
  padding-bottom: 10px;
  margin-right: 10px;
  font-size: 11px;
  color: #394246;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-shrink: 0;
}

.legendSymbol {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.legendDot {
  width: 10px;
  height: 10px;
  vertical-align: top;
  border-radius: 50%;
  display: inline-block;
  margin: 2px 5px 0 10px;
}

.pointer {
  cursor: pointer;
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.undefinedValuesNote {
  color: red;
  margin: 0 auto;
  font-size: small;
  margin-top: 2px;
  margin-bottom: 0;
}

.legendContainer {
  color: #394246;
  display: flex;
  position: relative;
}

.svgElement {
  min-height: 0;
  flex-grow: 1;
}
