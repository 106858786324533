@import '../styles.module';

$col-border-grey-lighter: #dce2eb;

.history {
  grid-template-columns: 2fr 1fr 170px 40px 170px 80px;;
}
.queue {
  grid-template-columns: 2fr 1fr 170px 40px 80px;
}

.status {
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding: 2px 0;

  .statusBar {
    // flex-grow: 1;
    // height: 1px;
    margin-bottom: 5px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }

  &.status_in_progress {
    .JobGroup--Status-bar {
      background-color: $color-blue;
    }
  }

  &.status_waiting {
    .JobGroup--Status-bar {
      background-color: $color-grey;
    }
  }

  &.status_successful {
    .JobGroup--Status-bar {
      background-color: $color-green;
    }
  }

  &.status_cancelled {
    .JobGroup--Status-bar {
      background-color: $color-orange;
    }
  }

  &.status_failure {
    .JobGroup--Status-bar {
      background-color: $color-red;
    }
  }
}