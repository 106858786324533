@import '../../../../../scss/base/var';

.parent {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.link {
  display: flex;
  align-items: center;
  cursor:pointer;
  color: $color-primary-lighter30;
  height: 32px;
  justify-content: center;

  &:hover {
    color: $color-primary;
  }
}
