@import '../../../../scss/base/var';

.moduleCard {
  word-wrap: break-word;
  word-break: break-all;
  background-color: $color-light-grey;
  height: 110px;
  min-width: 300px;
  position: relative;
  border-radius: 2px;
  border: 1px solid $color-grey;
  padding: 10px;
  display: flex;
  flex-direction: column;

  &:hover {
    .title {
      text-decoration: underline;
    }
  }

  .body {
    display: flex;
    flex-direction: row;
    margin-bottom: auto;

    .iconContainer {
      width: 40px;
      height: 40px;
      overflow: hidden;
      flex-shrink: 0;

      .icon {
        width: 40px;
        height: 40px;
      }
    }

    .textContainer {
      display: flex;
      flex-direction: column;
      margin-left: 10px;

      .title {
        display: block;
        font-weight: 500;
        margin-bottom: 5px;
      }

      .description {
        display: block;
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20px;

    .footerElement {

      &.versionInfoContainer {
        margin-right: 5px;

        .latestVersion, .amountVersions {
          background-color: $color-primary;
          color: white;
          font-weight: 400;
          height: 18px;
          display: inline-flex;
          align-items: center;
          font-size: 12px;
        }

        .latestVersion {
          border-top-left-radius: 9px;
          border-bottom-left-radius: 9px;
          padding: 0 6px 0 8px;
        }

        .amountVersions {
          border-left: 1px solid $color-light-grey;
          border-top-right-radius: 9px;
          border-bottom-right-radius: 9px;
          padding: 0 8px 0 6px;
        }
      }

      &.versionsCount {
        font-size: 12px;
        font-weight: 400;
      }

      &.codeAvailable {
        margin-right: 10px;
        padding: 0 8px;
        background-color: $color-text-light;
        color: white;
        font-weight: 400;
        height: 18px;
        border-radius: 9px;
        display: flex;
        align-items: center;
        font-size: 12px;
      }

      &.avatar {
        margin-left: auto;

        .avatarIcon {
          height: 20px;
          width: 20px;
        }

        .stockIcon {
          height: 20px;
          width: 20px;
          border-radius: 10px;
          padding: 2px;
          background-color: white;
        }
      }
    }
  }
}
