.resources-parent,
.backups-parent,
.templates-parent{
  padding: 20px;
  height: 100%;
}

.s3-permissions {
  .s3-single-permissions {
    margin-bottom: 30px;

    .data-source-name {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 10px;
      display: block;
    }
  }
}
