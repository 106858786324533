@import '../../../../../scss/base/var';

.header{
  display: flex;
  flex-direction: row;
}
.formGroup {
  padding: 0 0 22px 0;

  &.formGroup-flex {
    display: flex;

    .form--col-flex {
      flex-grow: 1;
      &:not(:first-child){
        margin-left: 10px;
      }
      &:not(:last-child){
        margin-right: 10px;
      }
    }
  }
}
.inputContainerError{
  border: 1px solid $color-red !important;

}
.textInputLineError {
  margin-left: auto;
  font-size: 14px;
  text-align: right;
  color: $color-red;
  float: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 0 0 5px;
  cursor: default;
}
.formLabel {
  color: $color-text-light;
  font-size: 14px;
  display: block;
  padding: 0 0 6px 0;
}
