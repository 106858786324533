@import '../../../../../../scss/base/var';


.container {
  display: flex;
  background-color: white;
  flex-direction: row;
  height: 100%;
  width: 100%;

  .item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;

    .content {
      flex-grow: 1;
      overflow: auto;
    }

    .background {
      margin-top: 12px;
      margin-bottom: 12px;
      display: flex;
      justify-content: center;
    }

    .circle {
      width: 15px;
      height: 15px;
      background-color: #ddd;
      position: relative;
      border-radius: 20px;
      margin-right: 4px;
      overflow: hidden;
    }

    .circle:hover {
      cursor: pointer;
    }

    .mover {
      position: absolute;
      top: 0;
      left: 0;
      width: 15px;
      height: 15px;
      border-radius: 20px;
      background-color: $color-primary;
      transition: transform 0.5s ease;
    }
  }

  .btn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
    opacity: 0; // Hide by default
    transition: opacity 0.7s ease; // Smooth transition for showing/hiding
    .prev {
      font-size: x-large;
    }

    .prev:hover {
      cursor: pointer;
      color: $color-primary;
    }

    .next {
      font-size: x-large;
    }

    .next:hover {
      cursor: pointer;
      color: $color-primary;
    }
  }
  &:hover {
    .btn {
      opacity: 1; // Show buttons on container hover
    }
  }

}