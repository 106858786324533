@import '../../../../scss/base/var';

.elementCardButtonOnly {
  background-color: $color-light-grey;
  height: 130px;
  min-width: 265px;
  position: relative;
  border-radius: 2px;
  border: 1px solid $color-grey;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    .subHeader {
      text-decoration: underline;
    }
  }

  &.clickable {
    cursor: pointer;
  }

  .content {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .buttonParent {
      display: flex;
      flex-direction: column;
      align-items: center;

      .subHeader {
        margin-top: 10px;
        color: $color-text-light;
      }
    }
  }
}
