@import '../../../../scss/base/var';

.notEvenOnePermission {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.fullViewHeight {
    padding-top: 110px; // to compensate for the header and sub-header
    height: 100vh;

    .subHeaderMock {
      padding: 0;
      position: fixed;
      left: 0;
      top: 48px;
      width: 100%;
      background: #fff;
      border-bottom: 1px solid #ddd;
      z-index: 998;
      margin: 0;
      height: 62px;
    }
  }

  .title {
    font-size: 18px;
    color: $color-text-grey;
    margin-bottom: 10px;
  }

  .description {
    font-size: 14px;
    color: $color-text-grey;
    margin-bottom: 40px;
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
