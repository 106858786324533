.chartBox {
  width: inherit;
  height: inherit;
}

.chart-axis {
  .domain {
    display: none;
  }
}

.histogram-axis {
  .domain {
    stroke: #979FA8;
  }
}

.histogram-axis__tick {
  font-size: 12px;
  .tick {
    line {
      stroke: #979FA8;
    }
    text {
      fill: #979FA8;
    }
  }
}

.drift-axis-left__tick {
  font-size: 12px;
  .tick {
    line {
      display: none;
    }
    text {
      fill: #979FA8;
    }
  }
}

.slider-axis-bottom__tick {
  font-size: 12px;
  .tick {
    line {
      display: none;
    }
    text {
      fill: #979FA8;
    }
  }
}

.drift-axis-left__help-line {
  .tick {
    text {
      display: none;
    }
    line {
      stroke: #DFE2E5;
      stroke-dasharray: 12, 6;
      stroke-width: 1;
    }
  }
}

.drift-axis-left__base-line {
  .tick {
    text {
      display: none;
    }
    line {
      stroke: $color-primary-lighter90;
      stroke-width: 1;
    }
  }
}

.accuracy-axis__help-line {
  .tick {
    text {
      display: none;
    }
    line {
      stroke: #EBEFF2;
      stroke-width: 1;
    }
  }
}

.accuracy-axis__help-line-dashed {
  .tick {
    text {
      display: none;
    }
    line {
      stroke: black;
      opacity: 0.3;
      stroke-dasharray: 6, 12;
      stroke-width: 1;
    }
  }
}

.accuracy-axis__tick {
  font-size: 10px;
  .tick {
    line {
      display: none;
    }
    text {
      fill: #A0A7AF;
    }
  }
}

.accuracy-axis__label {
  font-size: 11px;
  fill: #888888;
  font-weight: 500;
}

.accuracy-detail-axis {
  font-size: 14px;
  .domain {
    stroke: #888888;
  }
  .tick {
    text {
      fill: #444444;
    }
    line {
      stroke: #888888;
    }
  }
}

.accuracy-axis__base-line {
  .tick {
    text {
      display: none;
    }
    line {
      stroke: $color-primary-lighter90;
      stroke-width: 1.5;
    }
  }
}

.example-slider-area {
  fill: #96c1f1;
}

.bar-transition {
  transition: all 0.5s ease;
}

.chart-tooltip {
  background: $color-white;
  left: 0;
  top: 0;
  position: absolute;
  font-size: 12px;
  line-height: 1.2;
  border: 1px solid $color-grey;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, .1);
  padding: 4px 6px 4px;
  white-space: nowrap;
  color: $color-text-light;
  z-index: 99999;
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -12px;
    z-index: 1001;
    border: 6px solid transparent;
    border-top-color: $color-white;
    margin: 0 0 0 -6px;
  }
  span {
    color: $color-primary;
  }
}

.predictedValues-slider {
  margin-top: 20px;
}

.predictedValues-slider-custom {
  .rc-slider-track {
    background: none;
  }
  background-color: white;
  border: 2px solid $color-grey;
  height: 15px;
}

.predictedValues-slider-handle {
  position: absolute;
  transform: translate(-50%, -50%);
  cursor: pointer;
  margin-top: 5px;
  border-radius: 3px;
  width: 15px;
  height: 20px;
  background: $color-grey;
}

.habitat-header__data-icon {
  margin: 0 3px 0 0 ;
  font-size: 1.2em;
}

.heatmap-container{
  position: absolute;
}

.value-distribution-axis {
  font-size: 10px;
  .tick {
    line {
      display: none;
    }
    text {
      fill: #A0A7AF;
    }
  }
}
