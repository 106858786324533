// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group flex-video
////

/// Margin below a flex video container.
/// @type Number
@use "sass:math";

$flexvideo-margin-bottom: rem-calc(16) !default;

/// Padding used to create a 4:3 aspect ratio.
/// @type Number
$flexvideo-ratio: 4 by 3 !default;

/// Padding used to create a 16:9 aspect ratio.
/// @type Number
$flexvideo-ratio-widescreen: 16 by 9 !default;

/// Creates a percentage height that can be used as padding in a flex video container.
/// @param {List} $ratio - Ratio to use to calculate the height, formatted as `x by y`.
/// @return {Number} A percentage value that can be used as the `padding-bottom` parameter of a flex video container.
@function flex-video($ratio) {
  $w: nth($ratio, 1);
  $h: nth($ratio, 3);
  @return math.div($h, $w) * 100%;
}

/// Creates a flex video container.
/// @param {List} $ratio [$flexvideo-ratio] - Ratio to use for the container, formatted as `x by y`.
@mixin flex-video($ratio: $flexvideo-ratio) {
  position: relative;
  height: 0;
  padding-bottom: flex-video($ratio);
  margin-bottom: $flexvideo-margin-bottom;
  overflow: hidden;

  iframe,
  object,
  embed,
  video {
    position: absolute;
    top: 0;
    #{$global-left}: 0;
    width: 100%;
    height: 100%;
  }
}

@mixin foundation-flex-video {
  .flex-video {
    @include flex-video;

    &.widescreen {
      padding-bottom: flex-video($flexvideo-ratio-widescreen);
    }

    &.vimeo {
      padding-top: 0;
    }
  }
}
