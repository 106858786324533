@import '../base/var';

.workbench-repository-info {

  height: 100%;
  overflow-y: auto;
  margin: 5px 0;
  background-color: $color-white;
  border-radius: 4px;

  .repository-info-category-title {
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .git-file-status {
    border: 1px solid $color-grey;
    border-radius: 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 5px;
    position: relative;
    min-height: 70px;

    .git-file-status-category {
      display: flex;
      flex-direction: column;

      &:not(:first-child) {
        margin-top: 20px;
      }

      .git-file-status-title {
        font-weight: 400;
        margin-bottom: 5px;
      }

      .git-file-status-container {
        .git-file-status-row {
          padding: 5px 0;
          display: flex;
          font-weight: 400;

          &.staged-files {
            color: $color-green-dark;
          }

          &.not-staged-files, &.untracked-files {
            color: $color-red;
          }

          .change-type {
            width: 100px;
          }

          .file-name {

          }
        }
      }
    }
  }

  .git-branch-selector {
    margin-top: 5px;
  }

  .git-branches {
    flex-direction: row;
    align-items: flex-end;
  }

  .git-list-commits,
  .git-list-not-pushed-commits,
  .git-list-not-pulled-commits {
    flex-direction: column;
  }

  .git-list-commits,
  .git-branches,
  .git-list-not-pushed-commits,
  .git-list-not-pulled-commits,
  .repository-meta {
    border: 1px solid $color-grey;
    border-radius: 5px;
    width: 100%;
    display: flex;
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 5px;
    position: relative;
    min-height: 70px;

    .git-active-branch,
    .git-unpulled-status,
    .git-unpushed-status {
      margin-top: 20px;

      span {
        font-weight: 400;
      }
    }

    .repository-meta-buttons {
      margin: 10px 0 10px 0;
    }

    .buttons-horizontal {
      margin-left: auto;
      display: block;
      justify-content: flex-end;
      align-self: center;

      > * {
        display: inline-block;
        margin-left: 10px;
      }
    }
  }

  .git-list-commits,
  .git-file-status,
  .git-list-not-pushed-commits,
  .git-branches,
  .git-list-not-pulled-commits {
    .refresh-button {
      position: absolute;
      top: 15px;
      right: 10px;
      display: flex;
      align-items: center;
      padding: 10px 10px;
    }
  }

  .git-list-commits,
  .git-list-not-pushed-commits,
  .git-list-not-pulled-commits {
    .commit-list {
      .ct-headline {
        background-color: $color-white;
      }
    }

    .unpushed-label {
      // color: $color-text-light;
      color: $color-orange;

      & i {
        font-style: italic;
      }
    }
  }

  .git-button-bar {
    margin: 20px 0 0 0;
    display: flex;
    justify-content: flex-end;

    .button {
      margin-left: 10px;
    }

    .delete-button {
      background-color: $color-red;
    }
  }

  .branch-button-bar {
    margin-left: auto;
    display: block;
    justify-content: flex-end;

    > * {
      display: inline-block;
      margin-left: 10px;
    }
  }
}

.delete-remote {
  margin-right: auto;
  text-align: center;

  input {
    margin-top: auto;
    margin-right: 5px;
  }
}


.warning-text {
  font-weight: bold;
  color: $color-red;
}

.step-type-container {
  // background-color: deeppink;

  .repository-types {
    display: flex;
    margin-right: 10px;

    .repository-type-card, {
      width: 120px;
      height: 120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid $color-grey;
      border-radius: 5px;
      margin-right: 10px;

      &.selectable {
        cursor: pointer;
      }

      // kernel selected
      &.selected {
        border: 1px solid $color-primary;
        transition: .2s ease-in-out;
      }

      // kernel unselected
      &.unselected {
        .icon-container {
          .icon {
            filter: saturate(0) brightness(1.1);
            transition: .2s ease-in-out;
          }
        }
      }

      .icon-container {
        width: 80px;
        height: 80px;
        margin: 5px;

        .icon {
          width: 100% !important;
          height: 100% !important;
        }
      }

      .display-name {
        margin: 5px 0 0 0;
      }
    }
  }
}

.step-kernel-container {
  .kernelspec-container {
    &.selected {
      border: 1px solid $color-primary;
    }
  }
}
