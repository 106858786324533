@import '../../../../../scss/base/var';

.tableBrowserHeadline {
  cursor: default;
  margin-bottom: 10px;
  // font-weight: 400;
  display: block;
}

.loading {
  height: 160px;
  margin-top: 20px;
}

.backButtonContainer {
  margin: 0 0 20px 0;

  .backButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;

    .backIcon {
      color: $color-blue-highlight;
      margin-right: 5px;
    }

    .backText {
      color: $color-blue-highlight; // This is to make the color consistent - but doesn't look good.;
    }

    &:hover {
      .backIcon {
        color: $color-blue;
      }

      .backText {
        color: $color-blue;
      }
    }
  }
}

.imageContainer {
  margin-top: 20px;
}
