@import '../../../../../scss/base/var';

.resourceList {
  display: grid;

  .resourceListHeadlineCol {
    cursor: default;
    font-weight: 500;
    padding: 8px;
  }

  .resourceListEntriesRow {
    display: contents;

    &:hover > .resourceListEntriesCol {
      background-color: $color-light-grey;
      text-decoration: underline;
    }

    .resourceListEntriesCol {
      cursor: pointer;
      padding: 8px;
      text-decoration: none;
    }

    .loadingCol {
      padding: 8px;
    }
  }
}
