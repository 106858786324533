@import '../../../scss/base/var';

.uploadConfirmationParent {
  padding: 100px 0;
  text-align: center;
  max-width: 400px;
  font-size: 15px;
  color: $color-text-light;
  margin: 0 auto;

  p {
    padding: 0 0 40px 0;
  }

  h3 {
    margin: 0;
    color: $color-text;
    padding: 0 0 40px 0;
  }

  .backButton {
    margin: 0 auto;
  }

  .animationContainer {
    height: 64px; // Default height of spinner
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
  }

  .encodingErrors,
  .parsingErrors,
  .error {
    margin-top: 40px;
    padding: 10px;
    border: 1px solid $color-red;
    border-radius: 4px;
    color: $color-text;

    p {
      padding-bottom: 5px;
    }

    table {
      margin-top: 25px;
      margin-bottom: 10px;
      text-align: left;
      font-size: 14px;

      th {
        font-weight: 400;
      }

      th, td {
        padding: 5px;
      }

      tr {
        &:hover {
          background-color: $color-light-grey;
        }
      }
    }
  }
}

.uploadInputParent {
  height: 100px;
  display: flex;
  align-items: stretch;

  &.multiple {
    height: 200px;
  }
}

.uploadRightPart {
  margin-left: 10px;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
}

.fileParent {
  padding: 8px;
  overflow: hidden;
  box-sizing: border-box;

  .uploadText {
    cursor: default;
    display: flex;
    justify-content: space-between;

    .filename {
      color: $color-text;
      font-size: 14px;
      overflow-y: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .filesize {
      flex-shrink: 0;
      margin-left: 5px;
      color: $color-text-light;
    }
  }
}

.files {
  align-items: flex-start;
  justify-items: flex-start;
  flex: 1;
  overflow-y: auto;
  margin-bottom: 5px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}

.progressWrapper {
  display: flex;
  align-items: center;

  .progressInfo {
    width: 60px;
    text-align: right;
  }

  .progress {
    transition: width 0.25s ease-in-out, background-color 0.25s ease-in-out;

    &.done {
      background-color: $color-green;
    }
  }
}