@import '../../../../scss/base/var';

.modelManagement {
  display: flex;
  // flex-direction: column;

  .habitatsContainer {
    flex: 1 1;
    width: 1px;
    overflow-y: auto;
    padding-right: 5px; // Creating the gap between the habitats container and sidebar using the padding causes the scrollbar to run in between, so it's not overlapping the habitats

    .loadingPlaceholder {
      height: 100%;
      // background-color: white;
      // border-radius: 4px;
    }
  }
}
