@import '../../../../scss/base/var.scss';

.repositoryHeader {

  .breadCrumbs {
    margin-bottom: 20px;
  }

  .repositoryTop {
    display: flex;
    margin-top: 20px;

    .repositoryInfo {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      cursor: default;
      margin-bottom: 10px;

      .repositoryTopLine {
        margin-bottom: 10px;
        display: flex;

        .repositoryName {
          font-size: 18px;
          font-weight: 400;
          margin-right: 20px;
        }

        .repositoryItem {
          margin-right: 10px;
        }
      }

      .repositoryDescription {
        margin-bottom: 5px;
      }

      .repositoryActiveBranch {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        color: $color-text-light;

        .gitBranchSelector {
          margin-top: 5px;
          max-width: 200px;
        }
      }
    }

    .buttonContainer {
      margin-left: 10px;

      > * {
        display: inline-block;
        margin-left: 8px;
      }
    }
  }

  .latestCommit {
    background-color: $color-light-grey;
    border: 1px solid $color-grey;
    border-radius: 5px;
    margin-top: 20px;
    padding: 20px;
    cursor: default;

    .commitLineMessage {
      margin-bottom: 5px;
      font-weight: 400;

      i {
        font-style: italic; // Stupid, but otherwise it's overwritten ...
      }
    }

    .commitLineCommitter {
      .fillerText, .commitDate {
        color: $color-text-light;
      }
    }
  }

}
