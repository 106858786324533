.container {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  align-items: flex-end;

  div {
    flex-grow: 1;
  }
}

.group {
  flex-grow: 0 !important;
  height: 46px;
  display: flex;
  align-items: center;

  span {
    margin-right: .25rem;
    font-weight: 400;
  }
}
