@import '../../../../../scss/base/var';

.tableBrowserHeadline {
  cursor: default;
  margin-bottom: 10px;
  display: block;
}

.scrollableTableContainer {
  flex-grow: 1;
  overflow: scroll;
}

.backButtonContainer {
  margin: 0 0 20px 0;

  .backButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;

    .backIcon {
      color: $color-primary-lighter30;
      margin-right: 5px;
    }

    .backText {
      color: $color-primary-lighter30; // This is to make the color consistent - but doesn't look good.;
    }

    &:hover {
      .backIcon {
        color: $color-primary;
      }

      .backText {
        color: $color-primary;
      }
    }
  }
}
