.performance-drift {
  position: relative;
  text-align: center;
  padding: 19px 31px;
  height: 100%;
}
.performance--row {
  @include grid-row(12);
  @include grid-column-uncollapse(130px);
  @include breakpoint(small down) {
    @include grid-column-uncollapse(10px);
  }
}
.performance--col--left {
  @include grid-col(7);
  @include breakpoint(medium down) {
    @include grid-col(10);
    padding-bottom: 10px;
  }
  @include breakpoint(small down) {
    @include grid-col(12);
  }
}
.performance--col--center {
  @include grid-col(4);
  @include breakpoint(medium down) {
    @include grid-col(12);
  }
  @include breakpoint(small down) {
    @include grid-col(12);
  }
  color: $color-text-light;
  font-size: 12px;
}
.performance--col--right {
  @include grid-col(1);
  @include breakpoint(medium down) {
    @include grid-col(12);
    display: none;
  }
  @include breakpoint(small down) {
    @include grid-col(12);
    display: none;
  }
}
.performance--infos {
  padding: 4px 20px 4px 0;
}

.misclassification-axis-left__help-line {
  .tick {
    text{
      display: none;
    }
    line {
      stroke: #DFE2E5;
      stroke-width: 1;
    }
  }
}

.misclassification-tooltip {
  pointer-events: none;
  width: 170px;
  height: 40px;
  background: $color-white;
  position: relative;
  border: 1px solid $color-grey;
  border-radius: 4px;
  padding: 8px;
  font-size: 12px;
  text-align: left;
  &:before {
    content: "";
    position: absolute;
    left: 50%;
    border: 5px solid transparent;
    border-top-color: $color-grey;
    bottom: -10px;
    margin: 0 0 0 -5px;
  }
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    border: 5px solid transparent;
    border-top-color: $color-white;
    bottom: -9px;
    margin: 0 0 0 -5px;
  }
  .misclassification-chart_legend {
    text-align: left;
    flex: auto;
    flex-direction: column;
    padding: 0;
    color: $color-text;
    li {
      padding: 0 5px 0 0;
      display: inline-block;
      line-height: 1;
    }
  }
  .misclassification-chart_legend_dot {
    margin: 0 2px 0 0;
  }
}

.misclassification-chart_legend {
  flex: 1 1 0;
  flex-direction: row;
  order: 2;
  padding: 0;
  font-size: 11px;
  color: $color-text-light;
  text-align: right;
  li {
    padding: 2px 10px;
    display: inline-block;
    vertical-align: top;
  }
}

.misclassification-chart_legend_dot {
  width: 10px;
  height: 10px;
  vertical-align: top;
  border-radius: 50%;
  display: inline-block;
  margin: 2px 5px 0 0;
  background: #000;
}

.performance-drift--chart {
  height: 500px;
  & > div {
    height: 500px;
  }
}
.performance-drift--chart--sizer {
  height: 120px;
  & > div {
    min-height: 120px;
  }
}

.tooltip--label {
  color: $color-text-light;
  padding: 0 0 8px 0;
}
