// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group grid
////

/// Sizes child elements so that `$n` number of items appear on each row.
///
/// @param {Number} $n - Number of elements to display per row.
/// @param {String} $selector ['.column'] - Selector(s) to use for child elements.
@use "sass:math";

@mixin grid-layout(
  $n,
  $selector: '.column'
) {
  & > #{$selector} {
    width: percentage(math.div(1, $n));
    float: $global-left;

    &:nth-of-type(1n) {
      clear: none;
    }

    &:nth-of-type(#{$n}n+1) {
      clear: both;
    }

    &:last-child {
      float: left;
    }
  }
}

/// Adds extra CSS to block grid children so the last items in the row center automatically. Apply this to the columns, not the row.
///
/// @param {Number} $n - Number of items that appear in each row.
@mixin grid-layout-center-last($n) {
  @for $i from 1 to $n {
    @if $i == 1 {
      &:nth-child(#{$n}n+1):last-child {
        margin-left: (100 - math.div(100, $n) * $i) * 0.5 * 1%;
      }
    }
    @else {
      &:nth-child(#{$n}n+1):nth-last-child(#{$i}) {
        margin-left: (100 - math.div(100, $n) * $i) * 0.5 * 1%;
      }
    }
  }
}
