@import '../../../../scss/base/var';

table.keyspacesTable {
  width: 100%;
  border-collapse: collapse;

  thead {
    td {
      padding: 10px;
    }

    td:first-child {
      width: 100%;
    }
  }

  // With border-collapse separate we need to put the borders on the cells themselves
  tbody {
    td {
      padding: 10px;
      vertical-align: middle;
    }

    tr {
      border-bottom: 1px solid $color-light-grey;

      &:last-child {
        border-bottom: none;
      }
    }

    tr:hover td {
      background-color: $color-light-grey;
    }
  }
}

.keyspacesTable {
  .keyspacesHeader {
    .keyspacesHeadline {
      cursor: default;
      margin-top: auto;
      font-weight: 500;
      display: block;
    }

    .permissionCell {
      font-weight: 500;
      cursor: default;
    }
  }

  .resourceCell {
    .displayName {
      font-weight: 400;
      margin-bottom: 5px;
      display: block;
    }

    .title {
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    }

    .subInfo {
      margin-top: 5px;
      display: block;
      font-style: italic;
    }
  }

  .permissionCell {
    display: flex;
    justify-content: center;
    cursor: pointer;
    width: 45px; // Makes the permissions cell evenly wide

    .permissionMark {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      display: block;

      &.enabledMark {
        background-color: $color-primary-highlight;
      }

      &.disabledMark {
        background-color: $color-grey;
      }
    }
  }
}
