@import '../../../../../../scss/base/var';

.fullScreen {
  display: flex;
  margin-left: auto;
  cursor: pointer;
}

.chart {
  border-radius: 4px;
  min-height: 0;
  min-width: 0;
  height: 100%;
  width: 100%;
}
