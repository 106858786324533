@import '../../../../scss/base/var';

.BreadCrumbs {
  display: flex;
  margin-bottom: 10px;

  .BackTo {
    margin-right: 20px;
  }
}
