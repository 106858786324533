.browser {
  #wb-browser-tabs {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    .browser-tab-link-container {
      border-bottom: 1px solid $color_grey;
      display: flex;
      // background-color: $color-white;
      // background-color: $color-light-grey;
      border-top-left-radius: 5px;
      height: 47px;

      &.buttons-small {
        .browser-tab-link {
          justify-content: center;

          .browser-link-name{
            display: none;
          }
        }
      }

      .browser-tab-link {
        flex-grow: 1;
        flex-basis: 0;
        display: flex;
        align-items: center;
        background-color: $color-white;
        height: 46px;
        border: none;
        border-right: 1px solid $color_grey;
        font-family: $Roboto;
        font-size: 14px;
        font-weight: 300;
        color: $color-text;
        padding: 0 7px;
        max-width: 150px;

        &:first-child{
          border-top-left-radius: 5px;
        }

        .browser-tab-link-icon {
          color: $color-text;
          margin-right: 10px;
        }

        .browser-link-name{
          white-space: nowrap;
        }
      }

      .browser-tab-link:last-child{
        // border-right: none;
        border-right: 1px solid $color_grey;
        margin-right: -1px;
      }

      .browser-tab-link:focus {
        outline: none !important;
      }

      .tab-link-active {
        font-weight: 300 !important;
        background-color: $color-primary;
        color: $color-white;

        .browser-tab-link-icon {
          color: $color-white;
        }
      }
    }

    .browser-tab-content {
      height: 0;
      flex-grow: 1;
    }
  }

  .load-error-container{
    margin: 10px;
    padding: 5px;
    border-radius: 2px;
    border: 1px solid $color-red;
    background-color: $color-red-light;
    color: $color-red;
    overflow: hidden;

    .load-error-headline{
      margin: 0 0 5px 0;
      font-weight: 600;
    }

    .load-error-message{
      margin: 0;
    }
  }
}
