@import '../../../../scss/base/var';

.TabLine--container{
  .TabLine--tabs{
    border-bottom: 1px solid $color-grey;

    .tab-link {
      padding: 10px;
      margin-bottom: -2px;

      &:hover {
        outline: 0;
        border-bottom: 3px solid $color-grey;
      }

      &:focus {
        outline: 0;
      }
    }

    .tab-link-active {
      border-bottom: 3px solid $color-primary !important; //if not !important, then the tab will turn grey when hovering it
    }
  }

  .TabLine--content{
    padding: 10px;

    .tab-content {

    }
  }
}
