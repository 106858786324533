@import '../../../../../scss/base/var';

.container {
  display: flex;
  flex-direction: column;
  margin: 12px;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

.field {
  flex-grow: 1;
}

.addButton {
  display: flex;
  flex-direction: row;

  .addButtonText {
    display: inline-flex;
    margin: 10px;
  }
}
