@import '../../../../scss/base/var';

.appInitializing {

  .header {
    background: $color-primary;
    color: $color-white;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    height: 48px;

    .headerRow {
      position: relative;
      width: calc(75rem - 30px);
      max-width: 98vw;
      margin: 0 auto;
      display: flex;
    }

    .headerLeft {
      padding: 4px 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        display: inline-block;
        height: 32px;
        background-size: cover;
        vertical-align: top;
        margin: 4px 10px 4px 0;
      }
    }
  }

  .subHeader {
    padding: 0;
    position: fixed;
    left: 0;
    top: 48px;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #ddd;
    z-index: 998;
    margin: 0;
    height: 62px;
  }

  .loadingContainer {
    position: absolute;
    top: 110px;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
