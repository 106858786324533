.tables-container{
  padding: 20px 15px;
  position: relative;

  .tables-headline {
    margin: 0 5px 10px 5px;
    font-size: 15px;
    font-weight: 400;
    display: block;
  }

  .table-list-wrapper{
    &:not(:first-child){
      margin-top: 30px;
    }
  }

  .tables-buttons{
    margin-top: 40px;
  }

  .tables-empty {
    position: absolute;
    width: calc(100% - 30px); // -2x15px horizontal padding
    height: calc(100% - 40px - 43px); // -2x20px vertical padding - 43px for the buttons headline
    display: flex;
    align-items: center;
    justify-content: center;

    .tables-empty-elements {
      display: flex;
      flex-direction: column;
      text-align: center;

      .tables-empty-pic {
        width: 107px;
        height: 107px;
        margin: 0 auto 25px auto;
        background: url("../../img/tablesEmpty.png") no-repeat;
        background-size: cover;
      }

      .headline {
        font-size: 18px;
        font-weight: 400;
        padding: 0 0 15px 0;
      }

      .description {
        font-size: 14px;
        color: $color-text-light;
      }

      .button-container{
        margin-top: 40px;

        > * {
          margin: 0 auto;
        }
      }
    }
  }

  .form-buttons--right{
    margin-bottom: 30px;
  }

  .action-icon{
    cursor: pointer;
    color: $color-primary;
  }
}
