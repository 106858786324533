@import '../../../scss/base/var';

.parentLayout {
  height: 100%;
  position: relative;

  .parentLayoutBody {
    height: 100%;
    max-width: $max-page-width;
    margin: 0 auto;
    display: flex;

    .parentLayoutLeft {
      background-color: $color-light-grey;
      width: 260px;
      flex-shrink: 0;
      padding-top: 48px; // to move below the header
    }

    .parentLayoutRight {
      height: 100%;
      width: 1px; // Make the right part flex grow all the remaining screen width
      position: relative;
      flex: 1;
    }
  }

  &.menuCollapsed {
    .parentLayoutLeft {
      width: 65px;
    }
  }
}
