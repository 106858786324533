.modalContent {
  span {
    display: block;

    &.affected {
      margin-top: 10px;
      font-weight: 400;
    }
  }

  .conflicts {
    .fileName {
      display: list-item;
      font-size: 14px;
      margin-left: 10px;
      list-style-type: '- '
    }
  }
}
