.container {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

.table {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 350px;
  height: 100%;
  & a {
    text-decoration: none;
  }
}
