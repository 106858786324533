@import '../../../../scss/base/var.scss';

.workbenchMetrics {

}

.metricsTitle {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
  display: block
}

.chartParent {
  height: 130px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.chartHeadline {
  cursor: default;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    font-weight: 500;
  }
}

.chart {
  position: relative;
  height: 100px;
}

.legend {
  display: flex;
  padding: 0 10px 10px 10px;
  justify-content: center;

  .legendItem {
    display: flex;
    margin: 0 10px;
    align-items: center;

    .legendIcon {
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 5px;
    }

    .legendIconBackgroundVarA {
      background-color: #6AA0EB;
    }

    .legendIconBackgroundVarB {
      background-color: #b9c3cb;
    }

    .legendIconBackgroundVarC {
      background-color: #f2ad5f;
    }

    .legendLabel {
      font-weight: 400;
      cursor: default;
    }
  }
}
