@import '../../../../../scss/base/var.scss';

.dataSourcePermissions {
  height: 100%;

  :global(.SideMenu) {
    background-color: $color-white;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    overflow-y: auto;
    border-right: 1px solid $color-light-grey;
  }

  .permissionsContainer {
    padding: 20px 15px;
    background-color: $color-white;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 100%;
    overflow-y: auto;
  }
}
