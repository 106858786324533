@import '../../../../../scss/base/var';

.button-bar {
  display: flex;
  border-bottom: 1px solid $color-grey;
  // padding: 5px 15px 5px 5px;
  background-color: $color-white;
  height: 41px;
  align-items: center;
  padding: 0 5px;

  .IconButton {
    width: 30px;
    height: 30px;
    border-radius: 2px;
    flex-grow: 0;
    flex-shrink: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-text;
    margin: 5px 5px 5px 0;

    &:hover {
      background-color: $color-grey;
      color: $color-white;
    }

    &.disabled {
      color: $color-grey;

      &:hover {
        background-color: $color-white;
        color: $color-grey;
      }
    }
  }
}

