@import '../../../../scss/base/var';

.AccountAddPhoto {
  width: 155px;

  .AccountAddPhoto--grey-block {
    background: $color-light-grey;
    border-radius: 4px;
    border: 1px solid $color-grey;
    margin: 0 0 21px 0;

    .AccountAddPhoto--wrap {
      position: relative;
      overflow: hidden;

      .AccountAddPhoto--dz {
        cursor: pointer;
        display: flex;
        height: 155px;
        text-decoration: none;
        text-align: center;
        position: relative;
        color: $color-text-light;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
        }
      }

      .AccountAddPhoto--dz-no-avatar {
        &:hover {
          color: $color-text;
        }
        [class^="icon-"], [class*=" icon-"] {
          border-radius: 50%;
          line-height: 46px;
          height: 48px;
          width: 48px;
          font-size: 30px;
          margin: 0 0 10px 0;
          display: inline-block;
          color: $color-text-light;
          border: 1px solid rgba($color-text-light, .4);
        }
      }

      .AccountAddPhoto--dz-avatar {
        &:hover {
          opacity: 0.5;

          .fa-pencil {
            opacity: 1;
          }
        }

        .fa {
          position: absolute;
          text-shadow: 1px 1px 0 $color-white, -1px -1px 0 $color-white;
        }

        .fa-pencil {
          opacity: 0;
          top: calc(50% - 15px);
          left: calc(50% - 15px);
          font-size: 30px;
          color: $color-text;
        }

        .fa-times {
          opacity: 0.8;
          top: 0;
          right: 0;
          padding: 5px 5px 0 0;
          font-size: 20px;
          text-align: right;
          color: $color-red;

          &:hover {
            opacity: 1;
          }
        }
      }

      .AccountAddPhoto--block-in {
        line-height: 0;
        display: flex;
      }
    }
  }
}
