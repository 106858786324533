@import "../../../../../scss/base/var";

.quickMenu {
  margin-left: 10px;
  position: relative;

  .quickMenuIcon {
    height: 30px;
    width: 30px;
    border-radius: 18px;
    border: 1px solid transparentize($color-white, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 50ms ease-in-out, border 50ms ease-in-out;

    svg {
      transition: opacity 50ms ease-in-out;
      opacity: 0.8;
    }

    &:hover {
      border: 1px solid transparent; // If this isn't set, there will be a border since the semi-transparent border and background colors are added on top of each other
      background-color: transparentize($color-white, 0.8);

      svg {
        opacity: 1;
      }
    }
  }
}

.quickMenuContent {
  position: absolute;
  left: -167px;
  width: 360px;
  top: 39px; // TODO: I don't know why 39px, but is the correct position ...
  background: $color-white;
  padding: 10px 10px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 8px; // #9;
  z-index: 999;
  color: $color-text;

  &:after {
    content: "";
    position: absolute;
    left: calc(50% - 4px);
    top: -12px;
    border-style: solid;
    border-width: 6px;
    border-color: transparent;
    border-bottom-color: $color-white;
  }

  .empty {
    display: flex;
    justify-content: center;
    align-items: center;

    .emptyMessage {
      font-style: italic;
    }
  }

  .entry {

    &:not(&:last-child) {
      border-bottom: 1px solid $color-light-grey;
      padding-bottom: 5px;
      margin-bottom: 5px;
    }

    a {
      text-decoration: none !important;
    }

    .entryInner {
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 8px;
      padding: 6px 4px;

      .iconContainer {
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
      }

      .textContainer {
        flex-grow: 1;

        .title {
          display: block;
          font-weight: 400;
        }

        .description {
          display: block;
        }
      }

      &:hover {
        background: var(--color-primary-lighter90, #e4ecf9);

        svg, span {
          color: var(--color-primary, #224e90);

          path {
            stroke: var(--color-primary, #224e90);
          }

        }

        .title {
          color: var(--color-primary, #224e90);
        }
      }
    }
  }
}
