@use "../../../../../../../scss/base/theme.module";
@import '../../../../../../../scss/base/var';

.taxonomy {
  padding: 20px 10px 10px 10px;
  height: calc(100% - 130px);
  position: relative;

  .placeholder {
    height: 96% !important;
    width: 98% !important;
    border: 1px solid $color-grey;
    padding: 10px;
    position: absolute;
  }

  .placeholderLabel {
    text-align: center;
    color: $color-grey;
  }

  button{
    &:focus {
      outline: none !important;
    }
  }

  .icon {
    stroke: $color-text !important;
    margin: 8px;

    &:hover{
      stroke: theme.$color-primary !important;
    }
  }

  p {
    padding: 0;
  }

  .nodeInvalid {
    background-color: $color-red-light !important;
    border: 1px solid $color-red !important;
  }

  .inputTitle,
  .inputSubtitle {
    padding: 0;
    margin-left: -2px;
    color: $color-text;
    border: 1px solid $color-grey;
    width: 182px;
    &:focus{
      outline: none;
    }
  }

  .inputTitle{
    border-bottom: none;
    margin-bottom: 0;
  }
  .inputSubtitle{
    border-top: none;
    margin-top: 0;
  }

  .buttonBar {
    display: flex;
    justify-content: flex-end;
    margin-top: 14px;
    margin-right: 20px;

    .button {
      width: 120px;
      height: 40px;
      border: none;
      border-radius: 20px;
      font-size: 14px;
      font-weight: 300;
      margin-left: 10px;

      &:focus {
        outline: none;
      }

      &.buttonNeutral {
        background-color: $color-white;
        color: theme.$color-primary;

        &:hover {
          background-color: theme.$color-primary;
          color: $color-white;
        }
      }

      &.buttonOk {
        color: $color-white;
        background-color: theme.$color-secondary;

        &:disabled {
          opacity: 0.25;
        }
      }
    }
  }

  .addRemoveBar{
    display: flex;
    flex-direction: row;
    padding-left: 44px;
    padding-bottom: 20px;
    border-bottom: 1px solid $color-grey;
    margin-bottom: 10px;

    .addTaxonomyContainer {
      display: flex;
      flex-direction: row;

      .iconContainer{
        width: 44px;
        height: 44px;
        background-color: theme.$color-secondary;
        border: 1px solid theme.$color-secondary-darker30;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .icon{
          stroke: $color-white !important;
        }
      }

      .inputContainer{
        display: flex;
        flex-direction: column;
        height: 44px;
        width: 230px;
        border: solid $color-text-light 1px;
        border-left: none;
        background-color: $color-light-grey;
        margin: 0;
        padding: 5px 10px;
        .inputTitle, .inputSubtitle{
          width: 210px;
        }
        .inputTitle{
          font-weight: bold;
        }
      }
    }

    .removeDropzone{
      display: flex;
      flex-direction: row;
      margin-left: 10px;

      .iconContainer{
        width: 44px;
        height: 44px;
        background-color: $color-red;
        border: 1px solid $color-red-dark;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .icon{
          stroke: $color-white !important;
        }
      }

      .inputContainer{
        height: 44px;
        width: 230px;
        border: 1px solid $color-text-light;
        border-left: none;
        background-color: $color-light-grey;
        margin: 0;
      }

      .inputContainerPlaceholder {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          color: $color-black;
          opacity: 40%;
        }
      }

      &.isOver{
        .inputContainer{
          background-color: $color-red-light;
          border: 1px solid $color-red;
        }
      }
    }
  }
}
