@import '../../../../../../../src/scss/base/var.scss';

.cardsContainer {

}

.card {
  width: 260px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid $color-grey;
  border-radius: 5px;
  margin: 5px 0;
  cursor: pointer;
  overflow: hidden;
  transition: .2s ease-in-out;
  background-color: $color-white;

  &:hover {
    background-color: $color-light-grey;
  }

  // kernel selected
  &.selected {
    border: 1px solid $color-primary-highlight;
    background-color: $color-light-grey;
  }

  // kernel unselected
  &.unselected {
    .icon-container {
      .icon {
        filter: saturate(0) brightness(1.1);
      }
    }
  }

  .iconContainer {
    width: 40px;
    height: 40px;
    margin: 5px 10px;

    .icon {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .displayName {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
