@use "../../../../../../../scss/base/theme.module";
@import '../../../../../../../scss/base/var';

.descriptionOption {
  background-color: $color-white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  justify-content: center;

  &.isSelected {
    background: theme.$color-secondary-lighter90;

    &:after {
      content: "\e904";
      font-family: 'dashboardNG';
      position: absolute;
      font-size: 18px;
      right: 10px;
      line-height: 23px;
      color: theme.$color-secondary;
    }
  }

  &.isDisabled {
    background-color: $color-light-grey;
    cursor: default;

    .label,
    .descriptioin {
      color: $color-text-light;
    }
  }

  &:hover {
    background-color: $color-light-grey;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $color-grey;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $color-text;
  }

  .description {
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $color-text-light;
  }
}
