@import '../../../../../scss/base/var';
@import '../../../../../scss/base/utils'; // for the strip-unit function

$job-width-large: 180px;
$job-height-large: 90px;

:export {
  jobWidthLarge: strip-unit($job-width-large);
  jobHeightLarge: strip-unit($job-height-large);
}

.job {
  background-color: $color-white;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 8px;
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: $color-light-grey;
  }

  &.jobLarge {
    width: $job-width-large;
    height: $job-height-large;
    padding: 12px 10px 0 15px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .jobType {
      margin-bottom: 5px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
    }

    .jobName {
      width: 22ch;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .jobPriority {
      width: 16px;
      height: 16px;
      line-height: 16px;
      font-size: 16px;
    }

    .codeCapsuleVersion {
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: $color-black;
      border-top-right-radius: 4px;
      border-bottom-left-radius: 4px;
      height: 16px;
      max-width: 50%;
      padding: 2px 4px 2px 4px;
      z-index: 3;

      span {
        display: block;
        color: $color-white;
        font-weight: 400;
        font-size: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .codeCapsuleVersion::before {
      content: "";
      position: absolute;
      width: 10px;
      height: 5px;
      background-color: transparent;
      right: -10px;
      bottom: 0;
      border-bottom-left-radius: 5px;
      box-shadow: -5px 0 0 0 $color-black;
    }
  }

  &.jobSlim {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .statusBar {
      left: 0;
      right: 0;
      height: 4px;
      border-radius: 0 0 4px 4px;
      transition: background-color 0.25s ease-in-out;
    }
  }

  &.clickable {
    cursor: pointer;
  }

  .jobStatus {
    font-size: 11px;
    text-align: right;
    position: absolute;
    bottom: 1px;
    right: 5px;
    color: $color-white;
    z-index: 3;
  }

  .statusBar {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 16px;
    z-index: 2;
    border-radius: 0 0 4px 4px;

    &.statusBarBackground {
      z-index: 1;
      background-color: $color-light-grey !important;
    }
  }

  &.statusRunning {
    .statusBar {
      background: $color-blue;
    }

    .jobStatus {
      color: $color-white;
    }
  }

  &.statusWaiting {
    .statusBar {
      background: $color-grey;
    }

    .jobStatus {
      color: $color-dark-grey;
    }
  }

  &.statusWaitinInExecutionQueue {
    .statusBar {
      background: $color-dark-grey;
    }

    .jobStatus {
      color: $color-white;
    }
  }

  &.statusTriggered {
    .statusBar {
      background: $color-purple;
    }

    .jobStatus {
      color: $color-white;
    }
  }

  &.statusSuccess {
    .statusBar {
      background: $color-green;
    }

    .jobStatus {
      color: $color-white;
    }
  }

  &.statusCancelled {
    .statusBar {
      background: $color-orange;
    }

    .jobStatus {
      color: $color-white;
    }
  }

  &.statusFailure {
    .statusBar {
      background: $color-red;
    }

    .jobStatus {
      color: $color-white;
    }
  }

  .buttons {
    position: absolute;
    left: 5px;
    top: 5px;
  }

  .button {
    color: $color-text-light;

    &:not(:last-child) {
      margin-right: 5px;
    }

    &:hover {
      cursor: pointer;

      &.editButton {
        color: $color-primary;
      }

      &.deleteButton {
        color: $color-red;
      }
    }
  }

  .jobPriority {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 10px;
    height: 10px;
    line-height: 10px;
    font-size: 10px;
    background-color: $color-grey;
    border-radius: 4px 0 4px 0;

    svg {
      color: white;
    }

    &.priorityHighest {
      background-color: $color-red-dark
    }
    &.priorityHigh {
      background-color: $color-red;
    }
    &.priorityLow {
      background-color: $color-grey;
    }
    &.priorityLowest {
      background-color: $color-dark-grey;
    }
  }
}

/** Setting the border here, makes the handles lie *on* the border */
.flowJob {
  background-color: $color-white;
  border: 1px solid $color-dark-grey;
  border-radius: 5px;
  cursor: default;
}

.flowHandleLarge {
  width: 10px !important;
  height: 10px !important;
  border: 1px solid $color-dark-grey !important;
  background-color: $color-white !important;
  cursor: default !important;

  &.flowHandleLeft {
    left: -5px !important;
  }

  &.flowHandleRight {
    right: -5px !important;
  }
}

.flowHandleSlim {
  width: 6px !important;
  height: 6px !important;
  border: 1px solid $color-dark-grey !important;
  background-color: $color-white !important;
  cursor: default !important;

  &.flowHandleLeft {
    left: -3px !important;
  }

  &.flowHandleRight {
    right: -3px !important;
  }
}

.flowHandleDisabled {
  // Overrides react-flow__handle.connectable. Is there a way to not set that class in the first place?
  cursor: default !important;
}

.progress {
  position: absolute;
  top: 3px;
  right: 3px;
  color: $color-text-grey;
}
