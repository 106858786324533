@import '../../../../scss/base/var';

.mainMenu {
  height: calc(100vh - 48px); // Full height - navbar
  display: flex;
  flex-direction: column;

  .entriesContainer {
    flex: 1;
    padding-top: 5px;
    padding-bottom: 5px;
    border-right: 1px solid $color-grey;
    overflow-y: auto;
  }
}

.menuEntries {
  padding: 8px 0;
}

.menuEntry {
  margin-bottom: 2px;
  margin-left: 5px;
  margin-right: 5px;

  .entry {
    display: flex;
    align-items: center;
    text-decoration: none;
    border-radius: 8px;
    height: 32px;
    position: relative;
    padding-left: 7.5px;
    padding-right: 7.5px;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    .icon {
      align-items: center;
      display: flex;
      height: 30px;
      justify-content: space-around;
      width: 30px;

      svg, span {
        color: $color-text;

        path {
          stroke: $color-text;
        }
      }
    }

    .title {
      font-weight: 400;
      color: $color-text;
      // color: $color-text;
      // font-weight: 500;
      font-size: 13px;
      flex-grow: 1;
    }

    .expandToggle {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;

      svg {
        stroke: $color-text-light;
      }
    }

    &.isActive, &:hover {
      background: var(--color-primary-lighter90, #e4ecf9);

      svg, span {
        color: var(--color-primary, #224e90);

        path {
          stroke: var(--color-primary, #224e90);
        }

      }

      .title {
        color: var(--color-primary, #224e90);
      }
    }

    &.isExpanded {
      .expandToggle {
        transform: rotate(90deg);
      }
    }

    &.mainEntry {
      cursor: pointer;

      .title {
        margin-left: 6px;
      }

      .icon {
        margin-left: 5px;
      }
    }

    &.subMenuEntry {
      margin-left: 30px;
      padding-left: 3px;
      transition: margin-left 200ms ease-in-out;

      .title {
        margin-left: 2px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: -5px; // To lap over the margin
        left: -3px;
        width: 1.5px;
        background-color: $color-text-light;
        opacity: 0.6;
        border-radius: 0;
        transition: opacity 200ms ease-in-out;
      }

      &:first-child::before {
        top: -5px;
      }

      &:last-child::before {
        bottom: 5px;
      }
    }

    &.loadingEntry {
      .title {
        font-style: italic;
      }
    }
  }
}

.menuHr {
  margin: 10px 0;
  border-bottom: 1px solid $color-grey;
}

.subMenuHr {
  margin: 5px 8px 5px 38px;
  border-bottom: 1px solid $color-grey;
  overflow: visible;
  position: relative;
  transition: margin-left 200ms ease-in-out;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: -6px; // To lap over the margin
    left: -11px;
    width: 1.5px;
    background-color: $color-text-light;
    opacity: 0.6;
    border-radius: 0;
    transition: opacity 200ms ease-in-out;
  }
}

.subMenuHeadline {
  color: $color-text-light;
  margin-left: 38px;
  padding-left: 3px;
  margin-bottom: 5px;
  font-weight: 400;
  cursor: default;
  position: relative;
  margin-top: 10px;
  transition: margin-left 200ms ease-in-out;

  .headline {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:before {
    content: '';
    position: absolute;
    top: -5px;
    bottom: -5px; // To lap over the margin
    left: -11px;
    width: 1.5px;
    background-color: $color-text-light;
    opacity: 0.6;
    border-radius: 0;
    transition: opacity 200ms ease-in-out;
  }
}

.menuHeadline {
  color: $color-text-light;
  margin-left: 5px;
  padding-left: 20px;
  margin-bottom: 5px;
  font-weight: 400;
  cursor: default;
}

.menuTooltip {
  display: none;
  // We had to make the following properties !important since they will be overriden by the styles of the react-tooltip library otherwise
  border-radius: 8px !important;
  background: var(--color-primary-lighter90, #e4ecf9) !important;
  color: var(--color-primary, #224e90) !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  opacity: 100% !important;
  height: 30px !important;
  z-index: 150 !important;
}

// Overrides for collapsed menu
.menuCollapsed {
  .mainMenuLeft {
    width: 65px;
  }

  .menuEntry {
    // border: 1px solid $color-grey;
    border-radius: 8px;
    padding: 0;
    width: 55px;

    .entry {
      position: relative;
      justify-content: center;
      padding-left: 0;
      padding-right: 0;

      .title {
        display: none;
      }

      .icon {
        margin-left: 0;
        padding-left: 0;
      }

      .expandToggle {
        position: absolute;
        left: 2px;
      }

      &.subMenuEntry {
        margin-left: unset;

        .icon {
          // margin-left: 5px;
        }

        &::before {
          left: 8.5px;
          opacity: 0.8;
        }
      }
    }

    .subMenuHr {
      margin-left: 18.5px;

      &:before {
        left: -10px;
      }
    }

    .subMenuHeadline {
      margin-left: 18.5px;

      &:before {
        left: -10px;
      }
    }
  }

  .menuTooltip {
    display: unset;
  }

  .menuHeadline {
    width: 100%;
    margin-left: 0;
    padding: 0;
    text-align: center;
  }
}
