@use "../../../../scss/base/theme.module";
@import '../../../../scss/base/var';

.elementCardTable {

  overflow: hidden;

  .table {
    width: 100%;
    overflow: hidden;
  }

  .elementCardTableRow {
    cursor: pointer;

    &.alerting {
      .colorBar {
        background-color: $color-red !important;
      }

      .header {
        color: $color-red !important;
      }
    }

    &.greyedOut {
      .colorBar {
        background-color: $color-text-light !important;
      }

      .header,
      .subHeader,
      .info,
      .bottomKey,
      .bottomValue {
        color: $color-text-light !important;
      }
    }

    &:hover {
      .header {
        text-decoration: underline;
      }
    }

    .headerCombined {
      display: flex;
      align-items: center;

      .colorBar {
        width: 2px;
        height: 17px;
        border-radius: 1px;
        margin-right: 10px;
      }

      .header {
        font-size: 13px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .subHeader {
      color: $color-text-light;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .info {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 4px;

      .infoEntry {
        display: flex;
        flex-direction: row;
        flex-grow: 1;

        .infoKey {
          color: $color-text-light;
          margin-right: 5px;
        }

        .infoValue {

        }
      }
    }

    .error {
      color: red;
    }

    .bottom {
      .bottomKey {
        color: $color-text-light;
        margin-right: 5px;
      }

      .bottomValue {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .iconLine {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .link {
        text-decoration: none;
      }

      .iconParent {
        margin-left: 5px;

        div { // Not really cool - is there a way to select the icons more precisely?
          transition: color 0.1s ease-in-out;

          &:hover {
            color: $color-text !important;
          }
        }
      }

      .progress {
        color: theme.$color-secondary;
        display: flex;
        align-items: center;

        .progressNumber {
          margin-right: 2px;
          white-space: nowrap;
        }

        .progressIcon {
          font-size: 19px;
        }
      }
    }
  }
}
