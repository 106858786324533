@import "../../../../scss/base/var.module";

.loadingPlaceholder {
  background: linear-gradient(-45deg, $color-grey, $color-grey, lighten($color-grey, 5%), $color-grey);
  background-size: 400% 400%;
  animation: gradient 4s ease-in-out infinite;
  width: fit-content;
  height: fit-content;

  .children {
    // To get the exact size, but do not actually show the child elements
    visibility: hidden;
  }
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  30% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
