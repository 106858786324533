@import '../../../../scss/base/var';

.tableContainer {
  border: 1px solid $color-grey;
  border-radius: 4px;
  overflow: hidden;
  background-color: $color-white;
  position: relative;
  width: fit-content;

  table {
    border-spacing: 0;
    font-size: 13px; // This is not consistent with the usual table we use (there we have 12px font-size). But 13 px makes the content slightly better readable
    table-layout: auto;

    tr {
      &:hover {
        background-color: $color-very-light-grey;
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }

    th {
      color: #a6aeb5;
      font-weight: 400;
      border-bottom: 1px solid $color-grey;
      padding: 0.5rem 0.625rem 0.625rem;
      text-align: left;
      cursor: default;

      max-width: 300px;
      overflow: hidden;
    }

    td {
      border-bottom: 1px solid $color-grey;
      border-top: 1px solid $color-grey;
      padding: 0.3rem 0.625rem 0.3rem;
      text-align: left;
      cursor: text;
      line-height: 18px;

      &.long {
        .tableValue {
          white-space: break-spaces;
          min-width: 350px;
        }
      }
    }
  }
}

// Element inside td if not in edit mode
.tableValue {
  white-space: pre; // Intention: Only break at actual line breaks ("\n")
  width: 100%;
  display: block;

  max-width: 350px;
  overflow-y: hidden;

  em {
    color: $color-dark-grey;
    font-style: italic !important;
  }
}

// Element inside td if in edit mode
.textArea {
  // left-margin -4px compensates for 2px padding and 2px border.
  // It ensures the text doesn't "jump" to the right when the span is replaced with the input element
  margin: 0 0 0 -4px;
  border: 2px solid $color-primary-highlight;
  padding: 2px;
  border-radius: 4px;
  width: 350px;
  font-size: 13px;
  resize: none;

  &:focus {
    outline-width: 0;
  }
}
