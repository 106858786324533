@import '../../../../scss/base/var';

$col-border-grey-lighter: #dce2eb;
$tooltip-background-color: $color-white;
$tooltip-border-color: $color-grey;

.tableHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 160px;
  gap: 10px;
  width: 100%;

  position: sticky;
  top: 0;
  background-color: white;
}

.name {
  display: grid;
  grid-row: 1;
  grid-column: 1;
  margin-right: 10px;
  align-self: center;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: inherit;
  overflow: auto;
  grid-row: 1;
  grid-column: 2;
}

.field {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.item {
  display: grid;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px 0;
  border: solid $col-border-grey-lighter;
  border-width: 0 0 1px 0;
  cursor: pointer;

  &.notInteractive {
    cursor: default;
  }
}

.row {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 160px;
}

.buttons {
  display: flex;
  align-items: center;

  grid-row: 1;
  grid-column: 6;

  div {
    margin: 0; // Remove the margin of the IconButton component
  }
}

.buttonsWrapper {
  height: 32px;
  border: 1px solid $color-grey;
  border-radius: 16px;
  padding: 0 10px;
  display: flex;
}