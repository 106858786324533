@import '../../../../../../../../scss/base/var';

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .shapChart {
    flex: 1;
    overflow: auto;
  }

  .legend {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    .gradient {
      height: 20px;
      width: 200px;
      margin: 0 20px;
    }

    .label {
      width: 10px;
      flex-grow: 1;
      text-align: left;

      &.left {
        text-align: right;
      }
    }
  }

  .tooltip {
    background-color: white;
    border: 1px solid #dce2eb;
    border-radius: 4px;
    padding: 10px;
  }
}
