.container {
  height: 100%;
}

.content {
  margin-top: 0;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  padding: 20px 15px;
  height: 100%;
}

.listPageParent {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.listContainer {
  padding: 0;
}

.pagingWrapper {
  height: 1px;
  flex-grow: 1;
  overflow-y: auto;
}
