@import "../../../../../scss/base/var";
@import "../../../../../scss/vendor/foundation/foundation.scss";

$height: 30px;

.searchBar {
  position: relative;
  display: inline-block;
  height: $height;
  border-radius: calc($height / 2);
  vertical-align: top;
  padding: 0 0 0 4px;
  border: 1px solid transparentize($color-white, 0.8);
  transition: background-color 50ms ease-in-out, border 50ms ease-in-out;

  &:hover {
    border: 1px solid transparent;
    background-color: transparentize($color-white, 0.8);

    .inputGroupButton {
      .button {
        opacity: 1;

        :global(.icon-search) {
          color: $color-white;
        }
      }
    }
  }

  @media (max-width: 780px) {
    display: none;
  }
  @include breakpoint(small down) {
    display: none;
  }

  .inputGroup {
    margin: 0;
    border-radius: 3px;
    width: 240px;
    box-sizing: border-box;
    flex: 1 1;
    box-shadow: none;
    font-size: 14px;
    display: flex;

    @include breakpoint(medium down) {
      width: 323px;
    }
  }

  .inputGroupField {
    color: $color-white;
    padding: 0;
    height: $height;
    border: none;
    background: none;
    min-width: 0;
    font-size: 14px;

    @include placeholder {
      color: $color-white;
    }

    &:active, &:focus {
      border: none;
      background: none;
    }

    &::-ms-clear {
      display: none;
    }
  }

  .inputGroupButton {
    vertical-align: middle;

    .button {
      height: $height;
      text-align: center;
      padding: 0 0 2px 0;
      cursor: pointer;
      width: 34px;
      margin: 0 2px 0 0;
      background: none !important;
      opacity: .8;
      box-shadow: none;

      :global(.icon-search), :global(.icon-refresh) {
        font-size: 16px;
        line-height: 26px;
        color: $color-white;
        margin: 1px 3px -1px 0;
        opacity: 1;
        display: block;
      }

      .iconCross {
        margin: 2px 0 -2px 3px;
        opacity: 1;
      }
    }
  }
}
