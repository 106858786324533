@import "theme.module";

/* Fonts  */
$Roboto: 'Roboto', sans-serif;
$font-monospace: 'Monaco', monospace;
// $font-monospace: 'Roboto Mono', monospace;

/* Colors */
$color-blue: #224e90;
$color-blue-highlight: #6aa0eb;
$color-blue-highlightx: #a1c0f3;
$color-grey: #dce2eb;
$color-blue-grey: #EEF2F6;
$color-black: #3a3a3a;
$color-dark-grey: #9f9f9f;
$color-red: #EC3D3D;
$color-red-light: #F4E9E9;
$color-red-dark: #c23030;
$color-green: #9fd356;
$color-green-light: #eef4df;
$color-green-dark: #5EA400;
$color-link: #3971b2;
$color-orange: #f2ad5f;
$color-orange-light: #f9e3b5;
$color-orange-dark: #f38a12;
$color-yellow: #f2dc22;
$color-yellow-light: #f5edb6;
$color-lime: #c2d35b;
$color-purple: #8c2290;

$color-light-grey: #eef0f2;
$color-very-light-grey: #f5f7f9;
$color-white: #fff;

$color-text: #071b32;
$color-text-grey: #394d63;
$color-text-light: #a6aeb5;

// Orchestration colors
$col-status-grey: #78909c;
$col-status-green: #388e3c;
$col-status-orange: $color-orange;
$col-status-red: #c62828;
$col-status-blue: $color-blue;
$col-status-purple: #7b1fa2;

/* Fonts Sizes  */
$size: 14px;
$size-line: 18px;

$size-p: 16px;
$size-p-line: 20px;

$size-input: 14px;
$size-header: 14px;

/* Screen-sizes */
$media-xs: 480px;
$media-sm: 640px;
$media-md: 768px;
$media-lg: 1024px;
$media-xl: 1280px;

/* The maximum supported page width, if a screen is larger than this value, the app will simply be centered */
$max-page-width: 2200px;

/* Mixins  */
@mixin font-size($size) {
  font-size: calc(#{$size} + 14%);
}

@mixin linearGradient($top, $bottom) {
  background: $top;
  background: -moz-linear-gradient(top, $top 0%, $bottom 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $top), color-stop(100%, $bottom));
  background: -webkit-linear-gradient(top, $top 0%, $bottom 100%);
  background: -o-linear-gradient(top, $top 0%, $bottom 100%);
  background: -ms-linear-gradient(top, $top 0%, $bottom 100%);
  background: linear-gradient(to bottom, $top 0%, $bottom 100%);
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content
  }
  &:-moz-placeholder {
    @content
  }
  &::-moz-placeholder {
    @content
  }
  &:-ms-input-placeholder {
    @content
  }
}

@mixin column($n) {
  -webkit-column-count: $n;
  -moz-column-count: $n;
  column-count: $n;
}

@mixin column-gap($nx) {
  -webkit-column-gap: $nx;
  -moz-column-gap: $nx;
  column-gap: $nx;
}
