@import "../../../../../scss/base/var";

.CollapsibleHeadlineArea {

  &Header {
    display: flex;
    cursor: pointer;

    &:hover {
      span {
        color: $color-primary;
      }
    }

    span {
      color: $color-text;
      font-weight: 400;
      font-size: 15px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      transition: color 0.1s ease-in-out;
    }

    &Icon {
      margin-right: 5px;
    }
  }

  &Body {
    margin-bottom: 30px;
  }

}
