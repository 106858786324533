.ChangePassword {
  margin: 0 0 60px 0;

  &--Field--Parent {
    margin-bottom: 1rem;
  }

  .InputError {
    margin-top: 11px;
    display: block;
  }

  .buttons {
    margin-top: 20px;

    > * {
      margin-left: 10px;
    }
  }
}
