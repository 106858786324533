@import "../../../../../scss/base/var";

.CodeCellOutputs {
  display: flex;
  flex-direction: row;

  &:first-child {
    padding-top: 5px; //This puts the first line at the same height as the sign in the button (if there is one)
  }

  &:last-child {
    padding-bottom: 10px;
  }

  .CodeCellOutputs--container {
    // border: 1px solid $color-grey;
    border-radius: 2px;
    font-family: $font-monospace !important;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: pre-wrap;
    padding: 0 5px;

    &-stream {
      white-space: pre;

      span {
        display: inline;
        white-space: pre-wrap;
      }
    }

    // Error outputs
    &-error {
      color: $color-red;

      .output-error-name {
        font-weight: 600;
      }

      .output-error-value-container {
        margin-top: 5px;
        height: 0; //Controlled by animejs
        opacity: 0; //Controlled by animejs
        .output-error-value {
          margin: 0;
        }
      }

      .output-error-traceback {
        margin-top: 10px;

        code {
          font-family: $font-monospace !important;
        }
      }
    }

    // Table styling
    table {
      border: none;
      border-spacing: 0;
      border-collapse: collapse;
      margin: 10px 0;
      text-align: right;

      th, td {
        border: 0 solid #fff;
        padding: 5px 10px;
        text-align: left;
      }

      font-family: $Roboto;
      font-size: 14px;

      thead {
        border-bottom: 1px solid $color-grey !important;
        font-weight: 500;
        color: $color-text;
      }

      tbody {
        tr:nth-child(even) {
          background-color: $color-very-light-grey;
        }

        tr:hover {
          background-color: $color-light-grey;
        }
      }
    }

    // ? styling
    dl {
      white-space: normal;

      dl, dt {
        font-weight: 300;
      }

      dd {
        margin-left: 2em;
      }
    }

    // List styling
    ol, ul {
      white-space: normal
    }
  }

}
