@import '../../../../scss/base/var';

.elementCard {
  word-wrap: break-word;
  word-break: break-all;
  background-color: $color-light-grey;
  height: 130px;
  min-width: 265px;
  position: relative;
  border-radius: 2px;
  border: 1px solid $color-grey;

  &:hover {
    .header {
      text-decoration: underline;
    }
  }

  .content {
    position: relative;
    padding: 10px 12px 11px 25px;
    height: 100%;
    width: 100%;
    font-size: 12px;
    text-decoration: none;
    display: flex;
    flex-direction: column;

    .contentHeader {
      display: flex;

      .contentHeaderLeft {
        width: 0;
        flex-grow: 1;

        .header {
          display: block;
          margin-bottom: 1px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          line-height: 20px;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: .2px;
          text-transform: none;
        }

        .subheader {
          display: block;
          color: $color-text-light;
        }
      }

      .contentHeaderRight {
        flex-shrink: 0;
        flex-grow: 0;

        .iconLine {
          display: flex;
          flex-direction: row;
          justify-content: center;

          .link {
            text-decoration: none;
          }

          .iconParent {
            margin-left: 5px;
            position: relative;

            div { // Not really cool - is there a way to select the icons more precisely?
              transition: color 0.1s ease-in-out;

              &:hover {
                color: $color-text !important;
              }
            }
          }

          .progress {
            color: $color-text-grey;
            display: flex;
            align-items: center;

            .progressNumber {
              margin-right: 2px;
              font-weight: 400;
            }

            .progressIcon {
              font-size: 19px;
            }
          }
        }
      }
    }

    .contentSpacer {
      flex-grow: 1;
    }

    .contentInfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 4px;

      .infoEntry {
        display: flex;
        flex-direction: row;

        .infoKey {
          color: $color-text-light;
          margin-right: 5px;
          text-wrap: nowrap;
        }

        .infoValue {
          flex-grow: 1;
          text-align: end;
          text-wrap: nowrap;
        }
      }
    }

    .error {
      color: red;
    }

    .colorBar {
      position: absolute;
      left: -1px;
      top: -1px;
      bottom: -1px;
      width: 4px;
      border-radius: 2px;
    }
  }

  &.greyedOut {
    .header,
    .subheader,
    .info,
    .bottomKey,
    .bottomValue {
      color: $color-text-light !important;
    }

    .colorBar {
      background-color: $color-text-light !important;
    }
  }

  &.alerting {
    border-color: $color-red;

    .header {
      color: $color-red !important;
    }

    .colorBar {
      background-color: $color-red !important;
    }
  }
}
