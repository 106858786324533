@import '../../../../scss/base/var';

.noPermissions {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.fullViewHeight {
    height: 100vh;
    padding-top: 48px; // to compensate for the header
  }

  .headline {
    font-size: 80px;
    font-weight: 300;
    color: $color-text-grey;
    margin-bottom: 5px;
  }

  .title {
    font-size: 18px;
    color: $color-text-grey;
    margin-bottom: 10px;
  }

  .description {
    font-size: 14px;
    color: $color-text-grey;
    margin-bottom: 80px;
  }
}
