$rowHeight: 22px;
$color-text-light: #a6aeb5;
$color-grey2: #cacdd2;
$color-text: #3a3b3d;
$color-link: $color-primary;

.rejected-features {
  width: 100%;
  text-align: left;
}

.rejected-features-content {
  padding: 16px 20px 0;
  min-height: 350px;
}

.rejected-features-row-headline {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 2;
}

.rejected-features-row {
  display: flex;

  & + & {
    margin-top: 10px;
  }

  &.toggle-all-bar {
    .discarded-or-used-icon {
      color: $color-text-light;
    }
    .feature {
      color: $color-text-light;
      cursor: default !important;
    }
  }

  &.no-features-for-query {
    margin-top: 20px;
    color: $color-text-light;
    i {
      font-style: italic; // Why does this have to be done explicitly here?
    }
  }
}

.rejected-features-cell,
.rejected-features-head-cell {
  display: inline-block;
  height: $rowHeight;
  vertical-align: top;
  horiz-align: center;
  line-height: $rowHeight;

  & + & {
    margin-left: 10px;
  }

  &.importance {
    width: 200px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  &.rejected {
    width: 160px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.discarded-or-used-icon {
    width: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &.icon-used {

    }

    &.icon-discarded {
      color: $color-red-dark;
    }

    &.icon-rejected {
      color: $color-orange-dark;
    }
  }

  &.feature-parent {
    width: 0;
    flex-grow: 1;
    cursor: default;

    .feature {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;

      &.discarded {
        // text-decoration: line-through;
        font-style: italic;
        color: $color-text;
      }
    }
  }

  &.bar-parent {
    width: 200px;
  }
}

.rejected-features-head-cell {
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
