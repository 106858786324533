@import '../../../../scss/base/var';

.FlapButton {
  box-sizing: content-box;
  position: absolute;
  // border: 1px solid $color-grey;
  background-color: $color-grey;
  color: $color-text;
  border-radius: 25px;
  height: 30px;
  width: 70px;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;

  &:hover {
    background: $color-primary;
    color: $color-white;
  }
}
