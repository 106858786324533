@import "../../../../../scss/base/var.module.scss";

$col-border-grey-lighter: #dce2eb;
$tooltip-background-color: $color-white;
$tooltip-border-color: $color-grey;

.header {
  display: grid;
  grid-template-columns: 0.5fr 1fr 2fr 1fr 0.5fr;
  gap: 10px;
  width: 100%;

  position: sticky;
  top: 0;
  padding: 10px 0 10px 0;
  background-color: white;
  z-index: 100; // To overlap the react-flow graph
}

// Styling for the job groups
.row {
  display: grid;
  width: 100%;
  gap: 10px;
  padding: 10px 0;
  border: solid $col-border-grey-lighter;
  border-width: 0 0 1px 0;
  grid-template-columns: 0.5fr 1fr 2fr 1fr 0.5fr;


  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: inherit;
    overflow: auto;
  }

  .Published {
    display: flex;
    align-items: center;
    grid-row: 1;
    grid-column: 4;
  }
}

.version {
}
