@import '../base/var.scss';

.code-capsule-details {
  height: 100%;
  display: flex;
  flex-direction: column;

  .SideMenu {
    background-color: $color-white;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    overflow-y: auto;
    border-right: 1px solid $color-light-grey;
  }

  .WithSidemenu {
    height: 1px;
    flex-grow: 1;
  }

  .WithSidemenu--content {
    display: flex;
    flex-direction: column;
  }
}

.code-capsule-container {
  background-color: white;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 1px;
  flex-grow: 1;
  overflow-y: auto;

  &.executions-container {
    .executions-empty {
      position: relative;
      margin-top: 53px; // to make it match to the buttons headline of the Queues
      width: calc(100% - 30px); // -2x15px horizontal padding
      height: calc(100% - 40px - 43px); // -2x20px vertical padding - 43px to make it match to the buttons headline of the Queues
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-top: 180px; // TODO This is the try to center the elements vertically ... Fix it to a proper center.

      .executions-empty-elements {
        display: flex;
        flex-direction: column;
        text-align: center;

        .executions-empty-pic {
          width: 107px;
          height: 107px;
          margin: 0 auto 25px auto;
          background: url("../../img/codeCapsuleExecutionsEmpty.png") no-repeat;
          background-size: cover;
        }

        .headline {
          font-size: 18px;
          font-weight: 400;
          padding: 0 0 15px 0;
        }

        .description {
          font-size: 14px;
          color: $color-text-light;
        }
      }
    }

    .executions-notebooks {
      display: flex;
      flex-direction: column;
    }

    .executions-link {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
