@import '../base/var';

.upload-container {
  width: 800px;
  height: 360px;
  position: relative;

  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;

  .title {
    color: $color-text;
    font-weight: 400;
    font-size: 15px;
  }

  .upload-target-container {
    display: flex;
    align-items: center;

    span {
      margin-right: 10px;
    }

    margin-bottom: 20px;
  }

  .upload-content {
    display: flex;
    flex-direction: row;
    padding-top: 16px;
    box-sizing: border-box;
    width: 100%;
    flex: 1;

    .tool-description {
      align-items: center;
      justify-content: center;
      margin-left: 32px;
      height: 200px;
      display: flex;
      text-align: center;
    }

    .files {
      margin-left: 32px;
      align-items: flex-start;
      justify-items: flex-start;
      flex: 1;
      overflow-y: auto;
      height: 200px;

      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }

      .row {
        display: flex;
        flex: 1;
        flex-direction: column;
        flex-flow: column;
        justify-content: space-between;
        padding: 8px;
        overflow: hidden;
        box-sizing: border-box;

        .upload-text {
          cursor: default;
          display: flex;
          width: 100%;
          overflow: hidden;
          height: 16px;

          .filename {
            color: $color-text;
            font-size: 14px;
            width: 0;
            flex-grow: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .filesize {
            font-size: 14px;
            margin-left: 5px;
            color: $color-text-light;
          }
        }

        .progress-wrapper {
          display: flex;
          flex: 1;
          flex-direction: row;
          align-items: center;
          margin-top: 2px;

          .progress-info {
            width: 60px;
            height: 20px;

            .progress-percentage {
              color: $color-text;
              text-align: right;
              display: block;
            }

          }

          .progress-bar {
            .progress {
              transition: width 0.4s ease-in-out;
            }
          }
        }

        .check-icon {
          opacity: 1;
          margin: 0 20px;
          stroke: $color-green;
        }

        .error-icon {
          opacity: 1;
          margin: 0 20px;
          stroke: $color-red;
        }
      }
    }

    .busy {
      background-color: transparent !important;
    }
  }

  .actions {
    display: flex;
    width: 100%;
    align-items: flex-end;
    flex-direction: row;
    margin-top: 20px;
    justify-content: flex-end;

    > * {
      margin-left: 10px;

      &:focus {
        outline: none !important;
      }
    }
  }
}

.progress-bar {
  width: 100%;
  height: 8px;
  background-color: $color-light-grey;
  border-radius: 5px;

  .progress {
    background-color: $color-primary-highlight;
    height: 100%;
    margin: 0;
    border-radius: 5px;
  }
}

.upload-modal-parent {
  .close-button {
    z-index: 1; // Move above the bigger div to make the button work
  }
}
