@import "../../../../../../../../scss/base/var";

.pipelineTuningResultsBestModel {
  width: 100%;
  height: 100%;
  overflow: auto;

  .infos {
    margin-bottom: 10px;
    font-size: 15px;
    color: $color-text;

    .infoLine {
      margin-bottom: 5px;

      .infosKey {
        font-weight: 400;
        margin-right: 5px;
      }
    }
  }

  .modelContainer {
    border: 1px solid $color-grey;
    border-radius: 4px;

    .bestModelChart {
      border-bottom: 1px solid $color-grey;
    }
  }
}
