@import '../../../../scss/base/var.scss';

.k8sNodeInfo {
  width: 100%;
  max-width: 800px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  .title {
    font-weight: 400;
    margin-bottom: 5px;
    font-size: 15px;
    display: block;
  }

  .infoBox {
    margin-bottom: 10px;
  }

  .table {
    text-align: left;
    width: 100%;
    cursor: default;
  }

  .rowHeader {
    font-weight: 400;
    background-color: $color-light-grey;
  }

  .row {
    &:not(.rowHeader){
      &:hover {
        background-color: $color-very-light-grey;
      }
    }
  }

  .col {
    padding: 10px;
  }

  .colName {

  }

  .colCpu {

  }

  .colMemory {

  }

  .colGpu {

  }

  .colGpuProduct {

  }
}
