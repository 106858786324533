.Headline {
  flex-grow: 1; // To override the margin-left: auto from the search box elements to the right

  display: flex;
  justify-content: space-between; // To move the create button to the left and the Filters to the right
  align-items: center;

  .Filters {
    display: flex;
    align-items: center;
    > div {
      margin-right: 10px;
    }

    .Dropdown {
      min-width: 20ch;
    }
  }
}

.elementCardGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
}
