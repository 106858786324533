@import '../../../../../../scss/base/var';

.backupJobModal {
  width: 600px;

  .backupJobHeadline {
    font-size: 15px;
    font-weight: 500;
  }

  .backupJobDescription {
    margin-top: 20px;
    white-space: pre-wrap;
  }

  .backupJobField {
    margin-top: 10px;
  }

  .backupJobButtonBar {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}