@import '../../../../scss/base/var';

.infoBox {
  border: 1px solid $color-grey;
  padding: 10px 15px;
  border-radius: 4px;
  background-color: $color-very-light-grey;
  cursor: default;
  display: flex;

  .iconContainer {
    color: $color-primary;
    margin-right: 15px;
  }

  .infoContainer {
    white-space: break-spaces;
    p {
      padding: 0;
    }
  }
}
