@import "../../../../../../../scss/base/var";
$border-width: 1.25px;

.pageSelection {
  position: relative;
  padding: 10px 20px;
  height: 100%;
}
.selectionWrap {
  display: flex;
  height: 100%;
}
.selectionInfo {
  width: 30%;
  font-size: 12px;
  overflow-y: auto;
}
.selectionGraph {
  width: 70%;
  padding: 0 0 0 50px;
  text-align: center;
  display: flex;
  flex-direction: column;

  .selectionGraphHistory {
    text-align: right;
    color: $color-text-light;
  }
}
.selectionInfoContainer {
  border: $border-width solid $color-grey;
  border-radius: 4px;
}
.selectionInfoTitle {
  padding: 10px 0 10px 0;
  text-align: center;
  font-weight: bold;
}
.selectionInfoItem {
  display: flex;
  flex-direction: row;
  padding: 6px 10px 5px 10px;
  position: relative;
  border-top: $border-width solid $color-grey;
  margin: -1px 0 0 0;
}
.selectionInfoLabel {
  font-weight: 400;
  overflow: hidden;
  padding: 5px 0 5px 0;
  margin: auto 0 auto 5px;
}
.selectionInfoList {
  color: $color-text-light;
  font-size: 10px;
  margin-top: 1px;
}
.selectionInfoIcon {
  margin: auto 0 auto 0;
  width: 20px;
  vertical-align: middle;
}