@import "../../../../../scss/base/var";
@import "../../../../../scss/vendor/foundation/foundation.scss";

.ProfileMenuButton {
  padding: 5px 10px 5px 20px;
  height: 48px;
  // border-right: 1px solid rgba($color-white, .3);
  display: flex;
  text-decoration: none;
  align-items: center;
  cursor: pointer;

  @media (max-width: 780px) {
    border-right: none;
  }

  &:hover, &:active, &:focus {
    .ProfileMenuButton--name {
      text-decoration: underline;
      color: $color-white;
    }
  }

  .ProfileMenuButton--image {
    margin-right: 7px;
  }

  .ProfileMenuButton--name {
    color: $color-white;
    display: inline-block;
    vertical-align: top;
    margin-right: 7px;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ProfileMenuButton--icon {
    margin-top: 2px;
    color: $color-white;
  }

  // Care about responsiveness
  .ProfileMenuButton--hamburger {
    display: none;
  }

  @media (max-width: 800px) {
    padding-right: 7px;
    .ProfileMenuButton--image {
      display: none;
    }
    .ProfileMenuButton--name {
      display: none;
    }
    .ProfileMenuButton--icon {
      display: none;
    }
    .ProfileMenuButton--hamburger {
      display: inline-block;
      color: $color-white;
      margin: 0 10px 0 0;
    }
  }
}
