@import '../../../../scss/base/var';

.Container {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  padding: 20px 15px;
  background-color: $color-white;
  height: 100%;
  border-radius: 4px;

  .Header {
    font-weight: 500;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 20px;
    align-self: center;
  }

  .HeaderLeft {
    font-weight: 500;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .FullLine {
    margin: 1.25rem -15px 1.25rem;
    border: 0;
    border-top: 2px solid $color-light-grey;
  }

  .ButtonsContainer {
    display: flex;
    gap: 8px;
    padding: 4px;
    justify-content: flex-end;
    bottom: 30px;
    right: 20px;
    position: absolute;
  }
}
