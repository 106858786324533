@import '../../../scss/base/var';

.modalParent {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.modalHeader {
  .modalGroupParent {
    display: flex;
    flex-direction: column;

    &.versionsParent {
      margin-top: 20px;
    }
  }
}

.sep {
  margin: 10px 0;
  max-width: unset;
}

.innerContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 60px;

  .modalColumn {
    // overflow-y: auto;

    .modalGroupParent {
      display: flex;
      flex-direction: column;

      &.parametersParent {
        margin-top: 20px;
      }

      &.resourcesParent {
        margin-top: 20px;
      }

      &.notebooksParent {
        margin-top: 20px;
      }

      &.priorityParent {
        margin-top: 20px;
        width: calc(50% - 5px); // To match the columns of the resources above
      }
    }
  }
}

.modalHeadline {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;
}

.modalSubHeadline {
  margin-bottom: 5px;
}

.modalDescription {
  display: block;
  margin-bottom: 5px;
}

.resourcesInputParent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 10px 0;
  row-gap: 20px;
  column-gap: 10px;
}

.resourcesColumn {

  ::placeholder {
    color: grey;
  }
}

.fieldParent {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;

  &.parametersParent {
    flex-direction: column;
  }
}

.notebookInput {
  width: 100%;
}

.filterParent {
  padding: 0 10px;
  margin-bottom: 15px;
}

.versionDropdownSelect {
  min-width: 160px;
}

.dndParent {
  width: 100%;
}

.dndInner {
  display: flex;
}

.notebookListContainer {
  padding: 0 10px;
  width: 50%;
}

.listName {
  font-weight: 400;
  margin-bottom: 5px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.droppable {
  border: 1px solid $color-grey;
  border-radius: 4px;
  padding: 5px;
  min-height: 200px;
  height: 100%;
  overflow: auto;
}

.draggable {
  padding: 4px 5px;
  background-color: $color-very-light-grey;
  border-radius: 4px;
  // See https://stackoverflow.com/a/67075990/6235550
  top: auto !important;
  left: auto !important;
  transition: background-color ease-in-out 0.1s;
  margin-bottom: 3px;

  &:hover {
    background-color: $color-light-grey;
  }
}

.parameterRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .parameterKey {
    border-radius: 4px;
    border: 1px solid $color-grey;
    background-color: $color-light-grey;
    flex-grow: 1;
    margin-right: 10px;
    width: 50%;
    height: 46px;
    padding: 10px;
    display: flex;
    align-items: center;
  }

  .parameterValue {
    width: 50%;
    flex-grow: 1;
  }
}

.jobNameParent {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}

// --- Responsiveness
@media (max-width: 1024px) {
  .innerContainer {
    grid-template-columns: 1fr; // Switch to single column "grid"
  }
}
