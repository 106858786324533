@import '../../../../scss/base/var.scss';

.repositoryContent {
  border: 1px solid $color-grey;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;

  i {
    font-style: italic; // Stupid, but otherwise it's overwritten ...

    > * {
      margin-right: 4px;
      display: inline-block;
    }
  }

  .emptyRepoText {
    vertical-align: middle;
  }
}

.colIcon {
  width: 32px;

  svg {
    margin-bottom: -3px; // To vertical align the icon with the text
  }
}

.rowDirectoryUp {
  cursor: pointer;
}
