@import '../../../../scss/base/var';

.sidemenu {
  position: relative;
  // background-color: #eef0f2; // transparent to see through the grey of the body
  min-width: 228px;
  width: 228px;
  font-weight: 400;
  font-size: 12px;
  overflow-y: auto;
  height: 100%;
  background-color: $color-white;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.headline {
  padding: 6px 10px 6px 50px;
  // background-color: #edf2f7;
  color: $color-text-light;
  text-transform: uppercase;
  font-weight: 400;
  cursor: default;
  position: sticky;
  top: -1px; // Required to trigger the IntersectionObserver in Category.tsx
  display: block;
  z-index: 1;
  background-color: $color-white;
}

.entriesContainer {
  .droppable {
    &.isDraggingOver {
      background-color: transparentize($color-green, 0.8);

      .addEntry {
        // Hide the add entry while dragging
        opacity: 0;
      }
    }
  }
}

.category {
  display: flex;
  flex-direction: column;
  padding: 0 0 2px 0;

  &:last-child {
    // If this is the last category in the sidebar: Hide the bottom HR
    .categoryHr {
      display: none;
    }
  }

  .categoryHr {
    margin: 5px 0;
    border-bottom: 1px solid $color-grey;
  }
}

.entry {
  height: 32px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 8px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 4px;
  border-radius: 8px;
  cursor: pointer;

  .iconsParentLeft {
    position: absolute;
    left: 3px;
    display: none;
  }

  .iconsParentRight {
    display: none;
  }

  .icon {
    display: flex;
  }

  .dragIcon {
    color: $color-dark-grey;
    cursor: grab;

    &:hover {
      color: $color-text-grey;
    }


  }

  .editIconButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 24px;
    height: 24px;

    .editIcon {
      color: $color-primary;
    }
  }

  &.disabled {
    cursor: default;
  }

  &:hover {
    .iconsParentLeft {
      display: flex; // Display the draggable icon if the entry is hovered. "flex" ensures the icons are not split up into several lines
    }

    .iconsParentRight {
      display: flex; // Display the edit icons if the entry is hovered
    }
  }

  &:hover {
    background: var(--color-primary-lighter90, #e4ecf9);
  }

  &.isActive {
    // background-color: $color-primary;
    background: var(--color-primary-lighter90, #e4ecf9);

    .tabLink {
      .tabLinkContent {
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 0;
        font-size: 12px;
      }

      > div {
        color: var(--color-primary, #224e90);
        // font-weight: bold;
      }
    }

    .editIcon {
      // color: $color-white;
    }

    &:hover {
      .dragIcon {
        color: $color-dark-grey;
        cursor: grab;
      }

      .tabLink {
        > div {
          color: var(--color-primary, #224e90);
        }

        .icon {
          > a {
            color: var(--color-primary, #224e90);
          }

          span { // for icons that are based on a font
            color: var(--color-primary, #224e90);
          }

        }
      }
    }
  }

  &.isDragging {

  }

  &.static {

  }

  &.editable {

  }

  .tabLink {
    transition: color ease-in-out 100ms;
    overflow: hidden;
    width: 100%;

    .labelContainer {
      display: flex;
      flex-grow: 1;
      overflow: hidden;
      gap: 6px;

      .label {
        display: flex;
        align-items: center;
        text-align: left;
        text-overflow: ellipsis;
        /* Needed to make it work */
        overflow: hidden;
        text-wrap-mode: nowrap;
        white-space-collapse: preserve;
      }

      .errorIndicator {
        display: flex;
      }
    }

    .input {
      flex-grow: 1;

      input {
        padding: 5px;
        margin-left: -6px; // 5px padding + 1px border - to ensure the text isn't moving when switching from static text to the text input
        height: 24px;
        font-size: 12px;
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.4;
    }

    &:focus {
      outline: none;
    }

    &.border-top {
      border-top: 1px solid $color-grey;
    }

    &:first-child {
      border-top-left-radius: 4px;
    }

    .icon {
      margin: 10px;
      transition: color ease-in-out 100ms;

      span {
        font-size: 16px; // If the icon is based on a font
        transition: color ease-in-out 100ms;
      }
    }

    > div, .disabledLink {
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 0 0;
      font-size: 12px;
    }

    &:hover {

      .tabLinkContent {
        color: $color-primary;
      }
    }
  }

  .loadingBar {
    height: 16px;
    margin: 4px 15px;
    background: linear-gradient(-45deg, $color-grey, $color-grey, lighten($color-grey, 5%), $color-grey,);
    background-size: 400% 400%;
    animation: gradient 4s ease-in-out infinite;
    color: transparent;
    width: 100%;
  }
}

.addEntry {
  width: calc(100% - 40px); // -24px to compensate for the padding
  position: relative;
  height: 14px;
  margin: -8.5px 20px -5.5px 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;
  opacity: 0;
  z-index: 30;

  &:hover {
    opacity: 1;
  }

  .addEntryHr {
    width: 100%;
    border-bottom: 1px solid $color-primary-highlight-darker30;
    position: absolute;
    border-radius: 1px;
  }

  .addEntryIcon {
    color: $color-primary-highlight-darker30;
    position: absolute;
    top: -1px;
    background-color: $color-light-grey;
    border-radius: 50%;
    width: 16px;
    height: 16px;
  }
}
