/* Default AltaSigma theming */
$default-color-primary: #224e90;
$default-color-primary-highlight: #6AA0EB;
$default-color-secondary: #9fd356;

$color-primary: var(--color-primary, $default-color-primary);
$color-primary-lighter30: var(--color-primary-lighter30, scale-color($default-color-primary, $lightness: 30%));
$color-primary-lighter60: var(--color-primary-lighter60, scale-color($default-color-primary, $lightness: 60%));
$color-primary-lighter90: var(--color-primary-lighter90, scale-color($default-color-primary, $lightness: 90%));
$color-primary-lighter99: var(--color-primary-lighter99, scale-color($default-color-primary, $lightness: 99%));
$color-primary-darker30: var(--color-primary-darker30, scale-color($default-color-primary, $lightness: -30%));
$color-primary-darker60: var(--color-primary-darker60, scale-color($default-color-primary, $lightness: -60%));
$color-primary-darker90: var(--color-primary-darker90, scale-color($default-color-primary, $lightness: -90%));
$color-primary-transparent: var(--color-primary-transparent, change-color($default-color-primary, $alpha: 0.66));

$color-primary-highlight: var(--color-primary-highlight, $default-color-primary-highlight);
$color-primary-highlight-lighter30: var(--color-primary-highlight-lighter30, scale-color($default-color-primary-highlight, $lightness: 30%));
$color-primary-highlight-lighter60: var(--color-primary-highlight-lighter60, scale-color($default-color-primary-highlight, $lightness: 60%));
$color-primary-highlight-lighter90: var(--color-primary-highlight-lighter90, scale-color($default-color-primary-highlight, $lightness: 90%));
$color-primary-highlight-lighter99: var(--color-primary-highlight-lighter99, scale-color($default-color-primary-highlight, $lightness: 99%));
$color-primary-highlight-darker30: var(--color-primary-highlight-darker30, scale-color($default-color-primary-highlight, $lightness: -30%));
$color-primary-highlight-darker60: var(--color-primary-highlight-darker60, scale-color($default-color-primary-highlight, $lightness: -60%));
$color-primary-highlight-darker90: var(--color-primary-highlight-darker90, scale-color($default-color-primary-highlight, $lightness: -90%));
$color-primary-highlight-transparent: var(--color-primary-highlight-transparent, change-color($default-color-primary-highlight, $alpha: 0.66));

$color-secondary: var(--color-secondary, $default-color-secondary);
$color-secondary-lighter30: var(--color-secondary-lighter30, scale-color($default-color-secondary, $lightness: 30%));
$color-secondary-lighter60: var(--color-secondary-lighter60, scale-color($default-color-secondary, $lightness: 60%));
$color-secondary-lighter90: var(--color-secondary-lighter90, scale-color($default-color-secondary, $lightness: 90%));
$color-secondary-lighter99: var(--color-secondary-lighter99, scale-color($default-color-secondary, $lightness: 99%));
$color-secondary-darker30: var(--color-secondary-darker30, scale-color($default-color-secondary, $lightness: -30%));
$color-secondary-darker60: var(--color-secondary-darker60, scale-color($default-color-secondary, $lightness: -60%));
$color-secondary-darker90: var(--color-secondary-darker90, scale-color($default-color-secondary, $lightness: -90%));
$color-secondary-transparent: var(--color-secondary-transparent, change-color($default-color-secondary, $alpha: 0.66));
