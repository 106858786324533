@import "../../../../../scss/base/var";
@import "../../../../../scss/vendor/foundation/foundation.scss";

.header {
  background: $color-primary;
  color: $color-white;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  height: 48px;

  .headerRow {
    position: relative;
    // width: calc(100% - 30px);
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    max-width: $max-page-width;

    .headerLeft {
      flex: 0;
      display: flex;
      align-items: center;
      padding-left: 15px;
      position: relative;
    }

    .headerCenter {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .headerRight {
      flex: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 15px;

      .helpIcon {
        margin-left: 20px;
        height: 30px;
        width: 30px;
        border-radius: 18px;
        border: 1px solid transparentize($color-white, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: background-color 50ms ease-in-out, border 50ms ease-in-out;
        font-weight: 400;
        font-size: 14px;
        text-decoration: none;

        span {
          color: white;
          transition: opacity 50ms ease-in-out;
          opacity: 0.8;
        }

        &:hover {
          border: 1px solid transparent; // If this isn't set, there will be a border since the semi-transparent border and background colors are added on top of each other
          background-color: transparentize($color-white, 0.8);

          span {
            opacity: 1;
          }
        }
      }
    }
  }

  .headerLabel {
    height: 30px;
    background-color: $color-white; // might be overriden by inline-styles
    color: $color-text; // might be overriden by inline-styles
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 400;
    cursor: default;
    white-space: nowrap;
    margin-left: 28px; // to make space for the expand menu icon
  }
}

.logoContainer {
  height: 48px;
  min-height: 48px;
  // border-right: 1px solid rgba(255, 255, 255, 0.3); // To math the other vertical lines in the header bar */
  padding: 0 9px 0 2px;
  background-color: $color-primary;
  display: flex;
  align-items: center;
  position: relative;
  flex: 0;
  min-width: 245px; // to match the left menu
  border-right: 1px solid transparentize($color-white, 0.9);

  .headerLogo {
    overflow: hidden;
    padding-top: 3px; // hack to center the logo
  }
}

.menuCollapseIcon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 10px;
  position: relative;
  transform: rotate(180deg);
  opacity: 0.8;

  svg {
    stroke: #ffffffcc;
  }

  &:hover {
    background-color: #ffffff44;
    opacity: 1;
  }
}

.menuCollapsed {
  .logoContainer {
    min-width: 50px !important; // to match the left menu
  }

  .menuCollapseIcon {
    // transform: rotate(180deg);
  }
}
