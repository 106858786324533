@import '../../../../scss/base/var';

.configPaneContentContainer {
  height: 100%;
  overflow: auto;
}

.modalTextArea {
  width: 50vw;
}

.showFileContainer {
  display: flex;
  align-items: center;
  margin: 4px 2px 12px 2px;

  &:hover {
    cursor: pointer;

    > span {
      text-decoration: underline;
    }
  }
}

.showFileIcon {
  margin-right: 7px;
}

.showFileText {
  font-size: small;
}