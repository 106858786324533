@use "../../../../../../../scss/base/theme.module";
@import '../../../../../../../scss/base/var';


.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tablePreview {
  padding-top: 10px;
  flex: 1;
  overflow: auto;
}