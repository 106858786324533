.model-realtime-container {
  padding: 20px;

  .activate-container {

    &:not(:last-child){
      margin-bottom: 30px;
    }

    .activate-top {
      width: 100%;

      .activate-title {
        padding: 0 0 11px 0;
        font-size: 15px;
        font-weight: 400;
      }
    }

    .activate-bottom {
      margin-top: 20px;
      border: 1px solid $color-grey;
      border-radius: 4px;
      padding: 20px;
      background-color: $color-light-grey;

      .starting-stopping-spinner {
        display: inline-block;
        position: absolute;
        margin-top: -22px; // This is to center it vertically.
        margin-left: 20px;
      }

      input {
        background-color: $color-white;
      }
    }

    .micro-postman-container{
      margin-top: 20px;

      .micro-postman-request {
        background-color: $color-light-grey;
        width: 100%;
        padding: 10px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border: 1px solid $color-grey;
        border-bottom: none;

        .micro-postman-headline{
          display: flex;

          .http-method {
            padding: 10px;
            border: 1px solid $color-grey;
            background-color: $color-white;
            border-right: none;
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
            font-family: $code-font-family;
            font-weight: 500;
          }
          .http-url {
            padding: 10px;
            border: 1px solid $color-grey;
            background-color: $color-white;
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
            width: 100%;
            font-family: $code-font-family;
            margin-right: 10px;
          }
        }

        .micro-postman-body{
          background-color: white;
          font-family: $code-font-family;
          margin-top: 10px;
          border: 1px solid $color-grey;
          border-radius: 4px;
          padding: 10px;
          resize: none;
          height: 300px;
        }
      }
      .micro-postman-response {
        width: 100%;
        padding: 10px;
        background-color: $color-light-grey;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border: 1px solid $color-grey;

        .response-row{
          display: flex;
          align-items: center;
          height: 30px;

          &:not(:first-child){
            margin-top: 5px;
          }

          &.response-row-full-json {
            flex-direction: column;
            align-items: flex-start;
            height: unset;

            .response-value {
              width: 100%;
              border: 1px solid $color-grey;
              background-color: $color-white;
              border-radius: 4px;
              padding: 5px;
              font-family: $font-family-monospace;
              white-space: break-spaces;
              margin-top: 5px;
            }
          }

          .response-title{
            width: 120px;
            font-weight: 400;
          }

          .response-value{
            display: flex;
            align-items: center;
            .score-bar{
              position: relative;
              .score-bar_bar {
                fill: $color-primary-highlight;
              }
              .score-bar_background-bar {
                //fill: $color-light-grey;
                fill: $color-white;
              }
            }
            .score-plain {
              margin-left: 10px;
            }
          }
        }

        .response-error {
          color: $color-red;
        }
      }
    }
  }

}
