@import '../../../../../scss/base/var';


.ModelSettingsCategory {
  overflow-y: auto;
  padding: 16px 20px 0;
  height: 100%;

}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 4fr;
  grid-template-rows: auto 1fr 1fr 1fr;
  grid-auto-flow: row;
  column-gap: 20px;
  row-gap: 8px;
}

.columnTitle {
  min-width: 40px;
}

.rowTitle {

  align-content: center;
}

.field {
  align-content: end;

}

.gridGPU {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;

  .disabled {
    pointer-events: none;
    filter: opacity(.35);
  }

  .col {
    align-content: end;

    &.gpuAvailableCol {
      align-content: center;
    }

    .inputParent {
    }

    .checkboxDisabled {
      color: $color-dark-grey;
    }

    .noGpuAvailableInfo {
      display: block;
      margin-bottom: 10px;
      color: $color-text;
      font-style: italic;
    }
  }
}
