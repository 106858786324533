@import '../../../../../../../../../../../../scss/base/var';

.editableTableElement {
  max-width: 100%;
  overflow: auto;
  padding-right: 20px; // Scrollbar
  padding-bottom: 20px; // Scrollbar

  &.inNotebook {
    max-height: 300px;
  }
}

.resetButton {
  width: 30px;
  height: 30px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #071b32;
  margin-right: 5px;
  transition: background-color 0.1s ease-in-out;
  cursor: pointer;
  border: 1px solid $color-grey;
  background-color: white;
  margin-bottom: 5px;

  &:hover {
    background-color: $color-very-light-grey;
  }
}
