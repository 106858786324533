@import "../../../../scss/base/var";

.grid {
  display: grid;
  grid-template-columns: [model-timeline] 1rem [content] auto [jobs-timeline] 16px [timestamp] 112px [end];
  grid-auto-rows: 52px;
  row-gap: 4px;
  column-gap: 8px;
  align-items: center;
  justify-items: stretch;
  padding: 8px;
  overflow-y: auto;
}

.timeTravelHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  position: sticky;
  top: 0;
  background: white;
  border-top-right-radius: 4px;
}

.title {
  font-size: 15px;
  font-weight: 400;
}

.compareModeSwitch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: white;
}

.compareModeText {
  margin-left: auto;
  margin-right: 10px;
}

.rowContainer {
  display: grid;
  grid-column: content / end;
  grid-template-columns: subgrid;
  padding: 6px 0;
  width: 100%;
  align-items: center;
  justify-items: stretch;
  border-radius: 14px;
  // border: 1px solid transparent; // to prevent jumping when hovering
  transition: .05s ease-in-out;
  background-color: white;
  cursor: default;

  &.selectable {
    cursor: pointer;
  }

  &:not(.selectable) > .content {
    color: #bbbbbb;
  }

  &.selected {
    border: 1px solid $color-primary;
  }

  &.selectable:hover {
    .content {
      //font-weight: 400;
      text-shadow: .5px 0 $color-primary;
      color: $color-primary;
    }
  }

  &.highlighted {
    background-color: $color-primary-lighter90;
  }
}

.content {
  grid-column: content;
  padding-left: 12px;
  font-weight: 300;
}

.jobsIcon {
  grid-column: jobs-timeline;
  height: 100%;
  width: 100%;
  stroke: $color-primary-lighter60;
}

.timestamp {
  grid-column: timestamp;
  padding-right: 8px;
  color: $color-dark-grey;
}

.jobConnection {
  grid-column: jobs-timeline;
  width: 1px;
  background: $color-primary-lighter90;
  margin: 44px 0;
  align-self: stretch;
  justify-self: center;
  border-radius: 1px;

  &.highlighted {
    // background: $color-primary;
  }
}

.modelBar {
  writing-mode: vertical-lr;
  width: 16px;
  border-radius: 8px;
  background: lightgray;
  grid-column: model-timeline;
  font-size: 11px;
  font-weight: normal;
  height: 100%;
  padding: 6px 0;
  line-height: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;

  &.highlighted {
    color: white;
    background: $color-primary;
  }
}

.timeTravelContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}
