.col--1 {
  @include grid-col(12);
}

.col--2--wrap {
  display: flex;
}
.col--2--left {
  flex: 0 1 auto;
  width: 228px;
  min-width: 228px;
  min-height: calc(100vh - 41px - 60px - 52px);
  border-right: 1px solid #ddd;
  background: #edf2f7;
  border-radius: 4px 0 0 4px;
  @include breakpoint(small down) {
    width: 52px;
    min-width: 52px;
  }
}
.col--2--center {
  flex: 1 1 auto;
}
.h100p {
  height: 100%;
}

.page--title {
  font-size: 15px;
  font-weight: 400;
  padding: 26px 20px;
  span {
    color: $color-text-light;
    margin: 0 0 0 10px;
  }
  .page--title--link {
    float: right;
    line-height: 18px;
    font-size: 14px;
    text-decoration: none;
    vertical-align: top;
    color: $color-primary;
    &:hover, &:active, &:focus {
      color: $color-primary;
    }
    [class^="icon-"], [class*=" icon-"] {
      font-size: 24px;
      line-height: 18px;
      vertical-align: top;
      margin: 0 6px 0 0;
    }
  }
}

.page--title--tabs {
  padding: 0;
  @include grid-row(12);
  margin: 0 0 40px 0;
}
.page--title--tab {
  padding: 0 !important;
  @include grid-col(6);
  @include breakpoint(medium down) {
    @include grid-col(12);
    margin: 15px 0 0 0;
  }
  &:first-child {
    .page--title--tab--link {
      border-left: none;
    }
  }
  &.active {
    .page--title--tab--link {
      cursor: default;
      background: $color-white;
      color: $color-primary;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        background: $color-primary;
        height: 4px;
        width: 100%;
      }
    }
    .page--title--tab--text {
      display: block;
    }
  }
}
.page--title--tab--link {
  color: $color-text-light;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.0;
  position: relative;
  text-decoration: none;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  @include linearGradient(rgba(236,236,236,.5), $color-white);
  padding: 9px 31px 11px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @include breakpoint(medium down) {
    background: none;
  }
  &:hover {
    color: $color-primary;
  }
}
.page--title--tab--text {
  padding: 9px 31px 11px;
  display: none;
  line-height: 1.3;
  @include breakpoint(medium down) {
    display: block;
  }
}
.white-block {
  background: $color-white;
  border-radius: 4px;
  border: 1px solid $color-grey;
  margin: 0 0 21px 0;
}
.grey-block {
  background: $color-light-grey;
  border-radius: 4px;
  border: 1px solid $color-grey;
  margin: 0 0 21px 0;
}


.page--bar {
  padding: 26px 20px;
  &:before, &:after {
    content: "";
    display: table;
    clear: both;
  }
  .page--title {
    display: inline-block;
    vertical-align: top;
    padding: 0 38px 0 0;
  }
}
.page--views {
  display: inline-block;
  vertical-align: top;
  margin: -7px 0 0 0;
}
.page--view {
  display: inline-block;
  vertical-align: baseline;
  .button {
    margin: 0 10px 0 0;
  }
  &.active {
    .button-white {
      color: $color-white;
      background: $color-primary;
    }
  }
}
.page--back {
  display: inline-block;
  vertical-align: top;
  color: $color-primary;
  text-decoration: none;
  margin: 0 15px 0 0;
  font-size: 15px;
  [class^="icon-"], [class*=" icon-"] {
    display: inline-block;
    vertical-align: top;
    margin: 2px 4px 0 0
  }
}
.page--path {
  font-size: 15px;
  display: inline-block;
  vertical-align: top;
  font-weight: 400;
  li {
    display: inline-block;
    vertical-align: top;
    &:after {
      content: "/";
      margin: 0 6px 0 4px;
      color: $color-text-light;
    }
    &:last-child {
      &:after {
        content: "";
      }
    }
  }
  a {
    color: $color-text-light;
    text-decoration: none;
    &:hover {
      color: $color-text;
    }
  }
}
