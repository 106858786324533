@import '../../../../scss/base/var';

.statusIcon {
  width: 16px;
  height: 16px;
}

.container {
  display: flex;
  gap: 5px;
}

.topLine {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}

.tinyBusy {
  transform: scale(calc(16 / 80)) translateY(40px);
}

.title {
  font-weight: 400;
}

.success {
  color: $color-white;
  background-color: $color-green;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.unknown {
  color: $color-white;
  background-color: $color-orange;
  border-radius: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-weight: 400;
}

.error {
  color: $color-white;
  background-color: $color-red;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.errorText {
  color: $color-red;
  font-weight: 400;
  padding-bottom: 5px;
}

.light {
  color: $color-text-light;
}

.grey {
  color: $color-text-grey;
}

.body {
  margin-left: 21px;
  padding-bottom: 5px;

  .description {
    padding-bottom: 5px;
  }
}
