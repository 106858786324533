@import '../../../../../scss/base/var';

.detailsBar {
  height: 62px;
  padding: 11px 20px;
  margin-bottom: 5px;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-row-gap: 0;
  grid-column-gap: 0;
  align-items: center;
  cursor: default;

  background-color: $color-white;
  border-radius: 4px;

  overflow-x: auto;

  /* Hide the scrollbar, while still allowing to scroll */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}
