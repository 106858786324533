@import '../../../../scss/base/var';

$col-chart-link: #455A64;
$col-light-grey: #eef0f2;
$col-border-grey-lighter: #dce2eb;

.orchestrationCCOutput {
  padding: 30px 0 !important;

  .jobDetailsHeader {
    margin: 0 15px;
  }
}

.codeCapsuleOutputParent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow-y: clip;
  //padding-top: 30px;
  margin: 0;

  .switchDescription {
    padding-right: 5px;
    font-weight: 400;
  }

  .jobDetailsHeader {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    .backButtonContainer {
      flex-grow: 1;
    }

    .topButtonContainer {
      display: flex;
      flex-direction: row-reverse;

      .button {
        display: flex;
        align-items: center;
        margin-left: 10px;
      }
    }
  }

  .notebookTitleContainer {
    padding: 0 10px 15px 0;
    border-bottom: 1px solid $color-grey;
    display: flex;
    align-items: center;

    .iconParent {
      width: 55px;
      height: 10px;

      .icon {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        margin: 0 auto;
      }
    }

    .notebookTitle {
      font-weight: 400;
      font-size: 16px;
      margin-right: 10px;
    }
  }

  .modalNotebookContent {
    flex-grow: 1;

    .loading-container {
      position: absolute;
      height: calc(100% - 65px);
      width: 100%;
      overflow: hidden;
    }
  }

  .refreshIconContainer {
    margin-left: 10px;
    height: 40px;

    .refreshIconSpinningParent {
      width: 30px;
      height: 30px;
      margin: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      .refreshIconSpinning {
        color: $color-primary;
        margin: 5px;

        -webkit-animation: spin 2s linear infinite;
        -moz-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
      }
    }

    @-moz-keyframes spin {
      100% {
        -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
}

.success {
  background-color: $color-green;
}

.failure {
  background-color: $color-red;
}

.inProgress {
  background-color: $color-blue;
}

.waiting {
  background-color: $color-grey;
}
