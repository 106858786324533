@import '../../../../../scss/base/var';

.UserSidenotes {
  margin: 0 0 60px 0;

  .UserSidenotes--line {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between; // Distribute the sidenotes evenly
    flex-wrap: wrap;

    .UserSidenotes--column {
      .AccountUserInfo--value {
        font-size: 14px; // Make the sidenotes a bit smaller
      }
    }

    .UserSidenotes--column {
      flex-shrink: 0;
      display: block;
      margin-right: 20px;
      margin-bottom: 20px;

      .UserSidenotes--label {
        color: #a6aeb5;
        font-size: 14px;
        display: block;
        padding: 0 0 6px 0;
      }

      .UserSidenotes--value {
        color: $color-text;
        font-size: 14px;
        cursor: default;
      }

    }
  }
}
