@import '../../../../scss/base/var';

.BackTo {
  display: flex;
  align-items: center;
  cursor:pointer;
  color: $color-primary-lighter30;

  &:hover {
    color: $color-primary;
  }

  .BackTo--icon{
    margin-right: 10px;
  }
}
