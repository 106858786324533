@import "../../../../scss/base/var";

.SortableSearchableList--container {
  overflow-y: inherit;

  .SortableSearchableList--body{

    .SortableSearchableList--top{
      display: flex;

      .SortableSearchableList--info{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        cursor: default;
        margin-bottom: 10px;

        .SortableSearchableList--info-top-line {
          margin-bottom: 10px;
          display: flex;

          .SortableSearchableList--top-side-by-side-parent {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            .SortableSearchableList--name{
              font-size: 18px;
              font-weight: 400;
              margin-right: 20px;
            }
          }
        }
      }
    }

    .SortableSearchableList--headline {
      margin-right: auto;
      margin-bottom: 25px;
      display: flex;
      align-items: center;

      .SortableSearchableList--filter input {
        height: 36px;
        padding: 8px 13px;
        font-size: 14px;
      }

      .SortableSearchableList--sorting-selector {
        margin-left: auto;
      }
    }

    .Select-control { // Dropdown Menu for the ordering criteria
      width: 180px;
    }
  }
}
