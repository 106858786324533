@import '../../../../../scss/base/var';

.codeCapsuleReport {
  margin-top: 12px;
  margin-bottom: 12px;

  .headline {
    font-weight: 400;
    font-size: 15px;
    color: $color-text-light;
    display: block;
  }

  .description {
    margin-top: 10px;
    color: $color-text-light;
    display: block;
  }

  .singleNotebooksList {
    padding: 10px;
    border: 1px solid $color-grey;
    border-radius: 4px;
    margin-top: 10px;

    .singleNotebookEntry {
      cursor: pointer;
      display: flex;
      padding: 5px;
      text-decoration: none;
      align-items: center;

      &:hover {
        .singleNotebookEntryName {
          text-decoration: underline;
        }
      }

      .singleNotebookEntryIcon {
        margin-right: 10px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
      }
    }
  }
}
