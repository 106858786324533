@import "../../../../scss/base/var.module.scss";

.repositoryFilePreview {

  .content {
    padding: 15px;
  }
}

.repositoryFilePreview {
  grid-area: Content;
  margin-top: 10px;
  color: #333;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ddd;

  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    grid-gap: 10px;
    padding: 10px;
    background-color: $color-light-grey;

    .fileName {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .content {
    padding: 15px;
  }
}
