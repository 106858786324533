.callout {
  padding: 14px 16px;
  &.alert {
    color: $color-text;
    background: #ffd4b2;
    border: 1px solid #f2ad5f;
    border-radius: 4px;
  }
  a {
    color: $color-link;
    text-decoration: none;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
  [class^="icon-"], [class*=" icon-"] {
    font-size: 20px;
    color: #e7483a;
    vertical-align: middle;
    margin: 0 12px 0 0;
  }
}
.close-button {
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
  color: $color-text;
  margin-top: 5px;
}
