@import '../../../../scss/base/var';

.data-source-element {
  display: flex;

  .element {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-top: 1px solid $color-grey;
    padding: 10px;
    text-decoration: none;

    &:hover {
      background-color: $color-light-grey;
    }

    .element-icon-parent {
      margin-right: 20px;
      padding: 5px;

      svg {
        width: 30px;
        height: 30px;
      }
    }

    .element-ds-container {
      flex-grow: 1;

      .element-name {
        .name {
          font-weight: 400;
        }
      }

      .element-ds-type {
        color: $color-text-light;
        margin-top: 5px;

        .ds-type-details {
          display: flex;
          align-items: center;

          .ds-type {
            flex-grow: 1;
          }
        }
      }
    }

    .element-ds-role-container{
      margin-right: 10px;

      .element-ds-role span{
        height: 20px;
        min-width: 140px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-primary-highlight;
        color: $color-white;
      }
    }

    .element-ds-buttons {
      display: flex;

      .manage-ds-button {
        width: 30px;
        height: 30px;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-text;
        &:not(:last-child){
          margin-right: 5px;
        }
        &:hover{
          background-color: $color-grey;
          color: $color-white;
        }
      }
    }
  }
}
