@import '../../../../../../../../scss/base/var';

.pieChart {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // FIXME-CM: this is a quick & dirty fix with the expectation that it will be replaced by an echart anyway
  overflow: clip;

  .container {
    height: 100%;
    display: flex;
    position: relative;

    &.borders {
      border: 1px solid $color-grey;
      border-radius: 4px;
    }
    .chart {
      width: 60%;
    }
    .fullChart {
      width: 100%;
    }

    .legend {
      padding: 0 0 5px 25px;
      font-size: 11px;
      color: #394246;
      display: flex;
      flex-direction: column;
      justify-content: center;

      li {
        padding: 4px 0;
      }
    }
  }
}

.legendDot {
  width: 10px;
  height: 10px;
  vertical-align: top;
  border-radius: 50%;
  display: inline-block;
  margin: 2px 5px 0 0;
}

.tooltip {
  opacity: 0;
  position: absolute;
  background-color: #333;
  color: #ffffff;
  padding: 5px;
  border-radius: 3px;
  font-size: 14px;
  transition: all 0.05s ease;
  max-width: max-content;
}

@keyframes grow {
  from {
    transform: scale(0.9) rotateZ(3deg);
  }
  to {
    transform: scale(1) rotateZ(0deg);
  }
}

.arc {
  display: inline-block;
  position: relative;
  transition: 0.2s ease-in transform;
  z-index: 1;
  animation: grow 0.3s ease-in-out normal;
  &:hover {
    transform: scale(1.05);
  }
}

.restLegend {
  padding: 2px 4px;
  font-size: 11px;
  font-weight: 400;
  background-color: #ccc;
  border-radius: 6px;
  width: max-content;
  height: max-content;

  cursor: default;
}

.expandLegendText {
  padding: 2px 4px;
}

.restLegendContainer {
  color: #394246;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  background-color: #333;
  color: #ffffff;
  padding: 5px;
  border-radius: 3px;
  font-size: 14px;
  transition: all 0.05s ease;
  max-width: max-content;
  opacity: 0;
  visibility: hidden;
}
.smallLegend {
  position: absolute;
  bottom: 0;
  left: 0;
}

.donut {
  height: 100%;
  width: 100%;
}

.opacityOne {
  opacity: 1;
}
.visible {
  visibility: visible;
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
