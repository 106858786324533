@import '../../../../scss/base/var';

.SettingsBlock {

  .SettingsBlock--title {
    font-size: 15px;
    font-weight: 400;
    color: #a6aeb5;
    margin: 20px 0 10px 10px;
  }

  .SettingsBlock--block {
    border-radius: 4px;
    border: 1px solid #dce2eb;
    width: 100%;
    padding: 26px 20px 27px 20px;

    &.SettingsBlock--block-white {
      background: #fff;
    }

    &.SettingsBlock--block-grey {
      background: $color-light-grey;
    }
  }

  .SettingsBlock--element {
    display: flex;

    .SettingsBlock--left {
      width: 30%;

      .SettingsBlock--element-title {
        color: $color-primary;
        margin: 0 0 11px 0;
        font-weight: 400;
        font-size: 15px;
      }
      .SettingsBlock--element-description {
        margin: 5px 0 0 0;
        font-weight: 300;
        font-size: 12px;
      }
    }

    .SettingsBlock--right {
      margin-left: 10%;
      width: 60%;

      .buttons {
        margin-left: auto;
        width: fit-content;

        .Button {
          display: inline-block;
        }
      }
    }
  }

  .SettingsBlock--hr {
    margin: 20px auto;
  }

}
