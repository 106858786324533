@import '../../../../scss/base/var';

.browserCollapseFlap {
  width: 20px;
  height: 30px;
  background-color: $color-grey;
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 3;
  transition: background-color 0.1s ease-in-out, width 0.1s ease-in-out;

  &.collapsed {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    left: 0; // All to the left - but with respect to the border

    &:hover {
      .collapseIcon {
        margin-left: 10px;
      }
    }

    .collapseIcon {
      transform: rotate(180deg);
    }
  }

  &.expanded {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    // TODO in the sidebar 0 is correct, but in the workbench 1 is correct, something to do with position: absolute and box-sizing: border-box;
    right: 0;

    &:hover {
      .collapse-icon {
        margin-right: 10px;
      }
    }
  }

  .collapseIcon {
    color: $color-text-light;
    transition: color 0.1s ease-in-out, margin-left 0.1s ease-in-out, margin-right 0.1s ease-in-out;
    margin-right: 0;
    margin-left: 0;
  }

  &:hover {
    background-color: $color-primary;
    width: 30px;

    .collapseIcon {
      color: $color-grey;
    }
  }
}
