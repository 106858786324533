@use '../../../../../../../scss/base/theme.module';
@import '../../../../../../../scss/base/var.scss';

.modelArchive {
  padding: 20px 15px;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.headlineContainer {
  padding: 0 5px 10px 5px;
  flex-grow: 0;

  .headline {
    font-size: 15px;
    font-weight: 400;
    display: block;
  }
}

.body {
  overflow: auto;
  height: 1px;
  flex-grow: 1;

  .activateCol {

    .activeModel {
      height: 24px;
      border-radius: 12px;
      background-color: theme.$color-secondary;
      width: 120px;
      font-size: 14px;
      color: $color-white;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: default;
    }

    .activateButton {
      height: 24px;
      border-radius: 12px;
      width: 120px;
    }
  }

}
