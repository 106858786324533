@import '../../../../../../scss/base/var';

.sessions {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.emptyList {
  margin: 10px;
  display: flex;
  justify-content: center;
}

.titleContainer {
  display: flex;

  .title {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .shutdownAllButton {
    height: 24px;
    border-radius: 12px;
    cursor: pointer;
    margin-left: auto;
    background-color: $color-orange;
    color: white;
    transition: background-color 0.1s ease-in-out;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $color-orange-dark;
    }
  }
}

.gridContainer {
  padding: 10px 0px;
  display: grid;
  grid-template-columns: 3fr 2fr 62px;
  grid-row-gap: 2px;
  align-items: center;

  .headline {
    font-weight: 600;
    margin-bottom: 10px;
  }


  .name {
    grid-column: 1;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    flex-grow: 1;
    cursor: default;
  }

  .lastActivity {
    grid-column: 2;
  }

  .actions {
    grid-column: 3;

    .buttonsContainer {
      background-color: $color-white;
      height: 32px;
      border: 1px solid $color-grey;
      border-radius: 16px;
      padding: 0 10px;
      display: inline-flex;
      align-items: center;
    }
  }
}
