@import '../../../../scss/base/var';

.bubble {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: $color-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease-in-out;
  cursor: default;

  &.isValid {
    background-color: $color-green;
  }

  &.isErroneous {
    background-color: $color-red;
  }

  .number {
    color: $color-white;
    font-size: 16px;
    font-weight: 500;
  }

  .checkIcon {
    color: $color-white;
  }

  .validatingIcon {
    color: $color-white;

    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
