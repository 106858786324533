@import '../../../../../../../../scss/base/var';

.headline {
  font-weight: 400;
  color: $color-text;
  white-space: nowrap;
}

.optConstraintExhaustion {
  .tableContainer {
    cursor: default;
    margin-bottom: 20px;
    border: 1px solid $color-grey;
    border-radius: 4px;

    .columnIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-text-light;
    }

    .columnConstraintLevel {
      .constraintReferenceName {
        display: block;
        margin-bottom: 5px;
      }

      .speakingLevel {
        color: $color-text-light;
      }
    }

    th {
      border-top: none;
    }

    .row {
      &:hover {
        background-color: $color-light-grey;
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
}

.exhaustion {
  .speaking {
    span {
      &:not(:last-child) {
        margin-right: 3px;
      }
    }

    margin-bottom: 5px;
  }

  .exhaustionBarContainer {
    .percentage {
      margin-left: 6px;
      font-weight: 400;
      display: inline-block;
      min-width: 7ch; // minimum width as 100.00% to not jump the layout around in the usual cases (no hope for very large percentages)
    }
  }
}

.row:hover {
  .border {
    stroke: $color-text-light;
  }
}

svg:not(:root).exhaustionBar{
  overflow: visible;
}

.exhaustionBar {
  .backgroundBar {
    fill: $color-light-grey;
  }

  .border {
    fill: none;
  }

  &.leq {
    .fillBar {
      fill: $color-primary-highlight;
    }
  }

  &.geq {
    .fillBar {
      fill: $color-orange-dark;
    }
  }

  &.eq {
    .fillBar {
      fill: $color-green;
    }
  }
}
