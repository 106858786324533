@use "../../scss/base/theme.module";

.augur--line {
  background: $color-white;
  padding: 43px 20px 20px;
  position: relative;
  + .augur--line {
    padding-top: 23px;
  }
  &.active {
    .subtitle {
      color: theme.$color-primary;
    }
    .subtitle--description {
      display: block;
    }
  }
  &.passive {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255,.5);
    }
    .subtitle--num {
      &:after {
        border-color: #ddd;
      }
    }
    .subtitle--num {
      border-color: $color-text-light;
      background: $color-text-light;
      color: $color-text;
    }
    .augur--options {
      display: none;
    }
  }
  &.finished {
    position: relative;
    color: $color-text;
    .subtitle--num {
      border-color: theme.$color-secondary;
      background: theme.$color-secondary;
      .subtitle--num--in {
        display: none;
      }
      .icon-ok {
        display: inline-block;
      }
    }
  }
  &.invalid {
    position: relative;
    color: $color-text;
    .subtitle--num {
      border-color: $color-red;
      background: $color-red;
    }
  }
  hr {
    margin: 14px auto 8px;
    &.mb18 {
      margin-bottom: 18px;
    }
  }

  .NumericSliderInput {
    margin: 10px 15px;

    .value-preview{
      margin-bottom: 5px;
    }
  }
}

.subtitle {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.3;
  color: $color-text-light;
  &.subtitle--inline {
    float: left;
  }
}
.subtitle--text {
  margin: 2px 0 0 60px;
}
.subtitle--name {
  padding: 0 0 11px 0;
  font-weight: 500;
  // min-height: 50px;
  display: block;
  flex-direction: column;
  @include flex-align(center, top);
}
.subtitle--description {
  font-size: 12px;
  color: $color-text;
}
.subtitle--num {
  font-weight: 400;
  font-size: 20px;
  color: $color-white;
  position: relative;
  width: 43px;
  height: 43px;
  background: theme.$color-primary;
  border-radius: 50%;
  border: 1px solid theme.$color-primary;
  float: left;
  text-align: center;
  line-height: 40px;
  margin: 0 4px 0 0;
  vertical-align: top;
  .icon-alert,
  .icon-ok {
    display: none;
    line-height: 40px;
    padding-left: 2px;
  }
}

.form--results {
  .form--select {
    color: $color-text-light;
  }
  .subtitle--num {
    box-shadow: none;
    border-color: $color-text-light;
    .icon-ok {
      color: #56c8ea;
      line-height: 36px;
    }
  }
}

.target {
  .Select-value {
    display: none;
  }
}

.target--items {
  margin: 0;
}
.target--item {
  display: inline-block;
  vertical-align: top;
  background: $color-light-grey;
  border: 1px solid $color-grey;
  padding: 8px 40px 8px 20px;
  margin: 0 5px 5px 0;
  position: relative;
  font-size: 15px;
  border-radius: 18px;
}
.target--item--close {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 100%;
  cursor: pointer;
  text-align: center;
  color: $color-text-light;
  &:before {
    content: "\e917";
    position: absolute;
    left: 0;
    top: 0;
    font-size: 20px;
    width: 40px;
    height: 100%;
    text-align: center;
    line-height: 34px;
    font-family: 'dashboardNG' !important;
  }
  &:hover {
    color: $color-text;
  }
}

.augur--options--text {
  margin: 0 0 0 60px;
}

.new--augur--index {
  padding: 100px 0;
  text-align: center;
  max-width: 400px;
  font-size: 15px;
  color: $color-text-light;
  margin: 0 auto;
  @include breakpoint(medium down) {
    padding: 30px 0;
  }
  p {
    padding: 0 0 40px 0;
  }
  h3 {
    margin: 0;
    color: $color-text;
    padding: 0 0 40px 0;
  }
  .button {
    margin: 1px 0;
  }
}
