@import '../../../../../scss/base/var';

.Header {
  display: flex;
  cursor: pointer;
}

.Icon {
  margin-right: 5px;
}

.TextInputLine {


  .TextInputLineHeader {
    display: flex;
    overflow: hidden;

    .TextInputLineLabel {
      color: $color-text-light;
      font-size: 14px;
      display: block;
      padding: 0 0 6px 0;
      white-space: nowrap;
      cursor: default;
      flex-shrink: 1;
      flex-grow: 1;
      width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .TextInputLineError {
      margin-left: auto;
      font-size: 14px;
      text-align: right;
      color: $color-red;
      float: right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 0 0 5px;
      cursor: default;
    }
  }

  .TextInputLineInput {
    width: 100%;
    border-radius: 3px;

    &.success {
      border-color: $color-green;
    }

    &.error {
      border-color: $color-red;
    }
  }

  .TextInputLineSuccess {
    font-size: 18px;
    text-align: right;
    color: $color-green;
    margin: -34px 13px 0 0;
    float: right;
  }
}

.CollapsibleHeadlineAreaBody {
  margin-bottom: 10px;
}