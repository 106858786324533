@import "../../../../../../../../scss/base/var";

.pipelineTuningResultsSingleResults {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 5px;

  .singleResultsPerPipeline {

    .singleResultsPerPath {
      margin-bottom: 30px;

      .headline {
        display: flex;
        flex-wrap: wrap;

        .headlineItem {
          flex-shrink: 0;
          white-space: nowrap;
          cursor: pointer;

          &_toggle {
            width: 30px;
          }

          &_bestScore {
            width: 150px;
          }

          &_classifier {
            width: 280px;
          }

          &_parameterSettings {
            width: 150px;
          }

          .headlineKey {
            font-weight: 400;
            margin-right: 5px;
          }
        }
      }

      .body {
        margin-top: 10px;
        margin-left: 30px; // To compensate for the expand / collapse icon

        .pipeline {
          margin-bottom: 10px;
          border: 1px solid $color-grey;
          border-radius: 4px;
        }

        .resultsTable {
          table {

            th, td {
              white-space: nowrap;
            }

            thead {
              .trHeaderNode {
                th {
                  border-bottom: none;
                  color: $color-text;
                  padding: 8px 10px 2px 10px;
                }
              }
              .trHeaderParameter{
                th {
                  border-top: none;
                  padding: 2px 10px 10px 10px;
                }
              }
            }
          }
        }
      }
    }

  }

}
