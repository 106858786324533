@import '../../../../../../../src/scss/base/var.scss';

.fileBrowser {
  // border: 1px solid deepskyblue;

  .table {
    width: 100%;
    text-align: left;

    thead {
      height: 32px
    }

    td, th {
      padding: 3px 8px;
      vertical-align: middle;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .colIcon {
    width: 32px;

    svg {
      margin-bottom: -3px; // To vertical align the icon with the text
    }
  }

  .colName {

  }

  .colCreatedAt {
    width: 20%;
    min-width: 180px;
  }

  .colSize {
    width: 10%;
    min-width: 90px;
  }

  .colActions {
    width: 140px;
  }

  .row {
    height: 38px;

    &:hover {
      background-color: $color-very-light-grey;
    }
  }

  .rowHeader {
    font-weight: 500;
    cursor: default;
  }

  .rowDirectoryUp {
    cursor: pointer;
  }

  .rowFile {
    cursor: default;
  }

  .rowTable, .rowDirectory {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      text-underline-offset: 2px;
      text-decoration-color: $color-grey;
    }
  }

  .rowEmptyIndicator {
    td {
      cursor: default;
      padding: 20px;
      background-color: $color-light-grey;
      text-align: center;
    }
  }

  .fileActions {
    height: 32px;
    border: 1px solid #dce2eb;
    border-radius: 16px;
    padding: 0 10px;
    display: inline-flex;

    div {
      margin: 0; // Remove the margin of the IconButton component
    }
  }
}
