@import '../../../../../scss/base/var';

.TextInputArea {

  .TextInputArea--header {
    display: flex;

    .TextInputArea--label {
      color: $color-text-light;
      font-size: 14px;
      display: block;
      padding: 0 0 6px 0;
      white-space: nowrap;
      cursor: default;
    }

    .TextInputArea--error {
      margin-left: auto;
      font-size: 14px;
      text-align: right;
      color: $color-red;
      float: right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 0 0 5px;
      cursor: default;
    }
  }

  .TextInputArea--input {
    width: 100%;
    border-radius: 3px;

    &.success {
      border-color: $color-green;
    }
    &.error {
      border-color: $color-red;
    }
  }
}
