.Language {
  margin: 0 0 40px 0;

  .Language--title {
    font-size: 15px;
    font-weight: 400;
    color: #a6aeb5;
    margin: 20px 0;
  }

  .buttons {
    margin-top: 20px;

    > * {
      margin-left: 10px;
    }
  }
}
