@font-face {
  font-family: 'RwWidgets';
  font-weight: normal;
  font-style: normal;
  src: url('../../fonts/rw-widgets.eot');
  src: url('../../fonts/rw-widgets.eot') format('embedded-opentype'),
  url('../../fonts/rw-widgets.woff') format('woff'),
  url('../../fonts/rw-widgets.ttf') format('truetype'),
  url('../../fonts/rw-widgets.svg') format('svg');
}

.rw-i {
  display: inline-block;
  color: $icon-color;
  font-family: RwWidgets;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
   -webkit-font-smoothing: antialiased;
}

.rw-i-caret-down:before { content: '\e803'; }
.rw-i-caret-up:before { content: '\e800'; }
.rw-i-chevron-left:before { content: '\f104';  }
.rw-i-chevron-right:before { content: '\f105'; }

.rw-i-clock-o:before { content:'\e805'; }
.rw-i-calendar:before { content: '\e804'; }
.rw-i-search:before { content: '\e801'; }
