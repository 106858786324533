@import '../../../../../../../scss/base/var';

.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.innerContainer {
  flex: 1;
  overflow: auto;
}

.TextInputLine {

  .TextInputLineHeader {
    color: $color-text;
    font-weight: 400;
    margin: 0 0 13px 0;
    font-size: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    .wrapper {
      position: relative;
      width: min-content;
    }

    .clear {
      position: absolute;
      right: 0;
    }

    .TextInputLineError {
      margin-left: auto;
      font-size: 14px;
      text-align: right;
      color: $color-red;
      float: right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 0 0 5px;
      cursor: default;
    }
  }

  .TextInputLineInput {
    width: 100%;
    border-radius: 3px;

    &.success {
      border-color: $color-green;
    }

    &.error {
      border-color: $color-red;
    }

    &.pending {
      border-color: $color-primary;
    }
  }

  .TextInputLineSuccess {
    font-size: 18px;
    text-align: right;
    color: $color-green;
    margin: -34px 13px 0 0;
    float: right;
  }

  .TextInputLinePending {
    font-size: 18px;
    text-align: right;
    color: $color-primary;
    margin: -34px 13px 0 0;
    float: right;
  }
}

.fileBrowser {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  .bucketPathContainer {
    display: flex;

    .bpBucket,
    .bpDir {
      padding: 5px 7px 5px 11px;
      border-radius: 4px;
      background-color: $color-white;
      cursor: pointer;

      &:not(&:last-child) {
        margin-right: -4px; // Exactly the border width
      }
    }

    .bpBucket {
      font-weight: 400;
      color: $color-primary-highlight;
      border: 1px solid $color-primary-highlight;

      &:hover {
        background-color: $color-primary-highlight-lighter90;
      }
    }

    .bpDir {
      border: 1px solid $color-grey;

      &:hover {
        background-color: $color-very-light-grey;
      }
    }
  }

  .tableContainer {
    position: relative;
    overflow: auto;
    flex: 1 1 0;

    .table {
      width: 100%;
      height: 100%;
      text-align: left;

      thead, th {
        position: sticky;
        top: 0;
        z-index: 1;
      }

      thead {
        height: 32px;
        background: #fff;
      }

      td, th {
        padding: 3px 8px;
        vertical-align: middle;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .colIcon {
    width: 32px;

    svg {
      margin-bottom: -3px; // To vertical align the icon with the text
    }
  }

  .colName {

  }

  .colCreatedAt {
    width: 20%;
    min-width: 180px;
  }

  .colSize {
    width: 10%;
    min-width: 90px;
  }

  .colActions {
    width: 140px;
  }

  .row {
    height: 38px;

    &:hover {
      background-color: $color-very-light-grey;
    }
  }

  .rowHeader {
    font-weight: 500;
    cursor: default;
  }

  .rowDirectoryUp {
    cursor: pointer;
  }

  .rowFile {
    cursor: default;
  }

  .rowTable, .rowDirectory {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      text-underline-offset: 2px;
      text-decoration-color: $color-grey;
    }
  }

  .rowEmptyIndicator {
    td {
      cursor: default;
      padding: 20px;
      background-color: $color-light-grey;
      text-align: center;
    }
  }

  .fileActions {
    height: 32px;
    border: 1px solid #dce2eb;
    border-radius: 16px;
    padding: 0 10px;
    display: inline-flex;

    div {
      margin: 0; // Remove the margin of the IconButton component
    }
  }
}

.tablePreview {
  padding-top: 10px;
  overflow: auto;
}
