@import '../../../../scss/base/var';

.PagingParent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;

  .PagingHeader {
    padding: 0 0 10px 0;
    display: flex;
    align-items: center;
    flex-grow: 0;
  .PagePickerContainer{
    display: flex;
    margin-left: auto;
    height: 36px;
    align-items: center;

    border-radius: 20px !important;
  }
    .pagePicker {
      display: flex;
      margin-left: 10px;
      height: 36px;
      padding: 0;
      border-radius: 20px;
      align-items: center;
      border: 1px solid $color-grey;
      flex-shrink: 0;

      .left, .right {
        height: 36px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        transition: background-color ease-in-out 100ms, color ease-in-out 100ms;

        &.active {
          .pageChevron {
            stroke: $color-primary;
          }

          &:hover {
            background-color: $color-primary;

            .pageChevron {
              stroke: $color-white;
            }
          }
        }

        &.inactive {
          pointer-events: none;

          .pageChevron {
            stroke: $color-grey;
          }
        }
      }

      .info {
        padding: 0 10px;
      }

      .rightRounded {
        border-radius: 0 20px 20px 0;
      }

      .leftRounded {
        border-radius: 20px 0 0 20px;
      }
    }

  }


  .PagingContent {
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
  }
}
