.Account {

  .MainContainer--inner {
    height: 100%;
    overflow-y: auto;
  }

  .UserProfile {
    padding: 40px 20px;
    max-width: 900px;
    margin: 0 auto;
  }
}
