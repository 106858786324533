@import "../../../../../../../../../scss/base/var";

.modelParameters {
  .modelStep {
    padding: 10px;
    display: flex;
    flex-direction: column;
    position: relative;

    &:not(:last-child){
      border-bottom: 1px solid $color-grey;
    }

    &_active {
      &:after {
        content: "";
        position: absolute;
        width: 4px;
        border-radius: 2px;
        left: -2px;
        top: 0;
        bottom: 0;
        background-color: $color-primary;
      }
    }

    .displayName {
      font-weight: 400;
      font-size: 15px;
    }

    .description {
      margin-top: 5px;
    }

    .parameters {
      .static,
      .tuning {
        margin-top: 10px;
        display: flex;
        flex-direction: column;

        .paramsHeadline {
          font-weight: 400;
          color: $color-text-light;
          margin-bottom: 5px;
        }

        .paramsContainer {
          display: flex;
          flex-direction: column;

          .params {
            width: 300px;
            margin-bottom: 5px;
            justify-content: stretch;

            .paramsName {
              font-weight: 400;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

}
