@import '../../../../../scss/base/var';

.context-menu{
  background-color: $color-white;
  border-radius: 2px;
  z-index: 4; // Should be enough to put it all the way to the top?
  // box-shadow: 0 0 0.25em $color-grey;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 8px; // #9
  padding-top: 5px;
  padding-bottom: 5px;
  position: absolute;
  right: 5px;
  width: 200px;

  a {
    text-decoration: none;
  }

  .arrow {
    position: absolute;
    // Start the pseudo element from the right
    right: 0;
    height: 0;

    &:before {
      content: "";
      position: relative;
      // 9px align the tip of the arrow best with the middle of the three dots with triggered it
      right: 9px;
      top: -27px;
      border-style: solid;
      border-width: 6px;
      border-color: transparent;
      border-bottom-color: #fff;
    }
  }

  &:focus{
    outline: none !important;
  }

  .cm-item-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px 5px 10px;
    cursor: pointer;

    .context-menu-icon{
      margin-right: 14px;
    }

    .context-menu-text{
      margin: 0;
      padding: 0;
      white-space: nowrap;
    }

    &:hover{
      background-color: $color-grey;
    }

    &--disabled{
      color: $color-text-light;
      cursor: default;
    }
  }
}
