.influencers-header{
  display: flex;
  flex-direction: row;
}

.influencers {
  position: relative;
  padding: 0 10px;
  text-align: center;
}

.bubble-chart_bubble-text {
  text-align: center;
  word-break: break-all;

  // foreignObject is broken is WebkKit
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  p {
    font-size: inherit;
    margin-bottom: inherit;
  }
}

.horizontal-bar-chart {
  position: relative;
}

.horizontal-bar-chart_tooltip {
  position: absolute;
  background: $color-white;
  border: 1px solid $color-light-grey;
  border-radius: 4px;
  min-width: 120px;
  padding: 6px 8px;
  text-align: left;
}
.tooltip_value {
  font-size: 18px;
  font-weight: 400;
}

.horizontal-bar-chart_bar {
  fill: $color-primary-highlight;
}
.horizontal-bar-chart_background-bar {
  fill: $color-light-grey;
}

.horizontal-bar-chart_grid {
  .axis path,
  .axis line {
    fill: none;
    stroke: $color-light-grey;
  }

  .y.axis g:nth-child(2n + 1) line {
    display: none;
  }
}
.tick {
  text {
    font-size: 12px;
    fill: darken($color-text-light, 50%);
  }
}

.hiddenTable {
  width: auto;
  opacity: 0;
}

.influencers-search-input-parent{
  margin-top: -7px;
  .influencers--search--input {
    height: 36px;
    width: 200px;
    border: 1px solid #aaa;
    border-radius: 3px;
    padding-top: 8px;
    padding-bottom: 5px;
    padding-left: 13px;
    padding-right: 13px;

    &:focus{
      outline: none;
    }
  }
}
