.app-arrange-modal {
  .close-button {
    z-index: 3;
  }
}

.app-parent {
  height: calc(100vh - 200px);
  width: calc(100vw - 200px);
  display: flex;
  flex-direction: column;

  .app-part-main {
    flex-grow: 1; // Take all vertical space that isn't taken by the .app-part-arranger container
    display: flex;
    flex-direction: column;

    .main-input-output {
      flex-grow: 1; // Take all vertical space that isn't taken by the .main-button-bar
      display: flex;
      padding: 20px;
      width: 100%;
      background-color: $color-white;
      position: relative;
      overflow-y: auto;

      .main-output-container {
        margin-left: 10px;
      }

      .output-container-parent {
        padding: 10px;
      }

      .step-loading-parent {
        position: relative;
        height: 100%;
        width: 100%;
      }

      .beginning-step-parent {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;

        .beginning-message-parent {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: default;
          flex-grow: 1;
          width: 100%;
          max-width: 800px;
          position: relative;
          height: 100%;
          padding: 20px;

          .headline {
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 30px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            text-align: center;
          }

          .description-container {
            overflow-y: auto;
            max-height: calc(100% - 40px - 40px); // 40px for the buttons and 40px for the headline

            .description {
              font-size: 14px;
              color: $color-text;
              // text-align: center;
            }
          }

          .TextInputLine {
            width: 80%;
          }
          .TextInputArea {
            width: 80%;
            margin-top: 20px;
          }
        }

        .beginning-buttons-parent {
          display: flex;
          justify-content: flex-end;
          width: 100%;
        }
      }

      .step-parent {
        width: calc(100% - 40px); // minus the margin
        height: calc(100% - 40px); // minus the margin
        position: absolute;

        &.dragged-over {
          border: 2px dashed $color-grey;
        }

        .busy {
          z-index: 2;
          background-color: rgba(255, 255, 255, 0.7);
        }

        .grid-item-parent {
          background-color: $color-very-light-grey;
          border-radius: 4px;
          border: 1px solid $color-grey;

          &.missing-grid-item {
            padding: 10px;
            border: 1px dashed $color-red;
            color: $color-red;

            .remove-icon {
              stroke: $color-red;
            }
          }

          .remove-icon {
            position: absolute;
            right: 5px;
            top: 5px;
            cursor: pointer;
          }
        }

        &.error-parent {
          margin: 20px;
          padding: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;

          .error-scroll-parent {
            position: absolute;
            width: 800px;
            overflow-y: auto;

            .error-message-container {
              color: $color-red;
              font-weight: 500;
              margin-bottom: 20px;
            }

            .error-outputs-container {
              .CodeCellOutputs {
                padding: 20px;
                border: 1px solid $color-red;
                border-radius: 4px;
                background-color: $color-red-light;

                &:not(:last-child) {
                  margin-bottom: 20px;
                }
              }
            }
          }
        }
      }

    }

    .main-button-bar {
      width: 100%;
      padding: 10px 20px 10px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .step-info-container {
        cursor: default;
        display: flex;
        flex-direction: column;
        align-items: center;

        .step-type {
          font-weight: 400;
          color: $color-text-light;
        }
      }
    }
  }

  .app-part-arranger {
    // background-color: lightskyblue;
    margin-top: 10px;
    // height: 220px;
    position: relative;
    // border-top: 1px solid $color-grey;
    border: 1px solid $color-grey;
    border-radius: 4px;

    .expand-collapse-flap {
      position: absolute;
      // border: 1px solid $color-grey;
      background-color: $color-grey;
      color: $color-text;
      border-radius: 15px;
      height: 30px;
      width: 70px;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: background-color 0.25s ease-out, color 0.25s ease-out;

      &:hover {
        background: darken($color-grey, 20%);
      }

      &.not-empty {
        background-color: $color-orange;
        color: $color-white;

        &:hover {
          background: darken($color-orange, 20%);
        }
      }
    }

    .expand-collapse-amount-info {
      position: absolute;
      height: 30px;
      width: 180px; // Overwritten with inline-style in Arranger.jsx
      top: 0;
      left: calc(50% + 20px); // + 20px = 35px (half of the expand-collapse-flaps width) - 15px (it's border radius)
      transform: translate(0, -50%);
      border-top: 1px solid $color-orange;
      border-right: 1px solid $color-orange;
      border-bottom: 1px solid $color-orange;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      background-color: $color-white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      transition: width 0.25s ease-out;
      padding-left: 10px;
    }

    .workbench-buttons {
      display: flex;
      padding: 5px 5px 5px 5px;
      height: 40px;

      .workbench-button {
        width: 30px;
        height: 30px;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-text;
        margin-right: 5px;

        &:hover {
          background-color: $color-grey;
          color: $color-white;
        }

        &.disabled {
          color: $color-grey;

          &:hover {
            background-color: $color-white;
            color: $color-grey;
          }
        }
      }
    }

    .parent-unlayouted {
      position: relative;
      width: 100%;
      height: 170px; // Set in Arranger.jsx as inline css
      padding: 10px; // Set in Arranger.jsx as inline css
      display: flex;
      // background-color: greenyellow;
      overflow-y: hidden;
      overflow-x: auto;
      transition: height 0.25s ease-out, padding 0.25s ease-out, border-top-width 0.25s ease-out;
      border-top: 1px solid $color-grey;

      .unlayouted-element {
        cursor: pointer;
        background-color: $color-very-light-grey;
        border-radius: 4px;
        border: 1px solid $color-grey;
        margin-right: 10px;
        height: 150px;
        width: 200px;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
      }
    }
  }
}

// --- Styles specific to the input-elements in the App view (main part + unlayouted elements of the app)
.app-parent {
  .output-element,
  .input-element {
    // height: 100%;

    .output-element-container,
    .input-element-container {
      border: none;
      width: 100%; // To make the text fields grow in width with the react grid "cards"
      height: 100%;

      .output-container-parent {
        padding: 0;
      }

      .output-element-title,
      .output-element-description,
      .input-element-title,
      .input-element-description {
        // white-space: nowrap;
        overflow-x: hidden;
      }

      .inner-output-element,
      .inner-input-element {
        // height: 100%;
        flex-grow: 1;
      }
    }
  }

  &.app-static { // Styles that only apply if the app is in the static execution mode. Counterpart to &.app-arrange-editor
    .rw-calendar {

    }
  }
}

.MainContainer.Apps {
  .running-app-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    .button-container {
      margin: 0;
      padding: 0;
      border: 0;
      vertical-align: baseline;
    }
  }

  .app-main-container {
    font-family: $Roboto;
    font-size: 14px;
    font-weight: 300;
    border-radius: 5px;
    display: flex;
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    width: 100%;

    p {
      padding: 0;
    }
  }

  .app-parent { // Applies only to the final built apps (when they are executed only)
    height: 100%;
    width: 100%;
    padding: 20px 0;
  }
}
