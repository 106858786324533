.multi-col-select { // Multi column select (one step of the New Augur Wizard)

  table {
    width: 100%;
    table-layout: fixed;

    td {
      height: 36px;
      padding: 10px;
      white-space: nowrap;

      &.td-checkbox {
        width: 36px;
        padding: 8px;
      }

      &.td-name, .td-type {
        width: auto;
      }
    }
  }

  .checkbox-item {
    position: relative;
    cursor: pointer;

    .mark-input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
    }

    .mark-input:checked ~ .mark:after {
      display: block;
    }

    .mark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: #fff;
      border: 1px solid #eee;
      border-radius: 4px;

      &:hover {
        background-color: #ccc;
      }

      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 6px;
        top: 0;
        width: 8px;
        height: 14px;
        border: solid darkblue;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  .warning-container{
    display: flex;

    .warn-icon{
      margin-right: 5px;
      stroke: $color_red;
    }
  }

  .error-line {
    margin-top: 0;
    margin-bottom: 6px;
  }
}
