.ChangePassword {
  &--bottom {
    margin-top: 20px;
    display: flex;
    align-items: center;

    &--Field--Parent {
      margin-bottom: 1rem;
    }

    .ToggleInput {
      display: inline-block;
    }

    .Button {
      display: inline-block;
      margin-left: auto;
    }
  }
}
