@import '../../../../../scss/base/var';

.MergeRequestListItem--element {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-top: 1px solid $color-grey;
  padding: 10px;
  text-decoration: none;

  &:hover {
    background-color: $color-light-grey;
  }

  .element-icon-parent {
    margin-right: 20px;

    .element-icon {
      color: #3a3b3d;
    }
  }
  .element-name-parent {
    flex-grow: 1;
    .element-name {
      .name {
        font-weight: 400;
      }
    }
    .element-commit{
      color: $color-text-light;
      margin-top: 5px;

      .commit-details {
        display: flex;
        align-items: center;
      }
    }
  }
}
