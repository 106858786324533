@import "../base/var";

.Orchestration--content.Orchestration--JobDetails {
  overflow-x: hidden;

  .job-details-header {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    .back-button-container {
      flex-grow: 1;
    }
    .top-button-container {
      display: flex;
      flex-direction: row-reverse;

      .button {
        display: flex;
        align-items: center;
        margin-left: 10px;
      }
    }
  }
  .job-details-content-container {
    .headline {
      font-weight: 400;
      font-size: 15px;
      color: $color-text-light;
    }

    .job-details-content {
      padding: 10px;
      border: 1px solid $color-grey;
      border-radius: 4px;
      margin-top: 10px;

      .job-details-row {
        display: flex;
        cursor: default;

        &:hover {
          background-color: $color-blue-grey;
        }

        .job-details-title {
          font-weight: 400;
          margin-right: 10px;
          min-width: 200px;
          padding: 10px;
        }
        .job-details-value {
          padding: 10px;
          overflow-x: auto;
          text-overflow: ellipsis;

          .job-details-value-no-value {
            font-style: italic !important;
            color: $color-text-light;
          }

          li:not(:last-child) {
            margin-bottom: 5px;
          }

          td {
            padding-bottom: 5px;
            // Override the table defaults, choose same font size as in list etc.
            font-size: 14px;
            border: none;

            &.job-details-value-object-key {
              padding-right: 5px;
            }
          }
        }

        &.status {
          .job-details-value {
            font-weight: 400;
            color: $color-white;
            height: 18px;
            padding: 0 18px;
            margin: 9px 10px;
            border-radius: 9px;

            &.status_waiting {
              background-color: $color-grey;
              color: $color-text;
            }
            &.status_waiting-in-execution-queue {
              background-color: $color-dark-grey;
            }
            &.status_triggered {
              background-color: $color-purple;
            }
            &.status_running {
              background-color: $col-status-blue;
            }
            &.status_success {
              background-color: $color-green;
            }
            &.status_cancelled {
              background-color: $color-orange;
            }
            &.status_failure {
              background-color: $color-red;
            }
          }
        }
      }
    }
  }

  .job-logs-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 1px; // flex-grow will make the div grow to the full remaining height of the page

    .headline {
      font-weight: 400;
      font-size: 15px;
      color: $color-text-light;
    }

    .job-logs {
      margin-top: 10px;
      border-radius: 4px;
      border: 1px solid $color-grey;
      padding: 10px;
      overflow-y: scroll;

      .job-log-entry {
        font-family: $font-monospace;
        font-size: 13px;
        &:not(&:last-child) {
          margin-bottom: 5px;
        }
        .job-log-line {
          display: block;
          white-space: pre;
        }
      }
    }
  }
}



.Orchestration--JobDetails{
  .refresh-icon-container {
    margin-left: 10px;
    height: 40px;

    .refresh-icon-spinning-parent {
      width: 30px;
      height: 30px;
      margin: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      .refresh-icon-spinning {
        color: $color-primary;
        margin: 5px;

        -webkit-animation:spin 2s linear infinite;
        -moz-animation:spin 2s linear infinite;
        animation:spin 2s linear infinite;
      }
    }

    @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
    @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
    @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
  }
}
