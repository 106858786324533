@import '../../../../scss/base/var';

$border-radius: 4px;

.table {

  &:not(&.striped) {
    table {
      tr {
        background-color: $color-white;
      }
    }
  }

  &.fixedLayout {
    table-layout: fixed;
  }

  &.borderAround {
    border: 1px solid $color-grey;

    table {
      margin: -1px 0;
    }
  }

  &.roundedBorder {
    border-radius: $border-radius;

    table {
      thead {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;

        tr:first-child {
          th:first-child {
            border-top-left-radius: $border-radius;
          }
          th:last-child {
            border-top-right-radius: $border-radius;
          }
        }
      }

      tbody {
        tr:last-child {
          td:first-child {
            border-bottom-left-radius: $border-radius;
          }

          td:last-child {
            border-bottom-right-radius: $border-radius;
          }
        }
      }
    }

  }

  &.verticalAlignMiddle {
    td {
      vertical-align: middle
    }
  }

  .removeIconColumn {
    width: 20px;
    padding: 5px;

    &.removeDisabled {
      .removeIcon {
        background-color: $color-text-light;
        cursor: default;

        &:hover {
          background-color: $color-text-light;
        }
      }
    }

    .removeIcon {
      height: 20px;
      width: 20px;
      padding: 2px;
      border-radius: 10px;
      background-color: $color-red;
      color: $color-white;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;

      &:hover {
        background-color: $color-red-dark;
      }
    }
  }
}
