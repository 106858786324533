@import "../../../../../scss/base/var";


.NotebookCell{
  padding: 5px 10px 5px 0;
  display: flex;

  &:first-child {
    padding-top: 10px;
  }

  &:last-child {
    padding-bottom: 10px;
  }

  .NotebookCell--container {
    margin: 0 5px 0 0;
    border: none;
    display: block;
    width: 0;
    flex-grow: 1;
    border-radius: 2px;

    &.NotebookCell--is-single-selected {
      // box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
    }
  }

  .editor-parent-container{
  }

  .editor-container{
    border: 1px solid $color-grey;
    border-radius: 2px;
    font-family: $font-monospace;
    padding: 3px;
    background-color: white;

    textarea:focus{
      outline: none !important;
    }
    &.has-output{ //Remove bottom border + radius if there is output (that is appended directly)
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
    }
  }

  .completion-list {
    max-height: 240px;
    overflow-y: auto;
    width: 300px;
    border: 1px solid $color-grey;
    border-top: none;
    background-color: white;
    z-index: 5;
    position: absolute;
    font-family: $font-monospace;
    font-size: 13px;

    .completion-element{
      display: flex;
      cursor: pointer;

      .completion-type {
        padding: 5px;
        display: inline-block;
        width: 90px;

        &.instance {
          background-color: $color-orange-dark;
          color: $color-white;
        }
        &.function {
          background-color: $color-blue;
          color: $color-white;
        }
        &.keyword {
          background-color: $color-purple;
          color: $color-white;
        }
        &.statement {
          background-color: $color-red-dark;
          color: $color-white;
        }
        &.class {
          background-color: $color-green-dark;
          color: $color-white;
        }
      }
      .completion-text {
        display: inline-block;
        padding: 5px;
        flex-grow: 1;
      }

      &.active {
        .completion-text{
          background-color: $color-primary-highlight;
        }
      }
    }
  }

  &.raw-cell{
    .editor-container{
      background-color: $color-light-grey !important;
    }
  }

  &.markdown-cell{
    .main-container > *{
      margin: 5px;
    }
  }

  &.NotebookCell-is-multi-selected {
    background-color: #6AA0EB22;
  }
//needs to be important, so the passed style of papermill gets overridden
  .papermill-error-cell-tag{
    background-color: $color-red-light !important;
    p span{
      color: $color-red !important;
      font-size: 1em !important;
      font-family: "Monaco", monospace !important;
    }
  }

}
