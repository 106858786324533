.tab-container{
  position: relative;

  .main-tab-link{
    height: 63.5px;
    border-bottom: 5px solid $color-grey;

    &:focus{
      outline: none;
    }

    .main-tab-text{
      margin: 0 15px;
      color: $color-text-light;
      font-size: 14px;
      font-weight: 400;
    }

    &.tab-link-active {
      font-weight: inherit !important;
      .main-tab-text {
        color: $color-text;
      }
    }
  }

  // Underline animation
  #underline{
    position: absolute;
    top: 59px;
    left: 0; //Initial position (animated by anime)
    height: 5px;
    width: 1px; //Initial width (animated by anime)
    border: none;
    padding: 0;
    margin: 0;
    background-color: $color-primary;
  }
}
