@import "../../../../../scss/base/var";
@import "../../../../../scss/vendor/foundation/foundation.scss";

.TopMenu {
  position: absolute;
  right: 12px; // to move the arrow of the menu right below the chevron icon down
  top: 100%;
  background: $color-white;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 8px; // #9;
  z-index: 999;
  min-width: 150px;
  display: flex;

  .TopMenu--category{
    padding: 10px 5px;
    &:not(:first-child){
      // margin-left: 30px;
      border-left: 1px solid $color-grey;
    }
    .TopMenu--category-title{
      color: $color-text-light;
      font-weight: 500;
      padding: 10px 25px 10px 10px;
    }
    .TopMenu--item {
      // Overwrite the inherited white color from the header
      color: $color-text;
      // Not all items may be links that are already cursors
      cursor: pointer;
      padding: 10px;
      min-width: 200px;
      .TopMenu--icon {
        margin-right: 10px;
      }
      a {
        display: flex;
        align-items: center;
      }
    }
    .TopMenu--link {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @include breakpoint(large up) {

  }
  &:after {
    content: "";
    position: absolute;
    right: 13px;
    top: -12px;
    border-style: solid;
    border-width: 6px;
    border-color: transparent;
    border-bottom-color: $color-white;
  }
  .search {
    display: block;
    padding: 0;
    margin: 0 0 10px 0;
    &:after {
      display: none;
    }
    .input-group {
      background: $color-light-grey;
    }
    .input-group-field {
      color: $color-text;
      background: $color-light-grey;
      @include placeholder {
        color: $color-text;
      }
    }
    .button {
      .icon-search {
        color: $color-text;
      }
      &:hover {
        .icon-search {
          color: $color-text;
        }
      }
    }
  }
  .profile {
    color: $color-text;
    &:hover, &:active, &:focus {
      color: $color-text;
      .profile--name {
        color: $color-text;
      }
    }
  }
}
