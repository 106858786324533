// xxxxxxxxxxxxx MAKE IT DEPRECATED! xxxxxxxxxxxxx
// TODO The styling in here is also used for the Admin -> Resources screen. This means the styling in here should be
//   extracted and generalized.

.container--resource {
  margin-top: 62px;

  > div {
    display: flex;
    border: 1px solid #ddd;
    border-radius: 4px;

    > div:last-of-type {
      width: 100%;
      min-height: calc(100vh - 62px - 48px - 40px); // 62px top bar, 48 px main tab bar, 40px=2*20px vertical margin
      height: 100%;
      padding: 20px 15px;
      background: white;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      overflow-x: auto;
    }
  }
}
// xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

.MainContainer.Orchestration {

  .Orchestration--content {
    padding: 20px 15px;
    height: 100%;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;

    .Orchestration--inner-content {
      flex-grow: 1;

      &.not-scrollable {
        height: 100%; // Needed to make the inner (e.g. paging) component scroll instead
      }
    }
  }

  .orchestration-queue-parent {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    .queue-headline{
      font-size: 15px;
      font-weight: 400;
      display: block;

      &.queue-headline--execution {
        margin: 0 5px 10px 5px;
      }

      &.queue-headline--augur {
        margin: 20px 5px 10px 5px;
      }
    }

    .orchestration-job-queue,
    .orchestration-execution-queue {
      display: flex;
      flex-wrap: wrap;

      .queue-info {
        margin-left: 5px;
      }

      .process-order-bar {
        display: flex;
        align-items: flex-end;
        font-weight: 600;
        color: gray;
        writing-mode: vertical-lr;
        margin-bottom: 10px;
        transform: rotate(180deg);
      }

      .job-card {
        border: solid 1px #cacaca;
        margin: 5px;
        border-radius: 10px;
        width: 293px;
        padding: 10px;
        background: white;

        hr {
          height: 0;
          border-right: 0;
          border-top: 0;
          border-bottom: 1px solid #cacaca;
          border-left: 0;
          clear: both;
          margin: 7px -10px;
        }

        .card-header {
          font-weight: bold;
          text-transform: capitalize;
        }

        .queue-card-wrapper {
          border: solid 1px lightgrey;
          background: #c1c1c19e;
          padding: 5px;
          border-radius: 4px;
          margin: 5px 0;
          text-transform: capitalize;

          .queue-header {
            font-weight: bold;
          }

          .queue-card {
            background: white;
            display: flex;
            margin: 10px 5px;
            height: 40px;
            border-radius: 20px;
            align-items: center;
            padding: 4px;
            font-size: 0.8rem;

            .job-class {
              font-weight: bold;
            }

            span {
              display: flex;
              align-items: center;
              cursor: default;

              &.dot {
                height: 30px;
                width: 30px;
                background-color: gray;
                border-radius: 50%;
                margin-right: 10px;
                background-color: #999999;

                &.executed {
                  background-color: #87C333;
                }
                &.executing {
                  background-color: #33ABC3;
                }
                &.waiting {
                  background-color: #999999;
                }
                &.waiting-in-execution-queue {
                  background-color: #E4C23C;
                }
                &.triggered {
                  background-color: #9f7fe4;
                }
                &.error {
                 background-color: #E4823C;
                }
              }
            }
          }
        }
      }
    }
  }

  .jobconfigs-parent {
    .configmap {

      &:not(:first-child){
        margin-top: 30px;
      }

      .configmap-headline {
        margin: 0 5px 10px 5px;
        font-size: 15px;
        font-weight: 400;
        display: block;
      }

      .cm-table {
        tbody tr:hover{
          background-color: $color-blue-grey;
          .cm-edit-icon {
            display: inline;
          }
        }

        .td-key {
          width: 1px;
          cursor: default;
        }

        .td-value {
          cursor: pointer;
        }

        .cm-row {
          .cm-edit-icon {
            stroke: $color-text;
            margin: -2px 5px;
            display: none;
            vertical-align: text-top;
          }

          .cm-input-parent {
            display: flex;
            align-items: center;
            max-height: 24px;

            .cm-input {
              font-size: 13px;
              height: 24px;
              max-width: 300px;
            }

            .cm-input-ok,
            .cm-input-cancel {
              margin-left: 5px;
            }

            .busy{
              height: 40px !important;
              margin-left: 5px;
              fill: $color-grey;
              svg {
                height: 40px !important;
              }
            }
          }
        }
      }
    }
  }
}

.inline-icon-ok {
  stroke: $color-green;
}

.inline-icon-cancel {
  stroke: $color-red;
}


