@import '../../../../scss/base/var';

$margin-between: 6px;
$element-size: 20px;
$dot-size: 14px;

$color-rejected: $color-orange;
$color-auto: $color-primary-highlight;
$color-forced: $color-green;

.rejectAutoForceToggle {
  display: flex;
  flex-direction: row;

  .rejectAutoForceElement {
    border: 1px solid $color-text-light; // Fallback color
    width: $element-size;
    height: $element-size;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border-color 0.1s ease-in-out;
    cursor: pointer;

    .activeBubble {
      background-color: $color-text-light; // Fallback color
    }

    &:not(:last-child) {
      margin-right: $margin-between;
    }

    .activeBubble {
      width: $dot-size;
      height: $dot-size;
      border-radius: 50%;
      opacity: 0;
      transition: opacity 0.1s ease-in-out;

      &.isActive {
        opacity: 1;
      }

      &:hover {
        opacity: 0.5;

        &.isActive {
          opacity: 1;
        }
      }
    }
  }

  &.rejected {
    .rejectAutoForceElement {
      border-color: $color-rejected;

      .activeBubble {
        background-color: $color-rejected;
      }
    }
  }

  &.auto {
    .rejectAutoForceElement {
      border-color: $color-auto;

      .activeBubble {
        background-color: $color-auto;
      }
    }
  }

  &.forced {
    .rejectAutoForceElement {
      border-color: $color-forced;

      .activeBubble {
        background-color: $color-forced;
      }
    }
  }
}
