@import '../../../../scss/base/var';

.wizardStep {

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  &:last-child {
    margin-bottom: 40px;
  }

  .wizardStepTop {
    // border: 1px dashed limegreen;
    display: flex;
    flex-direction: row; // This is always set to "flex-direction: column" for screens smaller than $media-sm
    margin: 0 auto;
    max-width: 1200px;

    .infoContainer {
      // border: 1px dashed deeppink;
      flex-grow: 0;
      flex-shrink: 1;
      margin-right: 60px;
      display: flex;
      flex-direction: row;

      .bubbleContainer {
        margin-right: 20px;
        // border: 1px dashed orange;
      }

      .textContainer {
        // border: 1px dashed #009688;
        cursor: default;
        width: 300px;

        .title {
          font-weight: 500;
          height: 40px; // Same as the bubble
          display: flex;
          align-items: center;
        }

        .description {
          display: block;
          // margin-top: 16px;
        }
      }
    }

    .inputContainer {
      // border: 1px dashed deepskyblue;
      flex-grow: 1;
      flex-shrink: 1;
      width: 1px; // To make all steps grow equally
      // overflow: hidden;

      .errorParent {
        // !! Actually no error is displayed here at the moment. The errors are treated by the input components themselves
        // height: 40px; // Same as the bubble
        // border: 1px dashed darkred;
        overflow: hidden;
        display: flex;
        align-items: center;
        cursor: default;
      }

      .inputParent {
        // border: 1px dashed lightsalmon;
        .container {
          width: 100%;
          margin-top: 1rem;
          display: flex;
          align-items: flex-end;

          div {
            flex-grow: 1;
          }
        }

        .group {
          flex-grow: 0 !important;
          height: 46px;
          display: flex;
          align-items: center;

          span {
            margin-right: .25rem;
            font-weight: 400;
          }
        }
      }
    }
  }

  .wizardStepBottom {
    margin: 20px auto 0 auto;
    max-width: 1200px;

    &.fullWidth {
      max-width: unset;
    }
  }
}


.container {
  position: relative;
}

.image {
  border: 1px solid $color-primary;
  opacity: 1;
  display: block;
  transition: .5s ease;
  backface-visibility: hidden;
  background-color: $color-light-grey;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  text-align: center;
  line-height: 110px;
}

.middle {
  border-radius: 50%;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 55px;
  left: 55px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
}

.container:hover .image {
  opacity: 0.1;
}

.container:hover .middle {
  opacity: 1;
}

.crossContainer {
  border-radius: 50%;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 55px;
  left: 55px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;

  .cross {
    opacity: 0.8;
    top: 0;
    right: 0;
    padding: 5px 5px 0 0;
    font-size: 20px;
    text-align: right;
    color: $color-red;

    &:hover {
      opacity: 1;
    }
  }
}