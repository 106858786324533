@import '../../../../scss/base/var';

.table {
  cursor: default;

  table {
    table-layout: fixed;

    td {
      &.eventType {
        font-weight: 400;
      }
    }

    thead {
      td:last-child {
        width: 66%;
      }
    }
  }
}

table {
  .detailsCell {
    border: 1px solid $color-grey;
    border-radius: 5px;
    overflow: hidden;

    a {
      text-decoration: none;
    }

    tr {
      &:first-child {
        td {
          border-top: none;
        }
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }

      td {
        background-color: $color-white;

        &:last-child {
          width: 80%;
        }
      }
    }
  }
}

.disabled {
  filter: opacity(.25);
}

.detailsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.eventDetailsKeyContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 20%;
}

.eventDetailsValue {
  padding: 0;
  max-width: 100%;
  margin-left: 1rem;
  width: calc(100% - 1rem);
}

.eventDetailsValuePreview {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;
  width: 80%;
  margin-left: .5rem;
}

.monospaced {
  font-family: monospace;
}

.break {
  overflow-wrap: break-word;
}
