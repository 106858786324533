@import '../../../../../scss/base/var';

.ToggleInput {

  .ToggleInput--header {
    display: flex;

    .ToggleInput--label {
      color: $color-text-light;
      font-size: 14px;
      display: block;
      padding: 0 0 6px 0;
      white-space: nowrap;
      cursor: default;
    }
  }

  .ToggleInput--container {
    display: flex;
    align-items: center;

    .ToggleInput--description {
      margin-right: 10px;
    }
  }

}
