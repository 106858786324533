@import '../../../../../../../../scss/base/var';

.parameterTuningResults {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;
}

.headline {
  margin-bottom: 5px;
}

.parameterBlock {
  margin-bottom: 15px;
}

.parameterName {
  display: inline-block;
  width: 200px;
  overflow-wrap: anywhere;
}

.parameterValue {
  display: inline-block;
  width: 50px;
  text-align: right;
}

.resultContainer {
  margin-bottom: 15px;
}

.resultHeadline {
  margin-bottom: 5px;
}
