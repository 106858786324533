@import '../../../../../scss/base/var';

.AccountInfo {
  margin: 0 0 20px 0;

  .AccountInfo--title {
    font-size: 15px;
    font-weight: 400;
    color: #a6aeb5;
    margin: 20px 0;
  }


  .AccountInfo--content {
    display: flex;

    .AccountAddPhoto {
      flex-shrink: 0;
    }

    .AccountUserInfo {
      margin-right: 20px;
      width: 100%;

      .AccountUserInfo--line {
        margin-bottom: 16px;
        display: flex;

        .AccountUserInfo--column {
          // width: 0;
          // flex-grow: 1;
          display: block;
          margin-right: 20px;

          .AccountUserInfo--label {
            color: #a6aeb5;
            font-size: 14px;
            display: block;
            padding: 0 0 6px 0;
          }

          .AccountUserInfo--value {
            color: $color-text;
            font-size: 18px;
          }
        }
      }
    }
  }
}
