@import '../../../../scss/base/var';

.GenericFormStep {
  color: $color-text;
  display: flex;
  margin-bottom: 40px;

  &.GenericFormStep--component-below {
    display: block;

    .GenericFormStep--input-container {
      margin-left: 60px;
      width: 100%; // flex-grow: 1 will care about the width
    }
  }

  .bubble{
    margin-right: 20px;
    width: 40px;
    height: 40px;
    color: $color-white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    p{
      font-size: 20px;
      padding: 0;
    }
  }
  .bubble-info{
    background-color: $color-primary;
  }
  .bubble-ok{
    background-color: $color-green;
  }
  .bubble-warn{
    background-color: $color-orange;
  }
  .bubble-delete{
    background-color: $color-red;
  }
  .checked-icon{
    color: $color-white;
  }

  .description-container{
    margin-top: 14px;
    display: flex;
    margin-right: 40px;
    .description{
      width: 300px;
      .title{
        min-height: 40px;
        margin-bottom: 16px;
        font-weight: 500;
        color: $color-primary;
        display: flex;
        align-items: center;
        font-size: 15px;
        vertical-align: baseline;
      }
    }
  }

  .GenericFormStep--input-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 0; // flex-grow: 1 will care about the width

    .error-container{
      height: 18px;
      div {
        text-align: right;
        margin: 0;
        color: $color-red;
      }
    }

    .GenericFormStep--field {
      &:first-child {
        margin-top: 20px; // To align it with the title field on the left
      }
      margin-bottom: 20px;
    }
  }

  .input-container-error{
    .input, .textarea, .Select-control {
      border: 1px solid $color-red !important;
      border-radius: 3px;
    }
    .path-input{
      color: $color-red;
    }
  }
  & .kernel-input{
    flex-direction: row;
  }
}
