.resourceManagement {
  height: 100%;
  display: flex;

  .leftCol {
    min-width: 500px;
  }

  .rightCol {
    margin-left: 5px;
    // border: 1px solid deepskyblue;
    min-width: 440px;
  }
}

.category {
  padding: 20px 15px;
  background-color: white;
  border-radius: 4px;
  // overflow: hidden;

  &.categorySessions {
    height: 100%;
    overflow-y: auto;
  }

  &.categoryMetrics {
    margin-bottom: 5px;
  }

  &.categoryDiskUsage {
    margin-bottom: 5px;
  }
}
