@import '../../../../scss/base/var.scss';

.moduleDetails {
  height: 100%;

  :global(.SideMenu) {
    background-color: $color-white;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    overflow-y: auto;
    border-right: 1px solid $color-light-grey;
  }
}
