@import '../../../../../scss/base/var';

.container {
  height: 100%;
  width: 100%;
  position: relative;
}

.chart {
  height: 100%;
  width: 100%;
  vertical-align: top; // fixes a scrollbar problem with svg: https://stackoverflow.com/a/42998206
}