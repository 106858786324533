.fullscreenContainer {

  &:global(.MainContainer.Workbench) {
    max-width: unset;
    width: 100%;
    height: 100vh !important;
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;

    :global(.MainContainer--inner) {
      width: 100%;
      border: none;
    }

    :global(.browser) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: 100vh !important;

      :global(.browser-tab-link-container) {
        :global(#tab-content) {
          border-top-left-radius: 0;
        }
      }

      :global(.content-list) {
        height: calc(100vh - (50px + 50px + 50px));
      }
    }

    :global(.container-notebook) {
      width: 100%;
      height: 100vh !important;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;


      :global(.fullscreen-button) {
        border-top-right-radius: 0;
      }

      :global(.cells) {
        height: calc(100vh - (20px + 30px + 50px))
      }

      :global(.code-content) {
        :global(.editor-parent-container) {
          height: calc(100vh - (20px + 30px + 50px))
        }
      }
    }
  }

}
