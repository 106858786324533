@import '../../../../scss/base/var';

.habitat {
  background: $color-white;
  border-radius: 4px;
  margin-bottom: 30px;

  .habitatHeader {
    border-bottom: 1px solid $color-grey;
    display: flex;
    margin: 0 0 20px 0;
    height: 46px;
    // overflow: hidden; // This makes the dropdown menu disappear

    .headerName {
      background-color: $color-white;
      color: $color-text;
      border-top-left-radius: 8px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      width: 240px;
      cursor: default;

      .habitatIcon {
        margin-right: 5px;
        opacity: 0.3;
        font-size: 20px;
      }

      .habitatName {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        margin-left: 3px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .editHabitatIcon {
        margin-left: auto;
        /* padding to arrange the icon a bit lower, this makes it look more aligned with the other icons in the header */
        padding-top: 4px;

        &:hover {
          svg {
            color: $color-text;
          }
        }

        svg {
          color: $color-text-light;
          transition: color 0.1s ease-in-out;
        }
      }
    }

    .headerTabs {
      display: flex;

      .headerTab {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-text-light;
        border-left: 1px solid $color-grey;
        padding: 0 18px 0 14px;
        // border-bottom: 2px solid $color-white;
        // transition: border-bottom-color 0.2s ease-in-out;

        &:last-child {
          border-right: 1px solid $color-grey;
        }

        &:hover {
          color: $color-text;
        }

        &.headerTabDisabled {

        }

        &.headerTabActive {
          background-color: $color-primary;
          color: $color-white;
        }

        .headerContent {
          display: flex;
          align-items: center;

          .headerIcon {
            font-size: 20px;
            margin-right: 5px;
          }

          .headerText {
            display: flex;
            flex-direction: column;

            .headerTitle {
              font-size: 14px;
              font-weight: 400;
              white-space: nowrap;
            }

            .headerSubTitle {
              font-size: 10px;
              white-space: nowrap;
            }
          }
        }
      }
    }

    .headerViewType {
      .viewSwitch {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 13px;

        .viewSwitchLink {
          line-height: 18px;
          font-size: 20px;
          text-decoration: none;
          color: $color-text-light;
          margin: 0 4px;
          transition: color 0.1s ease-in-out;

          &:hover {
            color: $color-text;
          }

          &.viewSwitchLinkActive {
            color: $color-primary;
          }
        }
      }
    }

    .headerRightPart {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .headerButton { // The green "Add x" buttons on the right side in the header
        margin: -2px 5px -3px 5px;
        vertical-align: top;
        height: 36px;

        .headerButtonIcon {
          font-size: 18px;
          margin: -3px 0px -2px -10px;
          opacity: 0.5;
        }

        .headerButtonText {

        }
      }

      .order {
        width: 140px;
        margin-right: auto;
      }
    }
  }

  .habitatContent {

  }
}

// --- Responsiveness
@media (max-width: 1024px) {
  .order {
    display: none !important;
  }
}

@media (max-width: 800px) {
  // Remove the text in the header tabs
  .headerTab {
    padding: 0 14px !important;

    .headerIcon {
      margin-right: 0 !important;
    }
    .headerText {
      display: none !important;
    }
  }

  // Add "x" button on the right in the header
  .headerButton {
    width: 36px !important;
    min-width: unset !important;
    padding: 0;

    > div {
      margin: 0 !important;
    }

    .headerButtonIcon {
      margin: 0 !important;
    }

    .headerButtonText {
      display: none;
    }
  }
}
