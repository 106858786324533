.configure-code-capsule-modal,
.configure-archetype-modal,
.data-preview-modal {
  width: 600px;

  &.data-preview-modal{
    width: 100%;
    .modal-group-parent{
      height: calc(80vh - 80px);

      .data-preview-parent{
        overflow: auto;
      }
      .modal-description{
        margin-bottom: 10px;
      }
    }
  }

  .modal-group-parent{
    display: flex;
    flex-direction: column;

    &.parameters-parent{
      margin-top: 20px;
    }

    &.resources-parent {
      // TODO Conflict with other so named class
      padding: 0;
    }

    .modal-headline{
      font-weight: 500;
      font-size: 15px;
      margin-bottom: 10px;
    }

    .modal-description{
      display: block;
      margin-bottom: 5px;
    }

    .form--error {
      color: $color-red;
    }

    .field-parent {
      margin-top: 10px;
      margin-bottom: 10px;
      display: flex;

      &.parameters-parent{
        flex-direction: column;
      }

      .field-column{
        width: 50%;
        margin-right: 10px;
      }

      .parameter-row{
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .small-button{
          width: 24px;
          height: 24px;
          border-radius: 12px;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 12px;
          cursor: pointer;

          .icon-remove{
            color: $color-white;
          }

          &.button-remove{
            background-color: $color-red;
          }
          &.button-add{
            background-color: $color-green;
          }
        }

        .label-add{
          cursor: pointer;
          &:hover{
            text-decoration: underline;
          }
        }

        .parameter-key {
          border-radius: 4px;
          border: 1px solid $color-grey;
          background-color: $color-light-grey;
          flex-grow: 1;
          margin-right: 10px;
          width: 50%;
          height: 46px;
          padding: 10px;
          display: flex;
          align-items: center;
        }

        .parameter-value{
          width: 50%;
          flex-grow: 1;
        }
      }
    }

    .serving-types-parent {
      display: flex;
      margin-top: 15px;
      align-items: center;

      .serving-type-row{
        &:not(&:last-child){
          margin-bottom: 15px;
        }

        .label-parent{
          margin-left: 10px;
        }
      }
    }
  }


  // TODO duplicate name in workbench
  .button-bar {
    display: flex;
    justify-content: flex-end;
    margin-top: 14px;
    margin-right: 20px;

    .button {
      min-width: 100px;
      margin-left: 10px;
    }
  }
  // TODO get rid of it step by step
  .code-capsule-modal--button-bar {
    display: flex;
    justify-content: flex-end;
    margin-top: 14px;
    margin-right: 20px;

    .button {
      min-width: 100px;
      margin-left: 10px;
    }
  }
}
