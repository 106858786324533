[data-tooltip], .tooltip-sm {
  position: relative;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  &:before, &:after {
    position: absolute;
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    pointer-events: none;
    bottom: 50%;
    left: 50%;
    font-size: 12px;
  }
  &:after {
    z-index: 1001;
    padding: 5px 8px 6px;
    text-align: center;
    white-space: nowrap;
    background-color: $color-white;
    color: $color-text-light;
    content: attr(data-tooltip);
    font-size: 14px;
    line-height: 1.2;
    border: 1px solid $color-grey;
    border-radius: 4px;
    box-shadow: 0 2px 3px rgba(0,0,0,.1);
  }
  &:before {
    z-index: 1001;
    border: 6px solid transparent;
    background: transparent;
    content: "";
  }
  &:hover, &:focus {
    &:before, &:after {
      visibility: visible;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1;
      transform: translate(1px, -11px) translate(-50%, 0);
    }
  }
}

.tooltip-sm {
  &:before, &:after {
    bottom: 85%;
  }
  &:after {
    padding: 3px 8px 4px;
  }
}

.tooltip-sm--top {
  &:before, &:after {
    bottom: 100%;
    left: 50%;
  }
}
[data-tooltip], .tooltip-sm, .tooltip-sm--top {
  &:after {
  }
  &:before {
    margin-bottom: -11px;
    border-top-color: $color-white;
  }
}

.tooltip-sm--top {
  &:hover, &:focus {
    &:before, &:after {
      -webkit-transform: translateY(-11px);
      -moz-transform: translateY(-11px);
      transform: translateY(-11px);
    }
  }
}

.tooltip-sm--left {
  &:before, &:after {
    right: 100%;
    bottom: 50%;
    left: auto;
  }
  &:before {
    margin-left: 0;
    margin-right: -11px;
    margin-bottom: 0;
    border-top-color: transparent;
    border-left-color: $color-white;
  }
  &:hover, &:focus {
    &:before, &:after {
      -webkit-transform: translateX(-11px);
      -moz-transform: translateX(-11px);
      transform: translateX(-11px);
    }
  }
}

.tooltip-sm--bottom {
  &:before, &:after {
    top: 100%;
    bottom: auto;
    left: 50%;
  }
  &:before {
    margin-top: -11px;
    margin-bottom: 0;
    border-top-color: transparent;
    border-bottom-color: $color-white;
  }
  &:hover, &:focus {
    &:before, &:after {
      -webkit-transform: translateY(11px);
      -moz-transform: translateY(11px);
      transform: translateY(11px);
    }
  }
}

.tooltip-sm--right {
  &:before, &:after {
    bottom: 50%;
    left: 100%;
  }
  &:before {
    margin-bottom: 0;
    margin-left: -11px;
    border-top-color: transparent;
    border-right-color: $color-white;
  }
  &:hover, &:focus {
    &:before, &:after {
      -webkit-transform: translateX(11px);
      -moz-transform: translateX(11px);
      transform: translateX(11px);
    }
  }
}

.tooltip-sm--left, .tooltip-sm--right {
  &:before {
    top: 3px;
  }
}

.tooltip-sm--left, .tooltip-sm--right {
  &:after {
    margin-left: 0;
    margin-bottom: -16px;
  }
}
