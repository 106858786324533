@import "../../../../../scss/base/var";

.JobGroupTopologyChart {
  .react-flow__edge-path {
    stroke: $color-dark-grey !important;
  }
}

.group-topology-chart_tooltip {
  background-color: $color-light-grey;
  padding: 8px;
}

.tooltipContainer {
  position: relative;
}