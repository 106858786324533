@import "../../../../../../../../../../../../../scss/base/var";

.Ssp {
  padding: 10px;
  width: 100%;
  height: 100%;;
  position: relative;
  display: flex;
  flex-direction: column;

  .Ssp--categories-parent{
    display: flex;
    margin-bottom: 10px;

    .Ssp--category-tile {
      border: 1px solid $color-grey;
      border-radius: 4px;
      background-color: $color-white;
      padding: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      &:not(&:last-child){
        margin-right: 10px;
      }

      &.Ssp--category-selected {
        background-color: $color-text-light;
        color: $color-white;
      }

      .legend-circle {
        margin-left: 5px;
        width: 12px;
        height: 12px;
        border-radius: 6px;
      }
    }
  }

  .Ssp--plot-parent{
    border: 1px solid $color-grey;
    border-radius: 4px;
    background-color: $color-white;
    flex-grow: 1;
    flex-shrink: 1;

    .selection{
      stroke: $color-text-light;
      stroke-width: 1px;
      stroke-dasharray: 4;
      fill: transparent;
      border-radius: 0;
    }
  }
}
