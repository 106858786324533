.headline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .spinner {
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
  }
}
