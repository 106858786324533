@import '../../../../../scss/base/var';

.jobLogs {
  max-height: 500px;
  overflow-y: auto;

  &.empty {
    color: $color-text-light;
  }

  .logEvent {
    display: block;
    font-family: 'Monaco', 'Courier New', monospace;

    &:last-child {
      // scroll-snap-align: end;
    }

    .lineNumber {
      padding-right: 10px;
      min-width: 60px;
      text-align: right;
      background-color: #f6f6f6;
    }

    .logMessage {
      padding-left: 10px;
      word-wrap: break-word;
      overflow-wrap: anywhere;
    }
  }
}
