@import '../../../../scss/base/var';

.augurDetailsInner {
  // MainContainer--inner
  display: flex;
  flex-direction: column;
  height: 100%;
}

.devAugurDetailsInner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.devAugurContainer {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 1px;

  .sideNavContainer {
    display: flex;
    flex-direction: column;
    border-right: 1px solid $color-light-grey; // Same color as separator in left sidebar and the icon navbar

    .buttonContainer {
      background-color: $color-white;
      bottom: 10px;
      border-bottom-left-radius: 4px;
      display: flex;
      gap: 5px;
      justify-content: space-evenly;
      border-top: 1px solid $color-light-grey;
      padding: 5px;

      .editAugurButtons {
        min-width: 100px;
      }
    }
  }

  .devAugurParent {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: $color-white;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    .contentContainer {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }

    .sidebarContainer {
      display: flex;
      flex-direction: column;
      border-left: 1px solid $color-grey; // Same color as separator in left sidebar and the icon navbar
      min-width: 330px;
      width: 20%;
      height: 100%;
      right: 0;
    }
  }
}

.settingsContainer {
  overflow-y: auto;
  flex-grow: 1;
  height: 1px; // This is done so that the "settingsContainer" is the one that scrolls instead of the parent container. Otherwise the button bar would not always be visible for larger pages.
}

.settingsButtonsContainer {
  border-top: 1px solid $color-light-grey; // Same color as the wizard bottom button row
  display: flex;
  gap: 8px;
  padding: 5px;
  justify-content: flex-end;
}

.emptyViewAugur {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
