@import '../../../../scss/base/var';

$color-grey2: #cacdd2;

.ascDesc {
  position: relative;
  display: inline-block;
  padding-right: 13px;
  cursor: pointer;

  .top,
  .bottom {
    position: absolute;
    right: 0;
    top: 50%;
    border-width: 4px;
    border-style: solid;
    border-color: transparent;
    cursor: pointer;
  }

  .top {
    margin: -9px 0 0;
    border-bottom-color: $color-grey2;

    &:hover {
      border-bottom-color: $color-text-light;
    }
  }

  .bottom {
    margin: 1px 0 0;
    border-top-color: $color-grey2;

    &:hover {
      border-top-color: $color-text-light;
    }
  }


  &.asc {

    .top {
      border-bottom-color: $color-primary;

      &:hover {
        border-bottom-color: $color-primary;
      }
    }

    .bottom {
      &:hover {
        border-top-color: $color-text-light;
      }
    }
  }

  &.desc {

    .bottom {
      border-top-color: $color-primary;

      &:hover {
        border-top-color: $color-primary;
      }
    }

    .top {
      &:hover {
        border-bottom-color: $color-text-light;
      }
    }
  }
}
