@import '../../../../../scss/base/var';

.noElements {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;

  .noElementsContent {
    display: flex;
    flex-direction: column;
    align-items: center;

    .augurPic {
      width: 60px;
      height: 35px;
      margin: 0 auto 20px;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
