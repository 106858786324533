@import '../../../../../scss/base/var';

.backup-element {
  display: flex;

  .element {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-top: 1px solid $color-grey;
    padding: 10px;
    text-decoration: none;

    &:hover {
      background-color: $color-light-grey;
    }

    .element-bak-container {
      flex-grow: 1;

      .element-name {
          font-weight: 400;
      }
      .element-details {
        color: $color-text-light;
        margin-top: 5px;
      }
    }

    .element-bak-buttons {
      display: flex;

      .element-bak-button {
        width: 30px;
        height: 30px;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-text;

        &:not(:last-child) {
          margin-right: 5px;
        }

        &:hover {
          background-color: $color-grey;
          color: $color-white;
        }
      }
    }
  }
}
