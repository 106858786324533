.menus {
  position: relative;
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
}
.menus--icon {
  display: inline-block;
  font-size: 17px;
  height: 17px;
  width: 20px;
  text-align: center;
  line-height: 17px;
  margin: 0 14px 0 0;
  vertical-align: top;
  .icon-performance-drift {
    font-size: 14px;
  }
}
.menus--link {
  text-decoration: none;
  padding: 13px 22px 16px 22px;
  display: block;
  @include breakpoint(medium down) {
    padding: 8px 15px;
  }
  &:hover {
    .menus--icon {
      color: $color-primary;
    }
  }
  &:first-child {
    padding-top: 18px;
    @include breakpoint(medium down) {
      padding-top: 8px;
    }
  }
  &[data-icon="chevron-left"] {
    color: $color-primary-highlight;
    &:hover {
      color: $color-primary;
    }
  }
}
.menus--text {
  @include breakpoint(small down) {
    display: none;
  }
}
.menus--item {
  display: block;
  &:first-child {
    border-radius: 4px 0 0 0;
  }
  &.active {
    background: $color-primary;
    .menus--icon {
      color: $color-white;
      opacity: .7;
    }
    .menus--link {
      color: $color-white;
      cursor: default;
    }
  }
}