.page-settings {
  display: flex;
  min-height: calc(100% - 38px);
  flex-flow: row wrap;
}
.page-settings__white-block {
  flex: 1 100%;
  box-shadow: 0 3px 3px 0 #ddd;
  border-bottom: 1px solid rgba(221,221,221,.5);
  z-index: 1;
  overflow-x: hidden;
  padding: 50px 47px 20px 34px;
  line-height: 1.3;
  .page-settings--name {
    color: $color-primary;
    font-size: 20px;
    font-weight: 400;
    border-bottom: 1px solid $color-primary;
    padding: 0 0 7px 0;
    margin: 0 0 19px 0;
  }
}
.page-settings__grey-block {
  flex: 1 100%;
  line-height: 1.3;
  background: $color-light-grey;
  padding: 73px 47px 30px 34px;
  .page-settings--name {
    border-bottom: 1px solid $color-text-light;
    padding: 0 0 13px 0;
    margin: 0 0 19px 0;
    font-weight: 600;
  }
  .form--info {
    color: $color-text-light;
  }
  .form--buttons {
    text-align: right;
  }
}
