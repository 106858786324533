@import '../base/var';

.MainContainer.Apps {

  .apps-loading {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .apps-overview {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
    grid-gap: 20px;
    background-color: $color-white;
    border: 1px solid $color-grey;
    border-radius: 4px;
    padding: 20px;
  }

  .apps-empty { // Empty Pre-Queue
    // position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .apps-empty-elements {
      display: flex;
      flex-direction: column;
      text-align: center;

      .apps-empty-pic {
        width: 107px;
        height: 107px;
        margin: 0 auto 25px auto;
        background: url("../../img/appsEmpty.png") no-repeat;
        background-size: cover;
      }

      .headline {
        font-size: 18px;
        font-weight: 400;
        padding: 0 0 15px 0;
      }

      .description {
        font-size: 14px;
        color: $color-text-light;
      }
    }
  }
}
