@import '../../../../../../scss/base/var';

$min-card-width: 280px;

.moduleType {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($min-card-width, 1fr));
  grid-gap: 10px;

  // Hopefully the negative margin-bottom doesn't cause trouble - the idea is to bring the following WizardStep closer to
  //   this WizardStep. Since this is the only WizardStep that needs this exception in the vertical margin to look good
  //   it's defined here
  margin-bottom: -20px;
}


.moduleCard {
  word-wrap: break-word;
  word-break: break-all;
  background-color: $color-very-light-grey;
  height: 110px;
  min-width: $min-card-width;
  position: relative;
  border-radius: 2px;
  border: 1px solid $color-grey;
  padding: 10px;
  display: flex;
  flex-direction: row;
  transition: border, background-color .1s ease-in-out;
  align-items: center;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    background-color: $color-light-grey;
    border: 1px solid $color-primary-highlight;

    .fade {
      display: block;
    }
  }

  &.isSelected {
    background-color: $color-light-grey;
    border: 1px solid $color-primary-highlight;

    .fade {
      padding-top: 5px;
      display: block;
    }

    img {
      opacity: 1 !important;
    }

    .versionSelectContainer {
      // visibility: visible !important;
    }
  }

  &.isStock {
    position: relative;

    & > img {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 18px;
      height: 18px;
      opacity: 0.5;
    }
  }

  &.explicitlyNotSelected {
    .iconContainer {
      filter: saturate(0) brightness(1.1);
    }

    .nameContainer {
      .name {
        color: $color-text-light;
      }
    }
  }

  .iconContainer {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex-shrink: 0;

    .icon {
      width: 40px;
      height: 40px;

    }

    .defaultIcon {
      color: $color-primary;
    }
  }

  .nameAndVersionContainer {
    width: 1px;
    flex-grow: 1;

    .name {
      display: block;
      align-items: center;
      color: $color-text;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .versionSelectContainer {
      margin-top: 10px;
      padding-right: 30px;
    }
  }

  .fade {
    padding-top: 5px;
    display: none;

  }


  &.moduleCardLoading {

    .iconContainer {
      .iconCircle {
        width: 36px;
        height: 36px;
        border-radius: 18px;
      }

    }

    .nameContainer {
      display: flex;
      flex-direction: column;

      .nameBar1 {
        width: 140px;
        height: 12px;
        margin-bottom: 2px;
      }

      .nameBar2 {
        width: 100px;
        height: 12px;
        margin-top: 2px;
      }
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  30% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.loading {
  background: linear-gradient(-45deg, $color-grey, $color-grey, lighten($color-grey, 5%), $color-grey,);
  background-size: 400% 400%;
  animation: gradient 4s ease-in-out infinite;
}
