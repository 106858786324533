@font-face {
  font-family: 'Sansation';
  font-style: normal;
  font-weight: 400;
  src: local('Sansation'), local('Sansation Regular'), local('Sansation-Regular'), url('../../fonts/Sansation-Regular.ttf') format('truetype');
  // unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Sansation';
  font-style: normal;
  font-weight: 700;
  src: local('Sansation Bold'), local('Sansation-Bold'), url('../../fonts/Sansation-Bold.ttf') format('truetype');
  // unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
