@import '../../../../../../../../scss/base/var';

$rowHeight: 22px;
$color-text-light: #a6aeb5;
$color-grey2: #cacdd2;
$color-text: #3a3b3d;
$color-link: $color-primary;

.featureImportance {
  width: 100%;
  height: 100%;
  overflow: auto;
  text-align: left;
}

.featureImportanceInner {
  display: block;
}

.infoText {
    margin: 16px 0;
}

.featureRowHeadline {
  background-color: #fff;
  top: 0;
  z-index: 2;
}

.featureRow {
  display: flex;
  gap: 40px;

  & + & {
    margin-top: 10px;
  }
}

.featureCell,
.featureHeaderCell {
  display: inline-block;
  height: $rowHeight;
  vertical-align: top;
  horiz-align: center;
  line-height: $rowHeight;
  flex-basis: 0;

  &.importance {
    flex-grow: 3;
    min-width: 120px;
  }

  &.type {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.feature {
    min-width: 150px;
    flex-grow: 1;
    display: flex;
    gap: 8px;
  }

  &.rejected {
    min-width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.rejectedHeader {
      justify-content: start;
    }
  }
}

.discardedOrUsedIcon {
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconUsed {

}

.iconDiscarded {
  color: $color-red-dark;
}

.iconRejected {
  color: $color-orange-dark;
}

.featureParent {
  cursor: default;
  display: flex;
  flex-grow: 1;
}

.featureText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &.discarded {
    font-style: italic;
    color: $color-text;
  }
}

.featureHeaderCell {
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.horizontalBarChartBar {
  fill: $color-primary-highlight;
}

.horizontalBarChartBackgroundBar {
  fill: $color-light-grey;
}

.noFeaturesAvailable {
  font-size: 16px;
  margin-top: 20px;
  color: $color-text-light;
}
