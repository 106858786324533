@import '../../../../../scss/base/var';

.LoginHistory {
  margin: 0 0 60px 0;

  .LoginHistory--preview {
    max-height: 390px;
    overflow: hidden;
    -ms-overflow-style: none;
    + .LoginHistory--expand-link {
      margin-top: -12px;
    }
  }

  .LoginHistory--title {
    font-size: 15px;
    font-weight: 400;
    color: #a6aeb5;
    margin: 20px 0;
  }

  .LoginHistory--wrap {
    table {
      border-collapse: separate;
    }
    thead, tbody, tfoot {
      border: none;
      border-radius: 0;
      td {
        border-top: 1px solid $color-grey;
        border-bottom: 1px solid $color-grey;
        &:first-child {
          border-left: 1px solid $color-grey;
        }
        &:last-child {
          border-right: 1px solid $color-grey;
        }
      }
    }
    thead {
      border-radius: 5px 5px 0 0;
      td {
        padding-top: 1.5rem;
      }
      tr {
        &:first-child {
          td {
            &:first-child {
              border-radius: 5px 0 0 0;
            }
            &:last-child {
              border-radius: 0 5px 0 0;
            }
          }
        }
      }
    }
    tbody {
      border-radius: 0 0 5px 5px;
      td {
        border-top: none;
      }
      tr {
        &:last-child {
          td {
            &:first-child {
              border-radius: 0 0 0 5px;
            }
            &:last-child {
              border-radius: 0 0 5px 0;
            }
          }
        }
      }
    }

    .LoginHistory--expand-link {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 12px;
      margin-bottom: 12px;
      &:after {
        display: none;
      }

      .LoginHistory--expand-button {
        height: 28px;
        padding: 5px 10px;
        border: 1px solid $color-grey;
        border-radius: 14px;
        background-color: #fff;
        margin-top: -12px;
        text-decoration: none;
        color: $color-primary-highlight;
        cursor: pointer;
        font-weight: 400;

        :hover {
          text-decoration: underline;
        }
      }
    }
  }
}
