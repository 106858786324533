@import "../../../../scss/base/var";

.ExpandCollapseIcon {
  cursor: pointer;
  margin-bottom: -2px; // To align it vertically

  &--with-background {
    width: 30px;
    height: 30px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out; // Faster transition looks better for the small icon
    cursor: pointer;

    &:hover {
      background-color: $color-grey;

      .ExpandCollapseIcon-icon {
        color: $color-white;
      }
    }
  }

  .ExpandCollapseIcon-icon {
    color: $color-primary;
    transition-duration: 0.3s;
    transition-property: transform;

    &-expanded {
      transform: rotate(0deg);
    }

    &-collapsed {
      transform: rotate(-90deg);
    }

    &-black {
      color: $color-text;
    }
  }
}
