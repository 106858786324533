@import '../../../../../../../../../../src/scss/base/var.scss';

.credentialsCell {
  font-family: $Roboto !important;
  padding: 0;

  .headline {
    font-weight: 400;
    font-size: 15px;
    margin: 10px 10px 0 10px;
  }

  .form {
    display: flex;
    flex-wrap: wrap;
  }

  .field {
    width: 300px;
    max-width: 400px;
    margin: 10px;
  }

  .warning {
    font-weight: 300;
    font-size: 14px;
    color: red;
    margin: 10px 10px 0 10px;
  }
}
