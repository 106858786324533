@import '../../../../scss/base/var';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: clip;
}

.itemWrapper {
  background-color: white;
  height: 100%;
}

.item {
  display: flex;
  flex-direction: column;

  &.interactive :active {
    cursor: grabbing;
  }

  &.bordered {
    border: 2px solid $color-dark-grey;
    border-radius: 4px;

    transition: border 200ms ease-in-out, box-shadow 200ms ease-in-out;
  }

  &.selected {
    border: 2px solid color-mix(in srgb, $color-primary 50%, transparent);
    box-shadow: 0 0 8px color-mix(in srgb, $color-primary 50%, transparent);
  }

  &.error {
    border: 2px solid color-mix(in srgb, red 100%, transparent);

    &.selected {
      box-shadow: 0 0 8px color-mix(in srgb, red 100%, transparent);
    }
  }

  .removeButton {
    position: absolute;
    right: 4px;
    top: 4px;
    cursor: pointer;
  }

  .errorIndicator {
    position: absolute;
    left: 4px;
    bottom: 0; // 4px not sure why there already is bottom padding
  }
}

.grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: repeating-linear-gradient(
                  0deg,
                  transparent,
                  transparent calc(var(--grid-height) - 1px),
                  #ddd calc(var(--grid-height) - 1px),
                  #ddd var(--grid-height)
  ),
  repeating-linear-gradient(
                  -90deg,
                  transparent,
                  transparent calc(var(--grid-width) - 1px),
                  #ddd calc(var(--grid-width) - 1px),
                  #ddd var(--grid-width)
  );
  background-size: var(--grid-width) var(--grid-height);
}
