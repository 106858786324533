@import "../../../../scss/base/var";

.title-box {
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 5px;
}

.border-box {
  border: 1px solid $color-grey;
  border-radius: 5px;
  width: 100%;
  display: flex;
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 5px;
  position: relative;
  min-height: 70px;
  flex-direction: row;
  align-items: flex-end;
}
