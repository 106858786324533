@import '../../../../scss/base/var';

.WithSidemenu {
  height: 100%;
  width: 100%;
  display: flex;
}

.WithSidemenu--content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden; // Hopefully this doesn't cause problems. But it's required since otherwise the Job Details logs are overlapping
}

.SideMenu {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 228px;
  width: 228px;
  font-weight: 400;
  font-size: 13px;
  overflow-y: auto;
  padding: 6px 0;

  .orchestration-tab-back-icon {
    font-size: 13px;

    .menus--link {
      padding: 16px 14px 16px 18px;
    }

    .menus--text {
      display: inline-block;
    }
  }

  .SideMenu-tab-link {
    margin-bottom: 4px;
    margin-right: 5px;
    margin-left: 5px;
    transition: color ease-in-out 100ms;
    border-radius: 8px;

    &:focus {
      outline: none;
    }

    &.tab-link-active {

      > .SideMenu-tab-link--inner {
        color: var(--color-primary, #224e90);
      }
    }

    &.border-top {
      border-top: 1px solid $color-grey;
    }

    .SideMenu-tab-icon {
      margin: 0 10px;
      transition: color ease-in-out 100ms;
      display: flex;

      span {
        font-size: 17px; // If the icon is based on a font
        transition: color ease-in-out 100ms;
      }
    }

    > .SideMenu-tab-link--inner {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $color-text;
      font-size: 13px;
      height: 32px;
      padding: 0 8px;
    }

    > .SideMenu-tab-link--disabled {
      cursor: default;
      color: $color-text-light !important; // Because of the very specific rules below, which we want to override
    }

    // --- BLACK ENTRY
    &.SideMenu-tab-link--color-black {
      > .SideMenu-tab-link--inner {
        color: $color-text;
      }

      .SideMenu-tab-icon {
        > .SideMenu-tab-link--inner {
          color: $color-text;
        }

        fill: $color-text;
      }

      &:hover {
        background: var(--color-primary-lighter90, #e4ecf9);

        > .SideMenu-tab-link--inner {
          color: $color-primary;
        }

        .SideMenu-tab-icon {
          > .SideMenu-tab-link--inner {
            color: $color-primary;
          }

          span { // for icons that are based on a font
            color: $color-primary;
          }
        }
      }

      &.tab-link-active {
        background: var(--color-primary-lighter90, #e4ecf9);

        > .SideMenu-tab-link--inner {
          color: var(--color-primary, #224e90);
        }

        .SideMenu-tab-icon {
          > .SideMenu-tab-link--inner {
            color: var(--color-primary, #224e90);
          }

          // TODO where is this needed? instead of just the span below
          span { // for icons that are based on a font
            > .SideMenu-tab-link--inner {
              color: var(--color-primary, #224e90);
            }
          }

          span { // for icons that are based on a font
            color: var(--color-primary, #224e90);
          }
        }
      }
    }

    // --- BLUE ENTRY
    &.SideMenu-tab-link--color-blue {
      background: var(--color-primary-lighter90, #e4ecf9);

      > .SideMenu-tab-link--inner {
        color: $color-primary-highlight;
      }

      .SideMenu-tab-icon {
        > .SideMenu-tab-link--inner {
          color: $color-primary-highlight;
        }

        span { // for icons that are based on a font
          > .SideMenu-tab-link--inner {
            color: $color-primary-highlight;
          }
        }
      }

      &:hover {
        > .SideMenu-tab-link--inner {
          color: var(--color-primary, #224e90);
        }

        .SideMenu-tab-icon {
          > .SideMenu-tab-link--inner {
            color: var(--color-primary, #224e90);
          }

          span { // for icons that are based on a font
            > .SideMenu-tab-link--inner {
              color: var(--color-primary, #224e90);
            }
          }
        }
      }

      &.tab-link-active {
        // At the moment, the blue links are not supposed to be "active" since they're only the "Back" buttons. So
        //   there is no need for "active" styling (yet).
      }
    }

    // ---
  }

  .SideMenu-hr {
    border-bottom: 1px solid $color-grey;
    margin: 10px 0;
  }

  .SideMenu-headline {
    color: $color-text-light;
    cursor: default;
    padding: 6px 10px 6px 50px;
    text-transform: uppercase;
    font-weight: 400;
  }

  .SideMenu-loading {
    margin-bottom: 4px;
    margin-right: 15px;
    margin-left: 15px;
    transition: color ease-in-out 100ms;
    border-radius: 8px;
    height: 32px;
    display: flex;
    align-items: center;

    .SideMenu-loading-inner {
      display: block;
    }
  }
}
