@import '../../../../../../scss/base/var';

.container{
  height: 100%;
  display: flex;
  flex-direction: column;
}
.buttonContainer{
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: flex-end;
}