.notifications-wrapper{

  .notification-title{
    font-weight: 500;
  }

  .notification-info {
    background-color: $color-light-grey !important;
    border-top-color: $color-text-light !important;

    .notification-title {
      color: $color-text-light !important;
    }
  }

  .notification-action-button{
    height: 40px;
    border-radius: 20px !important;
    color: $color-white !important;
    width: 120px;
    font-weight: 300 !important;
    margin-left: auto !important;
  }

  .notification-success {
    background-color: $color-green-light !important;
    border-top-color: $color-green-dark !important;

    .notification-title {
      color: $color-green-dark !important;
    }

    .notification-action-button{
      background-color: $color-green !important;
    }
  }

  .notification-error{
    background-color: $color-red-light !important;
    border-top-color: rgb(236, 61, 61) !important;

    .notification-title{
      color: $color-red !important;
    }
  }

  .notification-dismiss{
    display: none;
  }
}
