@import '../../../../scss/base/var';

$card-height: 120px;

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  text-align: center;
  font-size: 12px;
  color: $color-text-light;
}

.elementGallery {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 8px;
}

.searchBarParent {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.elementsGridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  overflow-y: scroll;

  .elementContainer {
    position: relative;
    border: 1px solid $color-grey;
    border-radius: 4px;
    overflow: hidden;
    padding: 5px;
    height: $card-height;
    display: flex;
    flex-direction: column;

    .imageContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      flex: 1;
      overflow: hidden;

      .img {
        max-height: 100%;
        max-width: 100%;
        align-self: center;
      }
    }

    .nameParent {
      margin: 2px 0;
      width: 100%;
      text-align: center;
      font-weight: 400;
    }

    .icon {
      position: absolute;
      display: flex;
      padding: 2px;
      top: 4px;
      right: 4px;
      background-color: #d3d3d344;
      border-radius: 4px;
      border: 1px solid #d3d3d366;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
    }
  }
}


/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 360px) {
  .elementsGridContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}
