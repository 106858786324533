@import '../../../../../scss/base/var';

.JobGroupEditor {
  display: flex;
  flex-direction: column;
  height: 100%;

  .JobGroupEditor--error {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }

  .JobGroupEditor--expand-collapse {
    border-style: solid;
    border-color: $color-grey;
    border-width: 0 0 1px 0;
    margin: 0 10px;
    border-radius: 8px;
    position: relative;

    .JobGroupEditor--expand-collapse-body {
      height: 300px;
    }
  }

  .JobGroupEditor--dndflow {
    flex-grow: 1;
    flex-direction: column;
    display: flex;

    .JobGroupEditor--reactflow-wrapper {
      flex-grow: 1;
    }
  }

  .react-flow__handle {
    transition: background-color 0.1s ease-in-out, height 0.1s ease-in-out,
      width 0.1s ease-in-out, left 0.1s ease-in-out, right 0.1s ease-in-out;

    &-left {
      left: -5px !important;

      &.react-flow__handle-valid {
        // added by react-flow when the port is dragged over with a connection (plus: the connection is "valid" = can be connected)
        width: 16px !important;
        height: 16px !important;
        background-color: $color-dark-grey !important;
        left: -8px !important;
      }
    }

    &-right {
      right: -5px !important;

      &:hover {
        width: 16px !important;
        height: 16px !important;
        background-color: $color-dark-grey !important;
        right: -8px !important;
      }
    }
  }

  .react-flow__node {
    &.selected {
      border-color: $color-primary-highlight;
    }
  }

  .react-flow__edge {
    .react-flow__edge-path {
      stroke: $color-dark-grey !important;

      &:hover {
        stroke-width: 3;
      }
    }

    &.selected {
      .react-flow__edge-path {
        stroke: $color-primary-highlight !important;
        stroke-width: 3;
      }
    }
  }

  .react-flow__minimap {
    border: 1px solid $color-grey;
    border-radius: 4px;
  }
}
