// The next block contains general editor styles that apply to the regular Cells as well as to Code scripts
.NotebookCell,
.code-content {
  width: 100%;

  .ace_editor{
    font-size: 14px !important;

    textarea{
      min-height: 0 !important;
    }
  }
  .ace_active-line{
    background-color: transparent !important;
  }
  .ace_gutter-cell{
    background-color: $color-light-grey !important;
  }
  .ace_hidden-cursors{
    display: none !important;
  }
  .ace_selection{
    border-radius: 0;
  }
}

.raw-cell{
  .ace-tomorrow{
    background-color: transparent !important;
  }
  .ace_active-line{
    background-color: transparent !important;
  }
}

// Styling for markdown cells
.markdown-con,
.markdown-cell {
  .NotebookCell--container, .app-intro-description {
    // Font-size and weight
    p, li, th, td {
      font-size: 16px;
      font-weight: 300;
    }
    // Margins
    p, ul, ol, h1, h2, h3, h4, h5, h6 {
      margin: 10px 5px !important;
    }
    li{
      margin-bottom: 0 !important;
    }

    // Text
    em {
      font-style: italic !important;
    }

    // Code
    code {
      font-family: $font-monospace !important;
      font-size: 14px !important;
      background-color: transparent !important;
    }

    // Headlines
    h1 {
      font-size: 24px;
      font-weight: 600;
      font-family: $Roboto;
    }

    h2 {
      font-size: 22px;
      font-weight: 600;
      font-family: $Roboto;
    }

    h3 {
      font-size: 20px;
      font-weight: 600;
      font-family: $Roboto;
    }

    h4 {
      font-size: 18px;
      font-weight: 600;
      font-family: $Roboto;
    }

    h5, h6 {
      font-size: 16px;
      font-weight: 600;
      font-family: $Roboto;
    }

    // Tables
    th {
      color: $color-text;
      font-weight: 600;
    }

    // Code
    pre {
      padding: 5px;
      border: 1px solid $color-light-grey;

      code {
        font-family: $font-monospace !important;
      }
    }

    // Lists
    ul, ol {
      list-style-position: outside;
      line-height: inherit;
      margin-left: 50px !important;
    }

    ul {
      list-style-type: none;
    }
    ul li{
      position: relative;
    }
    ul li:before {
      font-weight: 300;
      content: "–";
      position: absolute;
      text-align: right; // This wouldn't be necessary - but to match the style to the ordered list
      width: 20px;
      left: -30px;
    }

    ol {
      list-style-type: none;
      counter-reset: my-awesome-counter;
    }
    ol li{
      counter-increment: my-awesome-counter;
      position: relative;
    }
    ol li:before{
      font-weight: 300;
      content: counter(my-awesome-counter) ".";
      position: absolute;
      width: 40px;
      left: -50px;
      text-align: right;
    }
  }
}
