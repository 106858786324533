.pageParent {
  height: 100%;
}

.contentParent {
  padding: 10px;
  height: 100%;
  overflow-y: auto;
}
.header {
  padding: 0 0 10px 0;
  height: 36px;
  display: flex;
  align-items: center;
}
