@import '../../../../scss/base/var';

$col-border-grey-lighter: #dce2eb;
$tooltip-background-color: $color-white;
$tooltip-border-color: $color-grey;

// Styling for the job groups
.item {
  display: grid;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px 0;
  border: solid $col-border-grey-lighter;
  border-width: 0 0 1px 0;
  cursor: pointer;
}

.topology {
  overflow: auto;
  padding: 5px;
  background-color: $color-light-grey;
  border: 1px solid $color-grey;
  border-radius: 5px;
  margin-right: 10px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: inherit;
  overflow: auto;
}

.field {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.description {
  margin-top: 5px;

  .box {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: clip;
    overflow-wrap: anywhere;
  }

  .tooltip {
    position: absolute;
    width: 600px;
  }

  .tooltipText {
    visibility: hidden;
    position: absolute;
    top: 100%;
    padding: 6px;
    background-color: $tooltip-background-color;
    border: 1px solid $tooltip-border-color;
    border-radius: 4px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    z-index: 100;

    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;
  }
}

.description:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}

.userIcon {
  display: flex;
  align-items: center;
}

.buttons {
  display: flex;
  align-items: center;

  div {
    margin: 0; // Remove the margin of the IconButton component
  }
}

.buttonsWrapper {
  height: 32px;
  border: 1px solid $color-grey;
  border-radius: 16px;
  padding: 0 10px;
  display: flex;
}