@import '../../../../../../../../scss/base/var';

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.axisLabel {
  font-size: 11px;
  fill: #888888;
  font-weight: 500;
}

.bar {
  transition: filter ease-in-out 0.05s;

  &:hover {
    filter: brightness(0.7);
  }
}
