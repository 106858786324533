@import "../../../../../scss/base/var";
@import "../../../../../scss/vendor/foundation/foundation.scss";

.searchResult {
  color: $color-text;
  position: absolute;
  left: -40px;
  right: -40px;
  top: 38px;
  background: $color-white;
  padding: 10px 20px;
  margin: 0 0 12px 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 8px; // #9;
  z-index: 999;
  // min-width: 150px;
  // width: 100%;
  text-align: left;
  font-size: 14px;

  &:after {
    content: "";
    position: absolute;
    left: 56px;
    top: -12px;
    border-style: solid;
    border-width: 6px;
    border-color: transparent;
    border-bottom-color: $color-white;
  }

  .headline {
    color: $color-primary;
    margin: 0 -20px 15px -20px;
    padding: 3px 20px 15px 20px;
    border-bottom: 1px solid $color-light-grey;
    font-weight: 400;
    font-size: 12px;
  }

  .group {
    &:last-child {
      .list {
        padding: 0 0 2px 0;
      }
    }

    .label {
      color: $color_text-light;
      font-size: 13px;
      font-weight: 400;
      padding: 0 0 10px 0;
    }

    .list {
      padding: 0 0 9px 0;

      .li {
        padding: 0 0 8px 0;
        a {
          color: $color-text;
          text-decoration: none;
          display: block;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

  }
}
