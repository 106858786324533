.launcher-content {
  width: 100%;
  height: calc(100% - 48px);
  position: relative;
  overflow: hidden;
  padding: 50px;

  .launcher-headline {
    font-size: 22px;
    font-weight: 400;
    cursor: default;
    margin-bottom: 40px;
  }

  .launcher-container {
    display: flex;
    flex-direction: row;

    .launcher-category {
      padding: 20px 40px 0 0;

      .headline {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 400;
      }

      .launcher-card-container {
        .launcher-card,
        .kernelspec-container {
          cursor: pointer;

          .icon-container-small {
            margin: 15px;
            width: 40px;
            height: 40px;
          }
        }
      }

      &.launcher-create-notebook {
        border-top: 1px solid $color-grey;
      }
    }
  }
}

@media(max-width: $media-sm) {
  .launcher-content {
    .launcher-container {
      flex-direction: column;
      .launcher-category {
        padding: 20px 0 0 0;
      }
    }
  }
}
