@import '../../../../scss/base/var';

.configPaneContainer {
  height: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
}

.backTo {
  margin: 4px 0 -4px 0;
}

.spacer {
  width: 100%;
}