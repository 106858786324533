.archetypes-container{
  padding: 20px 15px;

  .archetypes-headline {
    margin: 0 5px 10px 5px;
    font-size: 15px;
    font-weight: 400;
    display: block;
  }

  .archetype-list-wrapper{
    &:not(:first-child){
      margin-top: 30px;
    }
  }
}
