@import "../../../../../../scss/base/var.module.scss";

.MarkdownContainer {
  grid-area: Content;
  margin-top: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  line-height: 1.6;
  color: #333;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ddd;

  padding: 15px;
}