.MainContainer.Orchestration {
  .JobHistory--Header {
    display: grid;
    grid-template-columns: 2fr 1fr 170px 40px 170px 80px;;
    gap: 10px;
    width: 100%;

    position: sticky;
    top: 0;
    padding: 10px 0 10px 0;
    background-color: white;
    z-index: 100; // To overlap the react-flow graph
  }
}

// TODO obsolete?
.orch-page-parent {

  .history-container {
    height: 100%;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    &.history-empty {
      position: absolute;
      margin-top: 53px; // to make it match to the buttons headline of the Queues
      width: calc(100% - 30px); // -2x15px horizontal padding
      height: calc(100% - 40px - 43px); // -2x20px vertical padding - 43px to make it match to the buttons headline of the Queues
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-top: 180px; // TODO This is the try to center the elements vertically ... Fix it to a proper center.

      .history-empty-elements {
        display: flex;
        flex-direction: column;
        text-align: center;

        .history-empty-pic {
          width: 107px;
          height: 107px;
          margin: 0 auto 25px auto;
          background: url("../../img/orchestration/historyEmpty.png") no-repeat;
          background-size: cover;
        }

        .headline {
          font-size: 18px;
          font-weight: 400;
          padding: 0 0 15px 0;
        }

        .description {
          font-size: 14px;
          color: $color-text-light;
        }
      }

    }
  }
}
