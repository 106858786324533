@import "../../../../scss/base/var.module";

.label {
  height: 20px;
  width: 120px;
  border-radius: 10px;
  background-color: $color-white;
  border-color: $color-text-light;
  border-width: 1px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}