@import '../../../../../../../scss/base/var';

.descriptionField {
  grid-column: span 2 !important;
}

.boostFactorField {
  grid-column: 1;
}

.erroneous {
  color: $color-red !important;
}

.erroneousRow {
  td {
    background-color: $color-red-light;
  }
}
// --- Responsiveness
@media(max-width: $media-lg) {
  .descriptionField {
    grid-column: span 1 !important;
  }
}
