.ct-row {
  display: flex;
  width: 100%;
  // background-color: #af7;

  &:hover{
    background-color: $color-blue-grey;
  }

  &.ct-headline{
    font-weight: 500;
    background-color: $color-light-grey;

    &:hover{
      background-color: $color-light-grey;
    }
  }
}

.ct-col {
  padding: 10px;
  overflow: hidden;
  .ct-value {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &.ct-show-overflow {
      overflow: visible
    }
  }

  &.ct-col-flex-grow {
    flex-grow: 1;
  }

  &.ct-col-flex-grow-200px {
    flex: 1 0 200px;
  }

  // TODO This is no good idea.
  &.ct-col-40px {
    width: 40px;
  }

  &.ct-col-70px {
    width: 70px;
  }

  &.ct-col-80px {
    width:80px;
  }

  &.ct-col-120px {
    width: 120px;
  }

  &.ct-col-130px {
    width: 130px;
  }

  &.ct-col-140px {
    width: 140px;
  }

  &.ct-col-160px {
    width: 160px;
  }

  &.ct-col-200px {
    width: 200px;
  }

  &.ct-col-280px {
    width: 280px;
  }

  &.ct-col-350px {
    width: 350px;
  }

  &.ct-col-low-pad {
    padding: 5px;
  }

  &.checkbox-item-parent {
    padding: 5px;
    overflow: visible;
  }

  &.ct-col-flex{
    display: flex;
  }

  .ct-description {
    color: $color-text-light;
  }

  .ct-expand-wrapper{
    display: flex;
    vertical-align: center;

    .icon {
      margin: -4px -4px -4px auto;
      height: 24px;
      width: 24px;
      border-radius: 12px;
      padding: 0;
      border: 0;

      svg{
        stroke: $color-primary;
      }
      &:hover, &:focus {
        background: $color-primary;
        svg {
          stroke: $color-white;
        }
      }
    }
  }
}

.ct-sub-list {
  display: flex;
  flex-direction: column;
}


.ct-hr {
  width: 100%;
  height: 1px;
  background-color: $color-light-grey;
}

.ct-col-hover {
  display: none;
}

.ct-row:hover .ct-col-hover {
  display: flex;
}
